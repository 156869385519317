import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { routerPath, RouterURLParams } from "../../../AppRouter";
import emptyStateIllustration from "../../../assets/images/data-dashboard.svg";
import { StyledH4, StyledP } from "../../Typography";
import { MetricsEmptyStateWrapper } from "./styles";

const EmptyStateImage = styled(Image)`
    width: 250px;
    margin: 25px 0px;
`;

interface MetricsEmptyStateProps {
    firstName?: string | null;
}

export const MetricsEmptyState: React.FunctionComponent<MetricsEmptyStateProps> =
    ({ firstName }) => {
        const { dashboardId } =
            useParams<RouterURLParams["dashboardDetails"]>();

        return (
            <MetricsEmptyStateWrapper>
                <Row>
                    <Col xl={{ span: 6, offset: 3 }}>
                        <StyledH4>
                            Welcome to your dashboard
                            {firstName ? `, ${firstName}` : ``}!
                        </StyledH4>
                        <EmptyStateImage src={emptyStateIllustration} />
                        <StyledP>
                            Get started by creating your first metric. Click on
                            the button below and we&apos;ll walk you through it.
                        </StyledP>
                        <Button
                            href={routerPath.newMetric({
                                dashboardId,
                            })}
                            className="fw-bold hvr-pulse-shrink--on hvr-pulse-shrink--slow"
                        >
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            Create your first metric
                        </Button>
                    </Col>
                </Row>
            </MetricsEmptyStateWrapper>
        );
    };
