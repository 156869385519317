import {
    faEllipsisV,
    faPenAlt,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";

import { routerPath } from "../../AppRouter";
import { ChartContainerProps } from "./ChartContainer";

export const ActionsButton = ({
    isEditable,
    metric,
    organisationId,
    siteId,
    handleDeleteMetric,
}: ChartContainerProps & { handleDeleteMetric: () => void }) =>
    isEditable && metric ? (
        <Dropdown>
            <Dropdown.Toggle variant="light">
                <FontAwesomeIcon icon={faEllipsisV} />
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ minWidth: 0 }} align="end">
                <Dropdown.Item
                    href={routerPath.editMetric({
                        organisationId,
                        siteId,
                        metricId: metric.id,
                    })}
                >
                    <FontAwesomeIcon className="me-2" icon={faPenAlt} />
                    Edit
                </Dropdown.Item>
                <Dropdown.Item
                    className="text-danger"
                    onClick={handleDeleteMetric}
                >
                    <FontAwesomeIcon className="me-2" icon={faTrashAlt} />
                    Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    ) : null;
