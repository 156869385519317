import React from "react";
import { Col, Row } from "react-bootstrap";

import {
    Dashboard,
    Feature,
    MetricTimeframe,
    Organisation,
    Site,
    UpdateDashboardMutationOptions,
} from "../../../../generated/graphql";
import { Filters, FiltersProps } from "../../../Filters/Filters";
import { MoreActionsDropdown } from "./MoreActionsDropdown";
import { NewMetricAction } from "./NewMetricAction";
import { ShareAction } from "./ShareAction";
import { TimeframeButton } from "./TimeframeButton";

export interface ActionsBarProps {
    dashboardId: Dashboard["id"];
    onDateChange(params: {
        startAt: Date;
        endAt: Date;
        timeframe: MetricTimeframe;
    }): void;
    onFilterChange: FiltersProps["onChange"];
    filters: FiltersProps["filters"];
    isEmbedded?: boolean;
    metricCount: number;
    triggerUpgradeModal?: () => void;
    paidFeatures: Feature[];
    hidePaidFeatures?: boolean;
    reorderingMetrics: boolean;
    onReorderingMetricsClick: () => void;
    isPublic: boolean | undefined;
    siteId: Site["id"];
    organisationId: Organisation["id"];
    handleDeleteDashboard?: (dashboardId: string) => void;
    updateDashboard: (options?: UpdateDashboardMutationOptions) => Promise<any>;
    showShare?: boolean;
    setShowShare?: (value: boolean) => void;
}

export const ActionsBar: React.FunctionComponent<ActionsBarProps> = (props) => {
    const { reorderingMetrics } = props;

    const canAddNewMetric =
        props.paidFeatures.includes(Feature.UnlimitedMetrics) ||
        props.metricCount < 10;

    return (
        <>
            <Row className="ActionsBar justify-content-md-center mx-2">
                <Col>
                    <Row>
                        <Col md="auto">
                            <TimeframeButton {...props} />
                        </Col>
                        <Col>
                            <Filters
                                filters={props.filters}
                                onChange={props.onFilterChange}
                            />
                        </Col>
                    </Row>
                </Col>

                {!props.isEmbedded ? (
                    <>
                        <MoreActionsDropdown
                            dashboardId={props.dashboardId}
                            handleDeleteDashboard={props.handleDeleteDashboard}
                            reorderingMetrics={reorderingMetrics}
                            updateDashboard={props.updateDashboard}
                            onReorderingMetricsClick={
                                props.onReorderingMetricsClick
                            }
                        />
                        {props.setShowShare && props.showShare !== undefined ? (
                            <ShareAction
                                siteId={props.siteId}
                                paidFeatures={props.paidFeatures}
                                organisationId={props.organisationId}
                                isPublic={props.isPublic}
                                dashboardId={props.dashboardId}
                                updateDashboard={props.updateDashboard}
                                showShare={props.showShare}
                                setShowShare={props.setShowShare}
                            />
                        ) : null}
                        <NewMetricAction
                            canAddNewMetric={canAddNewMetric}
                            dashboardId={props.dashboardId}
                            metricCount={props.metricCount}
                            paidFeatures={props.paidFeatures}
                            triggerUpgradeModal={props.triggerUpgradeModal}
                        />
                    </>
                ) : null}
                {props.isEmbedded &&
                    !props.paidFeatures.includes(
                        Feature.EmbeddableDashboard,
                    ) && (
                        <Col md="auto">
                            <p>
                                Powered by{" "}
                                <span className="fw-bold">Nocodelytics</span>
                            </p>
                        </Col>
                    )}
            </Row>
        </>
    );
};
