import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import {
    ErrorText,
    Loading,
    MetricEditor,
    MetricEditorProps,
} from "../../components";
import { TimeframeButton } from "../../components/Metrics/MetricsList/ActionsBar/TimeframeButton";
import {
    Feature,
    MetricTimeframe,
    useCmsCollectionsQuery,
    useEventTypesQuery,
    useOrganisationQuery,
    useSiteByIdQuery,
    useSiteHtmlElementsQuery,
} from "../../generated";
import { PreviewChart } from "../../pages/Metrics/NewMetric/PreviewChart";

export interface MetricFormContainerProps {
    siteId: string;
    organisationId: string;
    formControl: MetricEditorProps["formControl"];
    onSubmit: () => void;
    triggerUpgradeModal: () => void;
}

export const MetricFormContainer: React.FunctionComponent<MetricFormContainerProps> =
    (props) => {
        const { siteId, organisationId } = props;
        const [selectedMetricTimeframe, setTimeframe] =
            useState<MetricTimeframe>();
        const [dates, setDates] = useState<{ startAt: Date; endAt: Date }>();

        const formValues = props.formControl.watch();

        const siteByIdQueryResults = useSiteByIdQuery({
            variables: { id: siteId },
        });
        const organisationResults = useOrganisationQuery({
            variables: { organisationId },
        });
        const siteHtmlElementsResults = useSiteHtmlElementsQuery({
            variables: { siteId },
        });
        const cmsCollectionsQuery = useCmsCollectionsQuery({
            variables: { siteId },
        });

        const eventTypesResults = useEventTypesQuery();

        const loading =
            siteByIdQueryResults.loading ||
            eventTypesResults.loading ||
            organisationResults.loading;
        const error =
            siteByIdQueryResults.error ||
            eventTypesResults.error ||
            organisationResults.error;
        const data = {
            ...siteByIdQueryResults.data,
            ...eventTypesResults.data,
            ...siteHtmlElementsResults.data,
            ...organisationResults.data,
        };
        const isSubscriptionValid = data.organisation?.features.includes(
            Feature.HistoricalData,
        );
        const defaultSelected = isSubscriptionValid
            ? MetricTimeframe.Last30days
            : MetricTimeframe.Last7days;
        const timeframe: MetricTimeframe =
            selectedMetricTimeframe || defaultSelected;
        if (loading) {
            return <Loading />;
        }
        if (error) {
            {
                return <ErrorText error={error} from="NewMetricPage" />;
            }
        }
        if (!data.siteById) {
            return <ErrorText error="Site not found" from="NewMetricPage" />;
        }
        if (!data.eventTypes) {
            return (
                <ErrorText error="Cannot get eventTypes" from="NewMetricPage" />
            );
        }
        return (
            <Form onSubmit={props.onSubmit}>
                <Row>
                    <Col sm={6}>
                        <MetricEditor
                            formControl={props.formControl}
                            site={data.siteById}
                            collections={
                                cmsCollectionsQuery.data?.cmsCollections[0] ||
                                []
                            }
                            siteHtmlElements={data.siteHtmlElements
                                ?.map((el) => ({
                                    id: el.id || "",
                                    text: el.text || "",
                                }))
                                .filter((el) => el.id)}
                        />
                    </Col>
                    <Col sm={6}>
                        <div>
                            <TimeframeButton
                                onDateChange={({
                                    timeframe,
                                    endAt,
                                    startAt,
                                }) => {
                                    setTimeframe(timeframe);
                                    if (startAt && endAt) {
                                        setDates({ startAt, endAt });
                                    }
                                }}
                                triggerUpgradeModal={props.triggerUpgradeModal}
                                paidFeatures={data.organisation?.features || []}
                            />
                        </div>
                        <PreviewChart
                            metricName={formValues.name}
                            {...formValues}
                            siteId={siteId}
                            organisationId={organisationId}
                            timeframe={timeframe}
                            startAt={dates?.startAt.toString() || ""}
                            endAt={dates?.endAt.toString() || ""}
                        />
                    </Col>
                </Row>
                <p className="mt-3">
                    <a
                        href="https://www.nocodelytics.com/help-center/metrics-overview"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Learn about metrics in our guide.
                    </a>
                </p>
            </Form>
        );
    };
