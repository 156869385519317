import React from "react";
import { useParams } from "react-router";

import { RouterURLParams } from "../../AppRouter";
import { ErrorText } from "../../components";
import { MetricListContainer } from "../../containers";
import {
    useDashboardByIdQuery,
    useOrganisationLazyQuery,
    useSiteByIdLazyQuery,
} from "../../generated/graphql";

export const EmbeddedMetricsPage: React.FunctionComponent = () => {
    const { dashboardId } = useParams<RouterURLParams["dashboardDetails"]>();
    const [getOrganisationByIdQuery, organisationQueryResults] =
        useOrganisationLazyQuery();
    const [getSiteByIdQuery, siteByIdQueryResults] = useSiteByIdLazyQuery();
    const dashboardByIdQuery = useDashboardByIdQuery({
        variables: { dashboardId },
        onCompleted: ({ dashboard: { siteId, organisationId } }) => {
            getOrganisationByIdQuery({ variables: { organisationId } });
            getSiteByIdQuery({ variables: { id: siteId } });
        },
    });
    const error =
        siteByIdQueryResults.error ||
        organisationQueryResults.error ||
        dashboardByIdQuery.error;
    const loading =
        siteByIdQueryResults.loading ||
        organisationQueryResults.loading ||
        dashboardByIdQuery.loading;
    const data = {
        ...siteByIdQueryResults.data,
        ...organisationQueryResults.data,
        ...dashboardByIdQuery.data,
    };

    const { siteId, organisationId } = data.dashboard || {
        siteId: "",
        organisationId: "",
    };

    if (error) {
        return (
            <ErrorText
                error={error}
                from="EmbeddedMetricsPage"
                location="ebc4fe46-1600-4890-8f1a-d9cacb29cedf"
            />
        );
    }

    if (!data?.siteById) {
        return !loading && siteByIdQueryResults.called ? (
            <ErrorText
                error="Site not found"
                from="EmbeddedMetricsPage"
                location="5f8d390e-882f-4137-a989-c0215d2dd8db"
            />
        ) : (
            <div />
        );
    }
    if (
        !dashboardByIdQuery.data?.dashboard.isPublic &&
        !data.siteById.isPublic
    ) {
        return <div>Embedded dashboard disabled.</div>;
    }

    return (
        <MetricListContainer
            triggerUpgradeModal={() => {
                window.alert("Paid feature not enabled.");
            }}
            organisationId={organisationId}
            siteId={siteId}
            dashboardId={dashboardId}
            isEmbedded
            isPublic
        />
    );
};
