import { faBolt, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Image, ListGroup, Modal } from "react-bootstrap";

import customerLogoCalm from "../../assets/images/customer_logo_calm.png";
import customerLogoDuke from "../../assets/images/customer_logo_duke.png";
import customerLogoWebflow from "../../assets/images/customer_logo_webflow.png";

export interface UpgradeModalProps {
    show: boolean;
    onClose: () => void;
    onUpgradeClick: () => void;
}

export const UpgradeModal: React.FunctionComponent<UpgradeModalProps> = (
    props,
) => {
    return (
        <Modal
            show={props.show}
            onHide={props.onClose}
            style={{ backdropFilter: props.show ? "blur(10px)" : "" }}
            className="d-flex justify-content-center align-items-center"
        >
            <Modal.Header className="text-white bg-primary" closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faBolt} className="me-2" />
                    Upgrade for more
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <h5 className="fw-bold mb-3">
                    Don&apos;t miss out on valuable insights.
                </h5>
                <h5 className="fw-bold mb-3">
                    Track more visitors from just $29 per month.
                </h5>
                <ListGroup variant="flush">
                    {[
                        "No risk of tracking being disabled",
                        "Embed dashboard anywhere",
                        "Invite users",
                    ].map((feature) => (
                        <ListGroup.Item
                            key={feature}
                            className="border-0 ps-0 text-muted"
                        >
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="me-2 text-success"
                            />{" "}
                            {feature}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <div className="mt-3 d-flex">
                    <div className="border-0 text-uppercase fw-bold text-muted d-flex align-items-center">
                        Trusted by
                    </div>
                    <div className="ms-4 d-flex align-items-center">
                        <Image
                            className="Customer_Logo"
                            src={customerLogoWebflow}
                            width={100}
                        />
                        <Image
                            className="Customer_Logo ms-3"
                            src={customerLogoDuke}
                            width={75}
                        />
                        <Image
                            className="Customer_Logo ms-5"
                            src={customerLogoCalm}
                            width={80}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="m-auto p-2">
                    <Button
                        className="hvr-buzz--short hvr-buzz fw-bolder"
                        variant="warning"
                        onClick={props.onUpgradeClick}
                    >
                        Upgrade to Premium →
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
