import React, { useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { Redirect, useParams } from "react-router-dom";

import { RouterURLParams, routerPath } from "../../AppRouter";
import { MainLayout } from "../../Layout";
import successIllustration from "../../assets/images/celebration.svg";
import { CodeSnippet, ErrorText, Snippet } from "../../components";
import { Loading } from "../../components/Loading";
import { StyledH4, StyledP } from "../../components/Typography";
import { config } from "../../config";
import {
    CheckScriptHeaderMutationResult,
    Feature,
    useCheckScriptHeaderMutation,
    useOrganisationQuery,
    useSiteByIdQuery,
    useSitesQuery,
} from "../../generated/graphql";

const SiteEnablePageError = ({
    checkScriptHeaderMutationResults,
}: {
    checkScriptHeaderMutationResults: CheckScriptHeaderMutationResult;
}) => {
    if (
        !checkScriptHeaderMutationResults.called ||
        checkScriptHeaderMutationResults.loading
    ) {
        return <Loading />;
    }
    return (
        <>
            {!checkScriptHeaderMutationResults.data?.checkScriptHeader.found ? (
                <p className="text-danger fw-bold">
                    We looked everywhere but we couldn&apos;t find the script on
                    your site. Did you publish it?{" "}
                    {
                        checkScriptHeaderMutationResults.data?.checkScriptHeader
                            .errorMessage
                    }
                </p>
            ) : null}

            {checkScriptHeaderMutationResults &&
                checkScriptHeaderMutationResults.error && (
                    <div>
                        <p className="fw-bold">Debugging information</p>
                        <Snippet
                            code={
                                checkScriptHeaderMutationResults.error.message
                            }
                        />
                    </div>
                )}
        </>
    );
};

export const SiteEnablePage: React.FunctionComponent = () => {
    const [showGif, setShowGif] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const { organisationId, siteId } =
        useParams<RouterURLParams["siteEnable"]>();
    const siteByIdQueryResults = useSiteByIdQuery({
        variables: { id: siteId },
        fetchPolicy: "no-cache",
    });
    const organisationQueryResults = useOrganisationQuery({
        variables: { siteId },
    });
    const sitesQueryResults = useSitesQuery({
        variables: { organisationId, first: 4 },
    });
    const [checkScriptHeaderMutation, checkScriptHeaderMutationResults] =
        useCheckScriptHeaderMutation({ variables: { siteId } });

    const data = {
        ...sitesQueryResults.data,
        ...organisationQueryResults.data,
        ...siteByIdQueryResults.data,
    };
    const error =
        siteByIdQueryResults.error ||
        organisationQueryResults.error ||
        sitesQueryResults.error;
    const loading =
        siteByIdQueryResults.loading ||
        organisationQueryResults.loading ||
        sitesQueryResults.loading;

    const sites = data.sites?.edges.map(({ node }) => node) || [];

    if (loading) {
        return <Loading makeItFun />;
    }
    if (error) {
        return <ErrorText error={error} from="SiteEnablePage" />;
    }
    if (!data || !data.siteById) {
        return <ErrorText error="Site not found" from="SiteEnablePage" />;
    }

    if (checkScriptHeaderMutationResults.data?.checkScriptHeader.found) {
        return (
            <MainLayout
                siteId={siteId}
                showUpgradeModal={showUpgradeModal}
                triggerUpgradeModal={() => setShowUpgradeModal(true)}
                onUpgradeModalClose={() => setShowUpgradeModal(false)}
            >
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <div className="d-flex flex-column justify-content-center align-items-center text-center">
                            <StyledH4 className="fw-bold">
                                Hooray, you&apos;re all set!
                            </StyledH4>
                            <img
                                style={{ maxWidth: 250 }}
                                src={successIllustration}
                                alt="success illustration"
                            />
                            <StyledP className="fw-bold mt-5">
                                You set up tracking for {data.siteById.name}.
                            </StyledP>
                            <StyledP className="mt-2">
                                Now let&apos;s go to your dashboard and start
                                understanding some of that data.
                            </StyledP>
                            <Button
                                className="fw-bold"
                                id="go-to-dashboard"
                                href={routerPath.siteDetails({
                                    organisationId,
                                    siteId,
                                })}
                            >
                                Take me to my dashboard
                            </Button>
                        </div>
                    </Col>
                </Row>
            </MainLayout>
        );
    }

    if (data.siteById.isEnabled) {
        return (
            <MainLayout
                siteId={siteId}
                showUpgradeModal={showUpgradeModal}
                triggerUpgradeModal={() => setShowUpgradeModal(true)}
                onUpgradeModalClose={() => setShowUpgradeModal(false)}
            >
                <p>This site is already enabled.</p>
                <Redirect
                    to={routerPath.siteDetails({ organisationId, siteId })}
                />
                <Button
                    href={routerPath.siteDetails({ organisationId, siteId })}
                    className="fw-bold"
                >
                    Take me to my dashboard
                </Button>
            </MainLayout>
        );
    }

    return (
        <MainLayout
            siteId={siteId}
            title={`Set up tracking for ${data.siteById.name}`}
            showUpgradeModal={showUpgradeModal}
            triggerUpgradeModal={() => setShowUpgradeModal(true)}
            onUpgradeModalClose={() => setShowUpgradeModal(false)}
        >
            <StyledP className="fw-bold">
                1. Copy the below script to your Webflow site
            </StyledP>
            <CodeSnippet apiUrl={config.api.url} siteId={siteId} />
            <StyledP className="fw-bold mt-4">
                2. Paste it into Project Settings → Custom Code → Head Code (
                <a
                    href={`https://webflow.com/dashboard/sites/${data.siteById.shortName}/code`}
                    target="_blank"
                    rel="noreferrer"
                >
                    take me there
                </a>
                ).
            </StyledP>
            <div className="d-flex flex-column align-items-baseline">
                <Button
                    variant="link"
                    onClick={() => setShowGif(!showGif)}
                    className="p-0 text-decoration-underline text-black"
                >
                    Show me the GIF.
                </Button>
                {showGif && <Image src="/assets/setup-snippet.gif" />}
            </div>
            <StyledP className="fw-bold mt-4">
                3. Don&apos;t forget to save and publish your site.
            </StyledP>
            <Row>
                <Col>
                    <Button
                        id="test-btn"
                        className="mb-2"
                        size="lg"
                        disabled={checkScriptHeaderMutationResults.loading}
                        onClick={() => {
                            if (
                                sites.filter(({ isEnabled }) => isEnabled)
                                    .length >= 3 &&
                                !data.organisation?.features.includes(
                                    Feature.UnlimitedSites,
                                )
                            ) {
                                setShowUpgradeModal(true);
                                return;
                            }
                            checkScriptHeaderMutation();
                        }}
                    >
                        {checkScriptHeaderMutationResults.loading
                            ? `Loading...`
                            : `Enable`}
                    </Button>
                </Col>
                {checkScriptHeaderMutationResults.called ? (
                    <Col xs={12}>
                        <SiteEnablePageError
                            checkScriptHeaderMutationResults={
                                checkScriptHeaderMutationResults
                            }
                        />
                    </Col>
                ) : null}
            </Row>
        </MainLayout>
    );
};
