import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Breadcrumb, Col, ListGroup, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";

import { RouterURLParams, routerPath } from "../../AppRouter/routerPath";
import { MainLayout } from "../../Layout";
import { ErrorText, Loading } from "../../components";
import { HoverText } from "../../components/Typography";
import {
    EventType,
    useEventTypesQuery,
    useEventsQuery,
    useSiteByIdQuery,
    useVisitorQuery,
} from "../../generated/graphql";
import { getEventIcon } from "../../helpers/getEventIcon";
import { VisitorDetailsCard } from "./VisitorDetailsCard";
import { VisitorEventsTable } from "./VisitorEventsTable";

export const VisitorDetailsPage: React.FunctionComponent = () => {
    const history = useHistory();
    const [selectedEventType, setSelectedEventType] = useState(EventType.View);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const { siteId } = useParams<RouterURLParams["siteVisitors"]>();
    const { visitorId } = useParams<RouterURLParams["siteVisitorDetails"]>();
    const eventTypesResults = useEventTypesQuery();
    const eventsQueryResults = useEventsQuery({
        variables: {
            filters: { eventType: selectedEventType, siteId, visitorId },
        },
    });
    const siteByIdResults = useSiteByIdQuery({ variables: { id: siteId } });
    const visitorQueryResults = useVisitorQuery({
        variables: { id: visitorId, siteId },
    });
    const loading = siteByIdResults.loading || visitorQueryResults.loading;

    const error = siteByIdResults.error || visitorQueryResults.error;

    const data = {
        ...siteByIdResults.data,
        ...visitorQueryResults.data,
        ...eventsQueryResults.data,
        ...eventTypesResults.data,
    };

    if (loading) {
        return <Loading makeItFun />;
    }

    if (error || !data.visitor || !data.siteById) {
        return (
            <MainLayout
                siteId={siteId}
                title="Error getting user details"
                loading={loading}
                showUpgradeModal={showUpgradeModal}
                triggerUpgradeModal={() => setShowUpgradeModal(true)}
                onUpgradeModalClose={() => setShowUpgradeModal(false)}
            >
                <ErrorText
                    from="UserDetailsPage"
                    error={error || "User details not available"}
                    inline
                />
            </MainLayout>
        );
    }

    return (
        <MainLayout
            siteId={siteId}
            loading={loading}
            showUpgradeModal={showUpgradeModal}
            triggerUpgradeModal={() => setShowUpgradeModal(true)}
            onUpgradeModalClose={() => setShowUpgradeModal(false)}
        >
            <Row>
                <Col>
                    <Breadcrumb className="Breadcrumb">
                        <Breadcrumb.Item
                            onClick={() =>
                                history.push(
                                    routerPath.siteRedirect({
                                        siteId,
                                    }),
                                )
                            }
                            className="fw-bold"
                        >
                            {data.siteById.name}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClick={() =>
                                history.push(
                                    routerPath.siteVisitors({
                                        siteId,
                                    }),
                                )
                            }
                            className="fw-bold"
                        >
                            Visitors
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <HoverText
                                text={
                                    data.visitor?.emailAddress ||
                                    data.visitor?.id
                                }
                            >
                                {data.visitor?.emailAddress || data.visitor?.id}
                            </HoverText>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h3>{data.visitor.emailAddress || data.visitor.id}</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <VisitorDetailsCard
                        id={data.visitor?.id}
                        city={data.visitor?.city}
                        emailAddress={data.visitor?.emailAddress}
                        isMember={!!data.visitor?.emailAddress}
                        initialReferer={data.visitor?.initialReferer}
                        lastInteraction={data.visitor?.lastInteraction}
                    />
                </Col>
            </Row>

            <ListGroup horizontal className="mb-3">
                {data.eventTypes?.map((eventType) => (
                    <ListGroup.Item
                        className="text-center"
                        action
                        key={eventType}
                        onClick={() => setSelectedEventType(eventType)}
                        active={selectedEventType === eventType}
                    >
                        <FontAwesomeIcon
                            className="me-3"
                            icon={getEventIcon(eventType)}
                        />
                        {eventType}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <VisitorEventsTable events={data.events || []} />
        </MainLayout>
    );
};
