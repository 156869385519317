import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

interface CopyToClipboardButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    text: string;
    variant?: string;
}

export const CopyToClipboardButton: React.FunctionComponent<CopyToClipboardButtonProps> =
    ({ text, variant }) => {
        const [copied, setCopied] = useState(false);

        const handleCopy = () => {
            setCopied(true);
            navigator.clipboard.writeText(text);
        };

        return (
            <Button
                onClick={handleCopy}
                className="Copy_Button d-flex align-items-center"
                variant={variant ? variant : "primary"}
            >
                <FontAwesomeIcon icon={faCopy} className="me-2" />
                {!copied ? "Copy" : "Copied!"}
            </Button>
        );
    };
