import chunk from "lodash.chunk";
import flatten from "lodash.flatten";
import React, { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";

export interface GridLayoutData {
    id: string;
    x: number;
    y: number;
    h: number;
    w: number;
}

export interface GridLayoutProps {
    elements: Array<{
        id: string;
        element: ReactNode;
    }>;
    layout?: GridLayoutData[] | null;
    onLayoutChange: (layout: GridLayoutData[]) => void;
    isEditable?: boolean;
}

const Card = styled.div`
    border-radius: 10px;
`;

const ResponsiveGridLayout = WidthProvider(Responsive);

export const buildLayouts = (props: GridLayoutProps): Layouts => {
    return {
        lg:
            props.layout?.map(({ id: i, ...rest }) => ({
                i,
                ...rest,
                minW: 1,
            })) ||
            flatten(
                chunk(props.elements, 3).map((chunks, y) =>
                    chunks.map(({ id }, x) => ({
                        i: id,
                        x: x * 4,
                        y,
                        w: 1,
                        h: 1,
                    })),
                ),
            ),
        sm: props.elements.map(({ id }, y) => ({
            i: id,
            x: 0,
            y,
            w: 1,
            h: 1,
            isDraggable: false,
            isResizable: false,
        })),
    };
};

export const GridLayout: React.FunctionComponent<GridLayoutProps> = (props) => {
    const onLayoutChange = (layout: Layout[]) => {
        props.onLayoutChange(
            layout.map(({ i: id, x, y, h, w }) => ({ id, x, y, h, w })),
        );
    };
    return (
        <Row>
            <Col>
                <ResponsiveGridLayout
                    className="layout"
                    measureBeforeMount
                    layouts={buildLayouts(props)}
                    cols={{ lg: 3, sm: 1 }}
                    breakpoints={{ sm: 500, lg: 930 }}
                    rowHeight={284}
                    margin={[20, 20]}
                    compactType={props.layout ? "vertical" : "horizontal"}
                    isResizable={props.isEditable}
                    isDraggable={props.isEditable}
                    onResizeStop={(layout) => onLayoutChange(layout)}
                    onDragStop={(layout) => onLayoutChange(layout)}
                    autoSize
                >
                    {props.elements.map(({ id, element }) => (
                        <Card
                            className="GridLayout__Card shadow-sm bg-white p-3"
                            key={id}
                        >
                            {element}
                        </Card>
                    ))}
                </ResponsiveGridLayout>
            </Col>
        </Row>
    );
};
