import React from "react";
import { useHistory } from "react-router";

import { routerPath } from "../../AppRouter";
import { ErrorText, SitesList } from "../../components";
import {
    Feature,
    useOrganisationQuery,
    useSitesListContainerQuery,
} from "../../generated/graphql";
import { SitesEmptyState } from "./SitesEmptyState";

interface SitesListContainer {
    organisationId: string;
    filters: { name: string };
    onPaidFeatureClick: () => void;
}

export const SitesListContainer: React.FunctionComponent<SitesListContainer> = (
    props,
) => {
    const history = useHistory();
    const { organisationId } = props;
    const sitesQueryResults = useSitesListContainerQuery({
        variables: { organisationId },
    });
    const organisationQueryResults = useOrganisationQuery({
        variables: { organisationId },
    });

    const data = {
        ...sitesQueryResults.data,
        ...organisationQueryResults.data,
    };
    const error = sitesQueryResults.error || organisationQueryResults.error;
    const loading =
        sitesQueryResults.loading || organisationQueryResults.loading;

    if (error) {
        return <ErrorText from="SitesPage" error={error} />;
    }

    if (
        data.sites?.edges.length === 0 &&
        !loading &&
        sitesQueryResults.called
    ) {
        return <SitesEmptyState />;
    }

    const sites =
        data.sites?.edges
            .map(({ node }) => node)
            .filter(
                (site) =>
                    !props.filters.name ||
                    site.name
                        .toLowerCase()
                        .includes(props.filters.name.toLowerCase()),
            ) || [];

    return (
        <SitesList
            sites={sites.map((site) => ({ ...site, organisationId }))}
            loading={loading}
            hasNextPage={false}
            onEnableClick={({ siteId }) => {
                if (
                    sites.filter(({ isEnabled }) => isEnabled).length >= 3 &&
                    !data.organisation?.features.includes(
                        Feature.UnlimitedSites,
                    )
                ) {
                    props.onPaidFeatureClick();
                    return;
                }
                history.push(
                    routerPath.siteEnable({
                        organisationId,
                        siteId,
                    }),
                );
            }}
        />
    );
};
