import { faPlug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import styled from "styled-components";

import emptyStateIllustration from "../../assets/images/missing.svg";
import { StyledH4, StyledP } from "../../components/Typography";
import { config } from "../../config";

const EmptyStateImage = styled(Image)`
    width: 250px;
    margin: 25px 0px;
`;

interface SitesEmptyStateProps {
    firstName?: string | null;
}

export const SitesEmptyStateWrapper = styled.div`
    padding: 50px;
    text-align: center;
`;

export const SitesEmptyState: React.FunctionComponent<SitesEmptyStateProps> =
    () => {
        return (
            <SitesEmptyStateWrapper>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <StyledH4>Oops, no sites found here</StyledH4>
                        <EmptyStateImage src={emptyStateIllustration} />
                        <StyledP>
                            It looks like something went wrong whilst connecting
                            to your Webflow account. Click the button below to
                            try again.
                        </StyledP>
                        <a href={`${config.api.url}/auth/webflow`}>
                            <Button className="fw-bold">
                                <FontAwesomeIcon
                                    icon={faPlug}
                                    className="me-2"
                                />
                                Reconnect Webflow account
                            </Button>
                        </a>
                    </Col>
                </Row>
                <div>
                    Something wrong?{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={config.app.supportUrl}
                    >
                        Chat with us.
                    </a>
                </div>
            </SitesEmptyStateWrapper>
        );
    };
