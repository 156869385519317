import emojiFlags from "emoji-flags";
import React from "react";

import { ListChart, ListChartProps } from "../ListChart";

interface TopCountriesChartProps {
    values: ListChartProps["values"];
}

export const TopCountriesChart: React.FunctionComponent<TopCountriesChartProps> =
    ({ values }) => {
        return (
            <ListChart
                values={values.map(({ label, ...rest }) => {
                    const { emoji = "❓", name: emojiName = "Unknown" } =
                        (label && emojiFlags.countryCode(label)) || {};
                    return {
                        ...rest,
                        label: `${emoji} ${emojiName}`,
                    };
                })}
            />
        );
    };
