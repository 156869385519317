import React from "react";
import { Card } from "react-bootstrap";
import { v4 as uuid } from "uuid";

import { config, localStorageKeys } from "../config";
import { useReportErrorMutation } from "../generated/graphql";

interface ErrorTextProperties {
    error: string | object | Error;
    from: string;
    context?: object;
    text?: string;
    location?: string;
    inline?: boolean;
}

export const ErrorText: React.FunctionComponent<ErrorTextProperties> = (
    props,
) => {
    const errorId = uuid();
    const token = localStorage.getItem(localStorageKeys.token);
    const params = { ...props, token, errorId };
    const body = ["Hi,", "I have this error to report", JSON.stringify(params)];
    const [reportError] = useReportErrorMutation();
    console.error(params);
    const error =
        typeof props.error === "string"
            ? props.error
            : JSON.stringify(props.error, undefined, 2);
    if (config.env === "production") {
        reportError({
            fetchPolicy: "network-only",
            variables: {
                input: {
                    from: props.from,
                    error,
                    message: `errorId: ${errorId}: props.text`,
                },
            },
        });
    }
    return (
        <Card
            className={
                props.inline
                    ? ""
                    : "position-absolute top-50 start-50 translate-middle"
            }
            bg="light"
        >
            <Card.Body>
                <Card.Text>
                    <span>Oops... Something went wrong.</span>
                    <span>
                        Please,{" "}
                        <a
                            href={`mailto:${
                                config.email.supportEmail
                            }?subject=I have an issue&body=${body.join(" ")}`}
                            className="text-primary"
                            target="_blank"
                            rel="noreferrer"
                        >
                            email our support team
                        </a>{" "}
                        with this error or{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={config.app.supportUrl}
                        >
                            chat with us
                        </a>
                        .
                    </span>
                    <span className="font-monospace">{props.text}</span>
                    <span className="font-monospace">{error}</span>
                </Card.Text>
            </Card.Body>
        </Card>
    );
};
