import { isEmpty } from "lodash";
import React, { useState } from "react";

import { GridLayout, GridLayoutData } from "../../Layout";
import { ActionsBar, ErrorText, MetricsEmptyState } from "../../components";
import { FilterData } from "../../components/Filters/Filters";
import {
    Feature,
    MetricTimeframe,
    MetricType,
    useCurrentUserQuery,
    useDashboardByIdQuery,
    useMetricsByDashboardIdQuery,
    useOrganisationQuery,
    usePlanExceededQuery,
    useSiteByIdQuery,
    useUpdateDashboardMutation,
} from "../../generated";
import { ChartContainer } from "../Chart";
import { MetricsListLoading } from "./MetricsListLoading";
import { PlanExceeded } from "./PlanExceeded";

interface MetricListContainerProps {
    organisationId: string;
    siteId: string;
    dashboardId: string;
    triggerUpgradeModal?: () => void;
    isEmbedded?: boolean;
    isPublic: boolean | undefined;
    handleDeleteDashboard?: (dashboardId: string) => void;
    showShare?: boolean;
    setShowShare?: (value: boolean) => void;
    hideDatepicker?: boolean;
}

export const MetricListContainer: React.FunctionComponent<MetricListContainerProps> =
    (props) => {
        const [startAt, setStartAt] = useState<Date>();
        const [endAt, setEndAt] = useState<Date | undefined>();

        const [reorderingMetrics, setReorderingMetrics] = useState(false);
        const [selectedTimeframe, setTimeframe] = useState<
            MetricTimeframe | undefined
        >();
        const [filters, setFilters] = useState<FilterData>({});
        const { siteId, organisationId, dashboardId } = props;

        const siteByIdResults = useSiteByIdQuery({
            variables: { id: props.siteId },
        });
        const metricsByDashboardIdResuls = useMetricsByDashboardIdQuery({
            variables: { dashboardById: props.dashboardId },
        });
        const dashboardByIdResults = useDashboardByIdQuery({
            variables: { dashboardId: props.dashboardId },
        });

        const currentUserQueryResults = useCurrentUserQuery();
        const organisationQueryResults = useOrganisationQuery({
            variables: {
                organisationId:
                    siteByIdResults.data?.siteById.organisationId || "",
            },
            skip: isEmpty(siteByIdResults.data?.siteById.organisationId),
        });
        const planExceededResponse = usePlanExceededQuery({
            variables: {
                organisationId:
                    siteByIdResults.data?.siteById.organisationId || "",
            },
            skip: isEmpty(siteByIdResults.data?.siteById.organisationId),
        });

        const [updateDashboard, updateDashboardResults] =
            useUpdateDashboardMutation();

        const error =
            metricsByDashboardIdResuls.error ||
            siteByIdResults.error ||
            currentUserQueryResults.error ||
            updateDashboardResults.error ||
            organisationQueryResults.error ||
            dashboardByIdResults.error;
        const loading =
            metricsByDashboardIdResuls.loading ||
            siteByIdResults.loading ||
            currentUserQueryResults.loading ||
            organisationQueryResults.loading ||
            dashboardByIdResults.loading;
        const data = {
            ...metricsByDashboardIdResuls.data,
            ...siteByIdResults.data,
            ...organisationQueryResults.data,
            ...currentUserQueryResults.data,
            ...dashboardByIdResults.data,
        };

        if (error) {
            return <ErrorText error={error} from="SiteEnablePage" />;
        }

        if (loading) {
            return <MetricsListLoading />;
        }

        const metricCount = data?.metricsByDashboardId?.length || 0;

        const organisationFeatures =
            (data.organisation?.features as unknown as Feature[]) || [];
        const urlSearchParams = new URLSearchParams(window.location.search);
        const metricFilterFromQs = urlSearchParams.getAll("metric");
        const hideDatepicker =
            urlSearchParams.get("hide_datepicker") === "true" ||
            props.hideDatepicker;
        const defaultTimeframe = organisationFeatures.includes(
            Feature.HistoricalData,
        )
            ? MetricTimeframe.Last30days
            : MetricTimeframe.Last7days;
        const timeframe =
            typeof selectedTimeframe !== "undefined"
                ? selectedTimeframe
                : defaultTimeframe;

        const isEditable =
            reorderingMetrics &&
            (metricFilterFromQs.length === 0 || !props.isEmbedded);
        return (
            <>
                {!hideDatepicker ? (
                    <ActionsBar
                        siteId={props.siteId}
                        organisationId={props.organisationId}
                        isPublic={props.isPublic}
                        updateDashboard={updateDashboard}
                        handleDeleteDashboard={props.handleDeleteDashboard}
                        dashboardId={dashboardId}
                        onDateChange={(dates) => {
                            setStartAt(dates.startAt);
                            setEndAt(dates.endAt);
                            setTimeframe(dates.timeframe);
                        }}
                        onFilterChange={(filters) => setFilters(filters)}
                        filters={filters}
                        metricCount={metricCount}
                        paidFeatures={organisationFeatures}
                        triggerUpgradeModal={props.triggerUpgradeModal}
                        hidePaidFeatures={props.isEmbedded}
                        isEmbedded={props.isEmbedded}
                        reorderingMetrics={reorderingMetrics}
                        onReorderingMetricsClick={() =>
                            setReorderingMetrics(!reorderingMetrics)
                        }
                        showShare={props.showShare}
                        setShowShare={props.setShowShare}
                    />
                ) : null}
                {planExceededResponse.data?.planExceeded.planExceeded ? (
                    <PlanExceeded
                        organisationId={organisationId}
                        siteId={siteId}
                    />
                ) : null}
                {metricCount === 0 ||
                planExceededResponse.data?.planExceeded.planExceeded ? (
                    <MetricsEmptyState firstName={data?.me?.user.firstname} />
                ) : (
                    <GridLayout
                        layout={
                            metricFilterFromQs.length === 0 && data.siteById
                                ? (data.dashboard
                                      ?.gridLayout as GridLayoutData[])
                                : null
                        }
                        onLayoutChange={(gridLayout) => {
                            if (
                                metricFilterFromQs.length > 0 ||
                                props.isEmbedded
                            ) {
                                return;
                            }
                            updateDashboard({
                                variables: {
                                    input: {
                                        id: props.dashboardId,
                                        gridLayout,
                                    },
                                },
                                fetchPolicy: "no-cache",
                            });
                        }}
                        elements={
                            data.metricsByDashboardId
                                ?.filter(({ id }) =>
                                    metricFilterFromQs.length === 0
                                        ? true
                                        : metricFilterFromQs.includes(id),
                                )
                                ?.map((metric) => ({
                                    id: metric.id,
                                    element: (
                                        <ChartContainer
                                            startAt={startAt?.toString()}
                                            endAt={endAt?.toString()}
                                            timeframe={timeframe}
                                            organisationId={organisationId}
                                            siteId={siteId}
                                            metric={{
                                                ...metric,
                                                metricType:
                                                    metric.metricType as unknown as MetricType,
                                            }}
                                            isEditable={isEditable}
                                            isMember={filters.isMember}
                                        />
                                    ),
                                })) || []
                        }
                        isEditable={isEditable}
                    />
                )}
            </>
        );
    };
