import { faCopy, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, InputGroup, Modal, Form } from "react-bootstrap";

import { routerPath } from "../../../../AppRouter";
import { config } from "../../../../config";
import {
    Dashboard,
    Feature,
    Organisation,
    Site,
    UpdateDashboardMutationOptions,
} from "../../../../generated";
import { EmbedDashboardDescription } from "../../../../pages/SiteSettings/EmbedDashboardDescription";
import { Loading } from "../../../Loading";
import { StyledH5, StyledP } from "../../../Typography";

interface ShareDashboardProps {
    siteId: Site["id"];
    showShare?: boolean;
    handleClose: () => void;
    dashboardId: Dashboard["id"];
    updateDashboard: (options: UpdateDashboardMutationOptions) => Promise<void>;
    isPublic: boolean | undefined;
    organisationId: Organisation["id"];
    paidFeatures: Feature[];
}

export const ShareDashboardModal: React.FunctionComponent<ShareDashboardProps> =
    (props) => {
        const initialShareButtonState = {
            text: "Sharing is",
            loading: false,
        };

        const initialCopyButtonState = {
            text: "Copy",
            color: "primary",
        };

        const [shareButtonState, setShareButtonState] = useState(
            initialShareButtonState,
        );

        const [copyButtonState, setCopyButtonState] = React.useState(
            initialCopyButtonState,
        );

        const dashboardLink: string = `${
            config.app.baseUrl
        }${routerPath.embeddedMetrics(props)}`;

        return (
            <Modal
                size="lg"
                show={props.showShare}
                centered
                onHide={props.handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Share and embed dashboard</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StyledP>
                        Share a read-only link or embed your entire your
                        dashboard.
                    </StyledP>
                    <StyledP className="mb-4">
                        People will be able to view your dashboard and select
                        dates but will not be able to make changes.
                    </StyledP>
                    <div className="d-flex mb-4">
                        <div className="flex-shrink-0 me-2">
                            <Button
                                className="fw-bold d-flex align-items-center justify-content-center"
                                variant="outline-primary"
                                disabled={shareButtonState.loading}
                                onClick={async () => {
                                    setShareButtonState({
                                        ...shareButtonState,
                                        loading: true,
                                    });
                                    await props.updateDashboard({
                                        variables: {
                                            input: {
                                                id: props.dashboardId,
                                                isPublic: !props.isPublic,
                                            },
                                        },
                                    });
                                    setShareButtonState({
                                        ...shareButtonState,
                                        loading: false,
                                    });
                                }}
                            >
                                <>
                                    {shareButtonState.loading ? (
                                        <>
                                            <Loading className="me-2" />
                                            Updating...
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon
                                                icon={
                                                    props.isPublic
                                                        ? faLockOpen
                                                        : faLock
                                                }
                                                className={`me-2
                                            ${
                                                props.isPublic
                                                    ? `text-success`
                                                    : `text-danger`
                                            }
                                        `}
                                            />
                                            {"  "}
                                            {shareButtonState.text}{" "}
                                            {props.isPublic ? "on" : "off"}
                                        </>
                                    )}
                                </>
                            </Button>
                        </div>
                        <div className="flex-grow-1">
                            <InputGroup className="mb-2">
                                <Form.Control
                                    value={dashboardLink}
                                    onChange={() => null}
                                    disabled={!props.isPublic}
                                />
                                {props.isPublic ? (
                                    <Button
                                        variant={copyButtonState.color}
                                        id="button-addon2"
                                        onClick={() => {
                                            setCopyButtonState({
                                                text: "Copied!",
                                                color: "success",
                                            });
                                            navigator.clipboard.writeText(
                                                dashboardLink,
                                            );

                                            const timeout = setTimeout(() => {
                                                setCopyButtonState(
                                                    initialCopyButtonState,
                                                );
                                            }, 1000);

                                            return () => clearTimeout(timeout);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCopy}
                                            className="me-2"
                                        />
                                        {copyButtonState.text}
                                    </Button>
                                ) : null}
                            </InputGroup>
                        </div>
                    </div>
                    {props.isPublic ? (
                        <>
                            <StyledH5>Embed dashboard </StyledH5>
                            <StyledP>
                                Paste the below code into an HTML Embed element
                                and set the height and width.
                            </StyledP>
                            <EmbedDashboardDescription
                                organisationId={props.organisationId}
                                siteId={props.siteId}
                                dashboardId={props.dashboardId}
                            />
                        </>
                    ) : null}
                </Modal.Body>
            </Modal>
        );
    };
