import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { MainLayout } from "../../Layout";
import { Divider } from "../../components/Typography";
import { config } from "../../config";

export interface GdprPageProps {}
export const GdprPage: React.FC<GdprPageProps> = () => {
    const formControl = useForm<{
        name: string;
        address: string;
        confirmRead: boolean;
        confirmPaymentRequired: boolean;
    }>();

    return (
        <MainLayout>
            <Row className="mb-3">
                <Col>
                    <h3>GDPR Documents</h3>
                </Col>
            </Row>
            <Divider />
            <div>
                <b>
                    Privacy Policy:{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.nocodelytics.com/privacy"
                    >
                        https://www.nocodelytics.com/privacy
                    </a>
                </b>
            </div>
            <div>
                <b>
                    Terms and Conditions:{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.nocodelytics.com/terms-and-conditions"
                    >
                        https://www.nocodelytics.com/terms-and-conditions
                    </a>
                </b>
            </div>
            <Divider />
            <h4>Data Processing Agreement</h4>
            <div className="mb-3">
                <b>Sample Agreement: </b>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.nocodelytics.com/dpa"
                >
                    https://www.nocodelytics.com/dpa
                </a>
            </div>
            <div>
                <Form
                    onSubmit={formControl.handleSubmit((data) => {
                        const params = new URLSearchParams();
                        params.set("name", data.name);
                        params.set("address", data.address);
                        window.open(
                            `${
                                config.app.baseUrl
                            }/api/generate/dpa?${params.toString()}`,
                            "_blank",
                        );
                    })}
                >
                    <Form.Group className="mb-3">
                        <Form.Label>Your organisation name</Form.Label>
                        <Form.Control
                            onChange={(e) =>
                                formControl.setValue("name", e.target.value)
                            }
                            type="text"
                            placeholder="ABC Limited"
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            onChange={(e) =>
                                formControl.setValue("address", e.target.value)
                            }
                            type="text"
                            placeholder="123 Example Street, Anytown, AN1 2BC"
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check
                            onChange={(e) =>
                                formControl.setValue(
                                    "confirmRead",
                                    e.target.checked,
                                )
                            }
                            type="checkbox"
                            label="I confirm I have read and agree to the terms of the Data Processing Agreement."
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check
                            onChange={(e) =>
                                formControl.setValue(
                                    "confirmPaymentRequired",
                                    e.target.checked,
                                )
                            }
                            type="checkbox"
                            label="I understand that this agreement applies only to paid subscriptions and requires ongoing payment to remain in effect."
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </div>
        </MainLayout>
    );
};
