/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

export type AppState = {
    showConfetti: boolean;
    setShowConfetti: (value: boolean) => void;
    showSidebar: boolean;
    setShowSidebar: (value: boolean) => void;
};

const AppStateContext = React.createContext<AppState>({
    showConfetti: false,
    setShowConfetti: () => {},
    showSidebar: false,
    setShowSidebar: () => {},
});

export const useAppState = () => React.useContext(AppStateContext);

export const AppStateProvider: React.FunctionComponent = ({ children }) => {
    const [showConfetti, setShowConfetti] = React.useState<boolean>(false);
    const [showSidebar, setShowSidebar] = React.useState<boolean>(false);

    const values = React.useMemo(
        () => ({
            showConfetti,
            setShowConfetti,
            showSidebar,
            setShowSidebar,
        }),
        [showConfetti, showSidebar],
    );

    return (
        <AppStateContext.Provider value={values}>
            {children}
        </AppStateContext.Provider>
    );
};
