import React from "react";
import {
    FormLabel,
    FormSelect,
    FormSelectProps,
    InputGroup,
} from "react-bootstrap";

import { MetricChartBreakdownBy, MetricType } from "../../../generated";

interface MetricBreakdownbyInputProps {
    metricType: MetricType;
    formSelectProps: FormSelectProps;
}

const MetricBreakdownLabel: { [key in MetricChartBreakdownBy]: string } = {
    ClientName: "Browser",
    Country: "Country",
    City: "City",
    DeviceBrand: "Device Brand",
    DeviceModel: "Device Model",
    DeviceType: "Device Type",
    None: "None",
    OsName: "Operating System",
    Path: "Path",
    Referer: "Source",
    CssClass: "Class name",
    CssId: "ID",
    Value: "Value",
    CmsWebflowItemId: "CMS Items",
};

export const MetricBreakdownbyInput: React.FunctionComponent<MetricBreakdownbyInputProps> =
    (props) => {
        return (
            <>
                <FormLabel htmlFor="breakdownby" className="fw-bold mt-3">
                    Breakdown by
                </FormLabel>
                <InputGroup>
                    <FormSelect id="breakdownby" {...props.formSelectProps}>
                        {Object.entries(MetricChartBreakdownBy)
                            .sort(([, l1], [, l2]) => l1.localeCompare(l2))
                            .map(([value, label]) => (
                                <option key={value} value={value}>
                                    {MetricBreakdownLabel[label]}
                                </option>
                            ))}
                    </FormSelect>
                </InputGroup>
            </>
        );
    };
