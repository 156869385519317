import {
    faChevronCircleDown,
    faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Dashboard } from "../../generated";
import { NewDashboardButton } from "./NewDashboardButton";
import { SidebarSiteDashboardsLink } from "./SidebarSiteDashboardsLink";

interface SidebarDashboardsProps {
    siteId?: string | null;
    dashboards: Array<Pick<Dashboard, "id" | "name" | "organisationId">>;
    toggle: boolean;
    setToggle: (value: boolean) => void;
    setCurrentDashboard: (dashboardId: string) => void;
    setShowUpdate: (value: boolean) => void;
    setShowDelete: (value: boolean) => void;
}

export const SidebarSiteDashboards: React.FunctionComponent<SidebarDashboardsProps> =
    (props) => {
        return (
            <li className="nav-item py-1 w-100 mb-3">
                <button
                    className={`btn btn-toggle rounded ${
                        props.toggle ? "" : "collapsed"
                    } text-light w-100 shadow-none`}
                    data-bs-toggle="collapse"
                    data-bs-target="#dashboards-collapse"
                    aria-expanded={props.toggle}
                    onClick={() => props.setToggle(!props.toggle)}
                >
                    <div className="d-flex align-items-center justify-content-between ">
                        <div>
                            <FontAwesomeIcon
                                icon={
                                    props.toggle
                                        ? faChevronCircleDown
                                        : faChevronCircleRight
                                }
                            />
                            <span className="Sidebar_Link ms-3">
                                Dashboards
                            </span>
                        </div>
                        <NewDashboardButton siteId={props.siteId} />
                    </div>
                </button>
                <div
                    className={`collapse text-light ${
                        props.toggle ? "show" : ""
                    }`}
                    id="dashboards-collapse"
                >
                    <ul className="btn-toggle-nav list-unstyled fw-normal small pt-1 w-100 overflow-hidden">
                        {!props.dashboards?.length
                            ? null
                            : props.dashboards.map((dashboard, i) => (
                                  <SidebarSiteDashboardsLink
                                      key={i}
                                      currentDashboard={dashboard}
                                      setCurrentDashboard={
                                          props.setCurrentDashboard
                                      }
                                      setShowUpdate={props.setShowUpdate}
                                      setShowDelete={props.setShowDelete}
                                  />
                              ))}
                    </ul>
                </div>
            </li>
        );
    };
