import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";

import { routerPath } from "../../AppRouter";
import { config } from "../../config";
import { CopyToClipboardButton } from "../CopyToClipboardButton";

export interface IFrameSnippetProps {
    organisationId: string;
    siteId: string;
    dashboardId: string;
}

export const IFrameSnippet: React.FunctionComponent<IFrameSnippetProps> = (
    props,
) => {
    const SNIPPET = `<iframe width="100%" height="100%" style="border-style: none;" src="${
        config.app.baseUrl
    }${routerPath.embeddedMetrics(props)}"></iframe>`;

    return (
        <div className="mb-3">
            <code className="d-flex">
                <SyntaxHighlighter
                    language="javascript"
                    style={a11yDark}
                    customStyle={{ borderRadius: 0 }}
                    className="d-flex align-items-center mb-0 border-0 mt-0"
                >
                    {SNIPPET}
                </SyntaxHighlighter>
                <CopyToClipboardButton
                    text={SNIPPET}
                    className="d-flex"
                ></CopyToClipboardButton>
            </code>
        </div>
    );
};
