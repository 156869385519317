import classnames from "classnames";
import * as dateFns from "date-fns";
import * as dateFnsTz from "date-fns-tz";
import React, { useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import { MainLayout } from "../../Layout";
import { config } from "../../config";
import {
    NotificationsDocument,
    useNotificationReadMutation,
    useNotificationsQuery,
} from "../../generated";

export const NotificationsPage: React.FC = () => {
    const notificationsResults = useNotificationsQuery();
    const [notificationRead] = useNotificationReadMutation({});
    const [expandedMessage, setExpandedMessage] = useState<string | null>(null);

    return (
        <MainLayout
            loading={notificationsResults.loading}
            error={
                notificationsResults.error && {
                    text: notificationsResults.error.message,
                    from: "NotificationsPage",
                }
            }
        >
            <Container>
                <h3>Notifications</h3>
                {notificationsResults.data?.me?.notifications.length === 0 ? (
                    <div>Silence is golden...</div>
                ) : null}
                {notificationsResults.data?.me?.notifications.map(
                    (notification, index) => {
                        const createdAtZoned = dateFnsTz.toZonedTime(
                            notification.createdAt,
                            config.timezone,
                        );

                        const formattedDate = dateFns.format(
                            createdAtZoned,
                            "yyyy-MM-dd HH:mm",
                        );

                        const isExpanded =
                            expandedMessage === notification.subject;

                        return (
                            <React.Fragment key={notification.subject}>
                                <Row
                                    onClick={() => {
                                        if (!notification.readAt) {
                                            notificationRead({
                                                variables: {
                                                    subject:
                                                        notification.subject,
                                                },
                                                refetchQueries: [
                                                    NotificationsDocument,
                                                ],
                                            });
                                        }
                                        setExpandedMessage(
                                            notification.subject,
                                        );
                                    }}
                                    style={{ cursor: "pointer" }}
                                    className={classnames([
                                        "d-flex align-items-center pt-2 pb-2 border-bottom",
                                        "pointer", // Bootstrap class for cursor pointer
                                        "hover:bg-light", // Bootstrap class for hover effect
                                        {
                                            "fw-bold": !notification.readAt,
                                            "bg-light": index % 2 !== 0,
                                        },
                                    ])}
                                >
                                    <Col className="text-truncate" xs={6}>
                                        {notification.subject}
                                    </Col>
                                    <Col xs={2}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={(props) => (
                                                <Tooltip {...props}>
                                                    {formattedDate}
                                                </Tooltip>
                                            )}
                                        >
                                            <div>
                                                {dateFns.formatDistance(
                                                    new Date(createdAtZoned),
                                                    new Date(),
                                                )}
                                            </div>
                                        </OverlayTrigger>
                                    </Col>
                                    {isExpanded && (
                                        <Container
                                            className="pt-2"
                                            dangerouslySetInnerHTML={{
                                                __html: notification.body,
                                            }}
                                        />
                                    )}
                                </Row>
                            </React.Fragment>
                        );
                    },
                )}
            </Container>
        </MainLayout>
    );
};
