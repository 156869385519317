import { sumBy } from "lodash";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";

import { routerPath } from "../../AppRouter";
import {
    usePlanExceededQuery,
    useSiteByIdQuery,
    useStripeCustomerPortalLinkQuery,
} from "../../generated";

interface PlanExceededProps {
    organisationId: string;
    siteId: string;
}

const BreakdownDetails: React.FC<{ siteId: string; viewCount: number }> = (
    props,
) => {
    const siteByIdResponse = useSiteByIdQuery({
        variables: { id: props.siteId },
    });
    if (siteByIdResponse.loading) {
        return null;
    }
    return (
        <div>
            <b>{siteByIdResponse.data?.siteById.name}:</b>{" "}
            {props.viewCount.toLocaleString()} views.
        </div>
    );
};

export const PlanExceeded: React.FC<PlanExceededProps> = (props) => {
    const history = useHistory();
    const planExceededResponse = usePlanExceededQuery({
        variables: { organisationId: props.organisationId },
    });
    const stripeCustomerPortalLink = useStripeCustomerPortalLinkQuery();

    return (
        <Modal
            centered
            show
            onHide={() =>
                history.push(
                    routerPath.siteSettings({
                        organisationId: props.organisationId,
                        siteId: props.siteId,
                    }),
                )
            }
        >
            <Modal.Header closeButton>
                <Modal.Title>Free Plan Exceeded</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    Well done! You had{" "}
                    <b>
                        {sumBy(
                            planExceededResponse.data?.planExceeded
                                .viewsBySiteId,
                            "viewCount",
                        ).toLocaleString()}
                    </b>{" "}
                    views last month. However, your Nocodelytics account has
                    exceeded its quota.
                </div>
                <p>
                    Without an upgrade,{" "}
                    <b>
                        Nocodelytics will be disabled for your site and{" "}
                        <u>historical data will be deleted</u>
                    </b>{" "}
                    in the next <b>30 days</b>.
                </p>
                <p>
                    If you need more time to explore, you use code <b>TRY3</b>{" "}
                    to get 3 months of the business plan ($49/month){" "}
                    <b>for free</b>.
                </p>
                {(planExceededResponse.data?.planExceeded?.viewsBySiteId || [])
                    .length > 1 ? (
                    <div>
                        <div>The view count is across all your sites.</div>
                        <div> Here is the breakdown:</div>
                        {planExceededResponse.data?.planExceeded.viewsBySiteId.map(
                            ({ siteId, viewCount }) => (
                                <BreakdownDetails
                                    key={siteId}
                                    siteId={siteId}
                                    viewCount={viewCount}
                                />
                            ),
                        )}
                    </div>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    href={
                        stripeCustomerPortalLink.data?.stripeCustomerPortalLink
                    }
                    style={{
                        position: "relative",
                        overflow: "hidden",
                        animation: "fireworks 3s infinite",
                    }}
                >
                    Upgrade Now
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
