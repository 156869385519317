import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCopyToClipboard } from "react-use";

interface HoverTextProps {
    text: string;
    hoverOnly?: boolean;
}

export const HoverText: React.FunctionComponent<HoverTextProps> = (props) => {
    const { text, hoverOnly } = props;
    const [state, copyToClipboard] = useCopyToClipboard();

    return (
        <OverlayTrigger
            key={text}
            placement="top"
            overlay={(props) => (
                <Tooltip {...props}>
                    {" "}
                    <>
                        {text}
                        {hoverOnly ? null : (
                            <>
                                <br />
                                {state.value ? "Copied!" : "Click to copy"}
                            </>
                        )}
                    </>
                </Tooltip>
            )}
        >
            <span
                style={{ cursor: "pointer" }}
                onClick={() => copyToClipboard(text)}
                onKeyDown={() => copyToClipboard(text)}
                role="button"
                tabIndex={0}
            >
                {props.children ? props.children : text}
            </span>
        </OverlayTrigger>
    );
};
