import React from "react";
import { Redirect, useParams } from "react-router-dom";

import { routerPath, RouterURLParams } from "../../AppRouter";
import { ErrorText, Loading } from "../../components";
import { useSiteByIdQuery } from "../../generated";

export const SiteRedirect: React.FunctionComponent = () => {
    const { siteId } = useParams<RouterURLParams["siteRedirect"]>();
    const siteByIdQueryResults = useSiteByIdQuery({
        variables: { id: siteId },
    });
    if (siteByIdQueryResults.loading) {
        return <Loading makeItFun />;
    }
    if (!siteByIdQueryResults.data?.siteById) {
        return <ErrorText from="SiteRedirect" error="Site not found" />;
    }
    return (
        <Redirect
            to={
                routerPath.siteDetails({
                    organisationId:
                        siteByIdQueryResults.data.siteById.organisationId,
                    siteId,
                }) + window.location.search
            }
        />
    );
};
