import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { Badge, Button, Col, Container, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { routerPath } from "../../AppRouter";
import { useAppState } from "../../context";
import { FeaturesQuery, User, useNotificationsQuery } from "../../generated";
import { Loading } from "../Loading";
import { AccountDropdown } from "./AccountDropdown";

export interface NavbarProperties {
    user?: Pick<User, "firstname" | "lastname" | "email"> | null;
    organisationId?: string | null;
    loading?: boolean;
    features?: FeaturesQuery["features"];
    onLogout: () => void;
    onBillingClick: () => void;
    triggerUpgradeModal?: () => void;
    isSubscriptionValid?: boolean;
}

export const Navbar: React.FunctionComponent<NavbarProperties> = (props) => {
    const { showSidebar, setShowSidebar } = useAppState();
    const notificationsResults = useNotificationsQuery();
    const notificationsCount =
        notificationsResults.data?.me?.notifications.filter((n) => !n.readAt)
            .length || 0;
    return (
        <Nav className="navbar navbar-expand bg-white text-dark border-bottom border-1 w-100 fixed-top">
            <Container fluid>
                {props.loading ? <Loading /> : null}
                <div className="d-flex align-items-center justify-content-center">
                    <Link to="/sites">
                        <img
                            src="/assets/nocodelytics-logo.jpg"
                            style={{ width: 38, height: 38, marginRight: 12 }}
                            alt="Nocodelytics logo"
                        />
                    </Link>
                    <p className="h4 mb-0">Nocodelytics</p>
                </div>
                <div className="d-flex align-items-center justify-content-center d-md-none">
                    <button
                        className="d-block d-md-none bg-white border-0"
                        type="button"
                        onClick={() => setShowSidebar(!showSidebar)}
                    >
                        {showSidebar ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-x"
                                viewBox="0 0 16 16"
                            >
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-list"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                                />
                            </svg>
                        )}
                    </button>
                </div>
                <Row>
                    <Col>
                        {notificationsResults.loading ? (
                            <Loading />
                        ) : (
                            <Button
                                href={routerPath.notifications()}
                                className="d-flex h-100 align-items-center"
                                variant={
                                    notificationsCount
                                        ? "primary"
                                        : "primary-ouline"
                                }
                            >
                                <FontAwesomeIcon
                                    className={classNames({
                                        "me-3": notificationsCount,
                                    })}
                                    icon={faBell}
                                />
                                {notificationsCount > 0 ? (
                                    <Badge bg="danger">
                                        {notificationsCount}
                                    </Badge>
                                ) : null}
                            </Button>
                        )}
                    </Col>
                    <Col>
                        <AccountDropdown {...props} />
                    </Col>
                </Row>
            </Container>
        </Nav>
    );
};
