import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { MaintenanceMessage } from "../components";
import { useMaintenanceQuery } from "../generated";
import { Routes } from "./Routes";

export const AppRouter: React.FunctionComponent = () => {
    const maintenanceQuery = useMaintenanceQuery({ pollInterval: 60_000 });

    if (maintenanceQuery.data?.maintenance) {
        return <MaintenanceMessage />;
    }
    return (
        <Router>
            <Routes />
        </Router>
    );
};
