import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { config } from "../../config";
import { useCurrentUserLazyQuery } from "../../generated";

export const SignUpPage: React.FunctionComponent = () => {
    const [currentUserLazyQuery, currentUserQueryResults] =
        useCurrentUserLazyQuery();
    const history = useHistory();
    useEffect(() => {
        document.title = `${config.app.name} – Sign up`;
        currentUserLazyQuery();
        if (currentUserQueryResults.data?.me) {
            history.push("/");
        }
    }, [currentUserLazyQuery, currentUserQueryResults.data?.me, history]);
    return (
        <div>
            <h1>Sign up for Nocodelytics</h1>
            <a
                href={`${config.api.url}/auth/webflow`}
                type="button"
                className="btn btn-light"
            >
                <img
                    alt="webflow icon"
                    className="mr-2"
                    height="28"
                    src="/assets/webflow-icon.svg"
                />
                Sign up with Webflow
            </a>
        </div>
    );
};
