import { GridLayoutData } from "./GridLayout";

interface AddItemToGridLayoutParams {
    layout?: GridLayoutData[];
    metricId: string;
}

export const addItemToGridLayout = (
    params: AddItemToGridLayoutParams,
): GridLayoutData[] => {
    const layout = params.layout || [];
    const defaultW = 1;
    if (layout.length === 0) {
        return [
            {
                id: params.metricId,
                h: 1,
                w: defaultW,
                x: 0,
                y: 0,
            },
        ];
    }
    const lastRowY = [...layout].sort((a, b) => b.y - a.y)[0].y;
    const lastRowItems = layout.filter(({ y }) => y === lastRowY);
    const [lastItem] = [...lastRowItems].sort((a, b) => b.x - a.x);
    if (lastItem.x + lastItem.w + defaultW <= 3) {
        // same line
        return [
            ...layout,
            {
                id: params.metricId,
                h: 1,
                w: defaultW,
                x: lastItem.x + lastItem.w,
                y: lastRowY,
            },
        ];
    }
    // new line
    return [
        ...layout,
        {
            id: params.metricId,
            h: 1,
            w: defaultW,
            x: 0,
            y: lastRowY + 1,
        },
    ];
};
