import {
    faEllipsisV,
    faPlug,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { throttle } from "lodash";
import React, { useEffect, useState } from "react";
import {
    Col,
    Dropdown,
    Form,
    InputGroup,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import { RouterURLParams } from "../../AppRouter";
import { MainLayout } from "../../Layout";
import { config } from "../../config";
import { SitesListContainer } from "../../containers";
import { useAppState } from "../../context";
import {
    SiteByIdDocument,
    SitesDocument,
    useDeleteDisabledSitesMutation,
} from "../../generated/graphql";

export const SitesPage: React.FunctionComponent = () => {
    useEffect(() => {
        document.title = `${config.app.name} – Your Sites`;
    }, []);
    const { organisationId } = useParams<RouterURLParams["sitesList"]>();

    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const { register, watch } = useForm<{ searchSiteNameValue: string }>();
    const searchSiteNameValue = watch("searchSiteNameValue");
    const { setShowConfetti } = useAppState();
    const [deleteDisabledSites, deleteDisabledSitesResults] =
        useDeleteDisabledSitesMutation({
            variables: { organisationId },
            refetchQueries: [SiteByIdDocument, SitesDocument],
        });

    useEffect(() => {
        if (searchSiteNameValue === "tada") {
            setShowConfetti(true);
        } else {
            setShowConfetti(false);
        }
    }, [searchSiteNameValue, setShowConfetti]);

    const loading = deleteDisabledSitesResults.loading;
    const error = deleteDisabledSitesResults.error;

    const searchInput = (
        <InputGroup>
            <InputGroup.Text className="bg-primary text-white">
                <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
                id="filter-sites-input"
                {...register("searchSiteNameValue")}
                onChange={throttle((event) => {
                    register("searchSiteNameValue").onChange(event);
                }, 500)}
                type="text"
                placeholder="Search sites"
            />
        </InputGroup>
    );
    return (
        <MainLayout
            title="Sites"
            loading={loading}
            error={
                error && {
                    text: JSON.stringify(error),
                    from: "SitesPage",
                }
            }
            showUpgradeModal={showUpgradeModal}
            triggerUpgradeModal={() => setShowUpgradeModal(true)}
            onUpgradeModalClose={() => setShowUpgradeModal(false)}
            rightElement={
                <Row>
                    <Col>{searchInput}</Col>
                    <Col xs="auto">
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary">
                                <FontAwesomeIcon
                                    className="me-2"
                                    icon={faEllipsisV}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={(props) => (
                                        <Tooltip {...props}>
                                            Missing a site? Reconnect your
                                            webflow account to add more.
                                        </Tooltip>
                                    )}
                                >
                                    <Dropdown.Item
                                        href={`${config.api.url}/auth/webflow`}
                                        variant="outline-primary"
                                    >
                                        <FontAwesomeIcon
                                            className="me-2"
                                            icon={faPlug}
                                        />
                                        Reconnect Webflow Account
                                    </Dropdown.Item>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={(props) => (
                                        <Tooltip {...props}>
                                            Keep only the sites you enabled. All
                                            other sites will be removed.
                                        </Tooltip>
                                    )}
                                >
                                    <Dropdown.Item
                                        className="text-danger"
                                        href={`${config.api.url}/auth/webflow`}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            if (
                                                window.confirm(
                                                    "You will only have 'tracking' sites left. All other sites will be deleted. Confirm?",
                                                )
                                            ) {
                                                deleteDisabledSites();
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            className="me-2"
                                            icon={faTrash}
                                        />
                                        Delete incomplete sites
                                    </Dropdown.Item>
                                </OverlayTrigger>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            }
        >
            <SitesListContainer
                onPaidFeatureClick={() => setShowUpgradeModal(true)}
                organisationId={organisationId}
                filters={{ name: searchSiteNameValue }}
            />
            <p className="my-4">
                Need to change which sites Nocodelytics has access to?{" "}
                <a href={`${config.api.url}/auth/webflow`}>
                    Manage your sites in Webflow
                </a>
            </p>
            <p>
                If you <b>can&lsquo;t find a site</b>, someone else might have
                added it to their account already. You need an invite to their
                organization to access it. The owner of the site need to contact
                us and we will add you to their organization for free.
            </p>
            <p>
                Sites still missing?{" "}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={config.app.supportUrl}
                >
                    Chat with us
                </a>{" "}
                and we will fix it in no time.
            </p>
        </MainLayout>
    );
};
