import {
    faCreditCard,
    faSignOutAlt,
    faMoneyBill,
    faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import { NavbarProperties } from "./Navbar";

const NameCircle = styled.div<{ large?: boolean }>`
    width: ${({ large }) => (large ? "35px" : "25px")};
    height: ${({ large }) => (large ? "35px" : "25px")};
    margin-left: -3px;
    background-color: #0d6efd;
    font-weight: 700;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
`;

export const AccountDropdown: React.FunctionComponent<NavbarProperties> = (
    props,
) =>
    props.user ? (
        <div className="dropdown">
            <button
                className="btn bg-light dropdown-toggle text-dark d-flex align-items-center"
                type="button"
                aria-label="dropdown-account-menu"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <NameCircle>{props.user?.firstname?.charAt(0)}</NameCircle>{" "}
            </button>
            <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdown-account-menu"
            >
                <li className="dropdown-item fw-bold disabled text-secondary d-flex">
                    <NameCircle large className="me-2 my-auto h3">
                        {props.user?.firstname?.charAt(0)}
                    </NameCircle>{" "}
                    <div className="d-flex flex-column">
                        <span>
                            {props.user?.firstname} {props.user?.lastname}
                        </span>
                        <span className="fw-normal">{props.user?.email}</span>
                    </div>
                </li>
                <li>
                    <hr className="dropdown-divider mx-3 text-light" />
                </li>
                <li>
                    <button className="dropdown-item disabled">
                        <FontAwesomeIcon className="me-3" icon={faUserPlus} />
                        Invite users (soon)
                    </button>
                </li>

                <li>
                    <button
                        onClick={() => props.onBillingClick()}
                        className="dropdown-item text-primary fw-bold"
                    >
                        <FontAwesomeIcon
                            icon={faCreditCard}
                            style={{ marginRight: 18 }}
                        />
                        {props.isSubscriptionValid === false
                            ? "Upgrade"
                            : "Billing"}
                    </button>
                </li>
                <li>
                    <button className="dropdown-item">
                        <a
                            href="https://affiliates.reflio.com/invite/nocodelytics"
                            target="_blank"
                            rel="noreferrer"
                            className="text-decoration-none fw-bold"
                        >
                            <FontAwesomeIcon
                                className="me-3"
                                icon={faMoneyBill}
                            />
                            Become an affiliate
                        </a>
                    </button>
                </li>
                <li>
                    <hr className="dropdown-divider mx-3 text-light" />
                </li>
                <li>
                    <button
                        onClick={() => props.onLogout()}
                        className="dropdown-item"
                    >
                        <FontAwesomeIcon
                            className="text-muted"
                            icon={faSignOutAlt}
                            style={{ marginRight: 20 }}
                        />
                        Sign out
                    </button>
                </li>
            </ul>
        </div>
    ) : null;
