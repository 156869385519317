import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

import {
    Dashboard,
    Metric,
    MetricChartBreakdownBy,
    MetricType,
    Site,
} from "../../generated/graphql";
import { Maybe } from "../../helpers";
import { Checkbox } from "../Checkbox";
import { Loading } from "../Loading";
import {
    CustomFieldSelectorInput,
    MetricBreakdownbyInput,
    MetricCollectionInput,
    MetricCssSelectorInput,
    MetricCssSelectorInputProps,
    MetricNameInput,
    MetricPathInput,
    MetricTypeInput,
} from "./Inputs";
import { MetricDescription } from "./MetricDescription";

export interface MetricFormData
    extends Pick<
        Metric,
        | "name"
        | "metricType"
        | "cssSelector"
        | "path"
        | "webflowCollectionId"
        | "breakdownBy"
        | "uniqueVisitor"
    > {}

export interface DashboardFormData
    extends Pick<Dashboard, "name" | "isPublic"> {}

export interface MetricEditorProps {
    defaultValues?: Maybe<Partial<MetricFormData>>;
    site: Pick<Site, "id" | "name">;
    siteHtmlElements?: MetricCssSelectorInputProps["siteHtmlElements"];
    loading?: boolean;
    formControl: UseFormReturn<MetricFormData>;
    collections: any;
}

export const MetricEditor: React.FunctionComponent<MetricEditorProps> = (
    props,
) => {
    const { register, watch, reset, getValues, setValue } = props.formControl;
    const metricType = watch("metricType");
    const cssSelector = watch("cssSelector");
    const webflowCollectionId = watch("webflowCollectionId");
    const uniqueVisitor = watch("uniqueVisitor");
    const formControls = {
        metricType: register("metricType", {
            required: true,
        }),
        webflowCollectionId: register("webflowCollectionId"),
        uniqueVisitor: register("uniqueVisitor"),
    };
    useEffect(() => {
        if (metricType === MetricType.Keyboard) {
            setValue("breakdownBy", MetricChartBreakdownBy.Value);
        } else if (metricType === MetricType.Cms) {
            setValue("breakdownBy", MetricChartBreakdownBy.CmsWebflowItemId);
        }
    }, [metricType, setValue]);
    if (props.loading) {
        return <Loading />;
    }
    return (
        <div>
            <MetricNameInput
                formControl={register("name", {
                    required: true,
                })}
            />
            <MetricTypeInput
                formSelectProps={{
                    ...formControls.metricType,
                    onChange: (event) => {
                        reset({
                            name: getValues("name"),
                            metricType: event.currentTarget.value as MetricType,
                            breakdownBy: MetricChartBreakdownBy.None,
                            cssSelector: null,
                            path: null,
                            webflowCollectionId: null,
                        });
                        formControls.metricType.onChange(event);
                    },
                }}
            />
            <MetricDescription metricType={metricType} />
            <MetricCssSelectorInput
                metricType={metricType}
                formControl={register("cssSelector")}
                value={cssSelector || ""}
                setValue={(value) => setValue("cssSelector", value)}
                site={props.site || { name: "" }}
                siteHtmlElements={props.siteHtmlElements || []}
            />
            <MetricPathInput
                metricType={metricType}
                formControl={register("path")}
            />
            <MetricCollectionInput
                metricType={metricType}
                siteId={props.site.id}
                formControl={{
                    webflowCollectionId: {
                        ...formControls.webflowCollectionId,
                        onChange: (event) => {
                            if (!getValues("cssSelector")) {
                                setValue(
                                    "cssSelector",
                                    `nocodelytics-${(Math.random() + 1)
                                        .toString(36)
                                        .slice(7)}`,
                                );
                            }
                            formControls.webflowCollectionId.onChange(event);
                        },
                    },
                    cssSelector: register("cssSelector"),
                }}
                error={
                    webflowCollectionId &&
                    metricType === MetricType.Cms &&
                    !cssSelector?.startsWith("nocodelytics-")
                        ? `CSS Class must start with 'nocodelytics-'`
                        : undefined
                }
            />
            {webflowCollectionId && (
                <CustomFieldSelectorInput
                    metricType={metricType}
                    siteId={props.site.id}
                    collectionId={webflowCollectionId}
                    formSelectProps={register("cssSelector")}
                />
            )}
            <MetricBreakdownbyInput
                metricType={metricType}
                formSelectProps={register("breakdownBy")}
            />
            {metricType !== MetricType.Visitors ? (
                <div className="mt-3">
                    <Checkbox
                        id="uniqueVisitor"
                        value={Boolean(uniqueVisitor)}
                        onChange={(event) => {
                            setValue("uniqueVisitor", event.target.checked);
                        }}
                    >
                        Unique visitor
                    </Checkbox>
                </div>
            ) : null}
        </div>
    );
};
