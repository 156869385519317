interface Config {
    env: "development" | "stage" | "test" | "production";
    app: {
        name: string;
        baseUrl: string;
        supportUrl: string;
    };
    api: {
        url: string;
    };
    email: {
        supportEmail: string;
    };
    webflow: {
        designerExtensionUrl: string;
    };
    timezone: string;
}

export const config: Config = {
    env: ((): Config["env"] => {
        if (document.location.host === "staging.nocodelytics.com") {
            return "stage";
        }
        if (document.location.host === "app.nocodelytics.com") {
            return "production";
        }
        if (process.env.CI) {
            return "test";
        }
        return "development";
    })(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    app: {
        name: "Nocodelytics",
        baseUrl: document.location.origin,
        supportUrl:
            "https://go.crisp.chat/chat/embed/?website_id=0c24de91-9f2f-4378-815a-8c4da4a415bd",
    },
    api: {
        url: process.env.VITE_API_URL || `${document.location.origin}/api`,
    },
    email: {
        supportEmail: "florian@nocodelytics.com",
    },
    webflow: {
        designerExtensionUrl:
            process.env.VITE_WEBFLOW_DESIGNER_EXTENSION_URL ||
            "https://649b2c97ec7281257582cb02.webflow-ext.com/64e0b49bd78bce8b90f8eea6",
    },
};

export const localStorageKeys = {
    token: `${config.app.name.toLowerCase()}:token`,
    selectedOrganisationId: "nocodelytics:selectedOrganisationId",
};
