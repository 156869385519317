import * as queryString from "query-string";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router";

import { routerPath } from "../../AppRouter";
import { ErrorText, Loading } from "../../components";
import { config, localStorageKeys } from "../../config";
import { useSignInMutation } from "../../generated";

export const SignInCallbackPage: React.FunctionComponent = () => {
    const location = useLocation();
    const { token } = queryString.parse(location.search);

    const [signInMutation, { called, error }] = useSignInMutation({
        onCompleted: async (data) => {
            localStorage.setItem(localStorageKeys.token, data.signIn);
            window.location.replace(routerPath.sites);
        },
    });

    useEffect(() => {
        document.title = `${config.app.name} – Sign In`;
        !called &&
            typeof token === "string" &&
            signInMutation({ variables: { token } });
    }, [called, signInMutation, token]);

    if (error) {
        return <ErrorText error={error} from={"SignInCallbackPage"} />;
    }

    if (typeof token !== "string") {
        return (
            <div>
                <div>No token found in the URL</div>
                <div>
                    <code>location: {JSON.stringify(location, null, 2)}</code>
                </div>
                <div>
                    <code>
                        parsed:{" "}
                        {JSON.stringify(
                            queryString.parse(location.search),
                            null,
                            2,
                        )}
                    </code>
                </div>
            </div>
        );
    }
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <Row>
                <Col className="mb-4">
                    <Loading />
                </Col>
                <Col xs={12}>
                    <h3 className="text-center">Signing in...</h3>
                </Col>
            </Row>
            {error ? <ErrorText error={error} from="SignIn" /> : null}
        </div>
    );
};
