import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";

import { useFeatures } from "../../hooks";

export interface FilterData {
    isMember?: boolean;
}

export interface FiltersProps {
    filters: FilterData;
    onChange: (filters: FilterData) => void;
}

export const Filters: React.FunctionComponent<FiltersProps> = (props) => {
    const isMemberFeatureEnabled = useFeatures("memberFilter");
    if (!isMemberFeatureEnabled) {
        return null;
    }

    const activeStyle = "bg-primary rounded bg-opacity-25 fw-bold";

    return (
        <ButtonGroup
            className="bg-white h-100 border"
            role="group"
            aria-label="Choose the date range"
        >
            <Dropdown className="btn-group" role="group">
                <Dropdown.Toggle
                    variant="outline-dark"
                    className="border border-light"
                >
                    <FontAwesomeIcon icon={faUser} className="me-3" />
                    {props.filters.isMember === undefined ? "All users" : null}
                    {props.filters.isMember === true ? "Members only" : null}
                    {props.filters.isMember === false
                        ? "Non members only"
                        : null}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 w-100">
                    <Dropdown.Item
                        className={`Dropdown_Option text-dark px-2 rounded ${
                            props.filters.isMember === undefined
                                ? activeStyle
                                : ""
                        }`}
                        onClick={() => {
                            props.onChange({
                                isMember: undefined,
                            });
                        }}
                    >
                        All users
                    </Dropdown.Item>
                    <Dropdown.Item
                        className={`Dropdown_Option text-dark px-2 rounded ${
                            props.filters.isMember ? activeStyle : ""
                        }`}
                        onClick={() => {
                            props.onChange({
                                isMember: true,
                            });
                        }}
                    >
                        Members only
                    </Dropdown.Item>
                    <Dropdown.Item
                        className={`Dropdown_Option text-dark px-2 rounded ${
                            props.filters.isMember === false ? activeStyle : ""
                        }`}
                        onClick={() => {
                            props.onChange({
                                isMember: false,
                            });
                        }}
                    >
                        Non members only
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ButtonGroup>
    );
};
