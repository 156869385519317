import { faStream } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import { routerPath } from "../../AppRouter";

interface SidebarSiteVisitorsProps {
    siteId?: string | null;
    organisationId?: string | null;
}

export const SidebarSiteLiveFeed: React.FunctionComponent<SidebarSiteVisitorsProps> =
    (props) => {
        const location = useLocation();
        const isCurrentPath = location.pathname.includes("/live");

        return (
            <li
                className={`nav-item py-1 w-100 opacity-50 ${
                    isCurrentPath ? "bg-primary bg-opacity-50 rounded" : ""
                }`}
            >
                <Link
                    to={routerPath.liveFeed({
                        organisationId: props.organisationId || "",
                        siteId: props.siteId || "",
                    })}
                    className="nav-link text-white disabled"
                >
                    <FontAwesomeIcon icon={faStream} />
                    <span className="Sidebar_Link ms-3 fw-bold">
                        Live Feed (soon)
                    </span>
                </Link>
            </li>
        );
    };
