import chunk from "lodash.chunk";
import flatten from "lodash.flatten";
import React from "react";
import { Layouts, Responsive, WidthProvider } from "react-grid-layout";

import { Metric } from "../../../generated/graphql";

interface MetricsProps {
    metrics: Metric[];
}

const ResponsiveGridLayout = WidthProvider(Responsive);

export const buildLayouts = (metrics: Metric[]): Layouts => ({
    lg: flatten(
        chunk(metrics, 3).map((chunks, y) =>
            chunks.map(({ id }, x) => ({
                i: id,
                x: x * 4,
                y,
                w: 4,
                h: 1,
            })),
        ),
    ),
    sm: metrics.map(({ id }, y) => ({
        i: id,
        x: 0,
        y,
        w: 12,
        h: 2,
        isDraggable: false,
        isResizable: false,
    })),
});

export const MetricsList: React.FunctionComponent<MetricsProps> = (props) => {
    return (
        <div>
            <ResponsiveGridLayout
                className="layout"
                measureBeforeMount
                layouts={buildLayouts(props.metrics)}
                breakpoints={{ sm: 500, lg: 930 }}
                rowHeight={284}
                margin={[20, 20]}
                isResizable={true}
                isDraggable={true}
                autoSize
            >
                {props.metrics.map((metric) => (
                    <div key={metric.id}>
                        <h3 className="text-truncate fs-5">{metric.name}</h3>
                    </div>
                ))}
            </ResponsiveGridLayout>
        </div>
    );
};
