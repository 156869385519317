import confetti from "canvas-confetti";
import React, { useEffect, useRef } from "react";

export const Confetti = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (canvasRef.current) {
            confetti.create(canvasRef.current)({
                particleCount: 100,
                spread: 70,
            });
        }
    });

    return (
        <div
            className="position-absolute d-flex align-items-center justify-content-center w-100 h-100"
            style={{ pointerEvents: "none" }}
        >
            <canvas width={1000} height={1000} ref={canvasRef} />
        </div>
    );
};
