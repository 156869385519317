import {
    faDatabase,
    faEye,
    faKeyboard,
    faListUl,
    faMousePointer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import { RouterURLParams } from "../../../AppRouter";
import { MainLayout } from "../../../Layout";
import {
    ChartDataV2QueryVariables,
    EventType,
    useDashboardByIdQuery,
    useSiteByIdLazyQuery,
} from "../../../generated";

export const NewMetricV2Page: React.FC = () => {
    const { dashboardId } = useParams<RouterURLParams["newMetric"]>();
    const [siteByIdQuery, siteByIdQueryResults] = useSiteByIdLazyQuery();
    const dashboardByIdQueryResults = useDashboardByIdQuery({
        variables: { dashboardId },
        onCompleted: (data) => {
            siteByIdQuery({ variables: { id: data.dashboard.siteId } });
        },
    });
    const data = {
        ...dashboardByIdQueryResults.data,
        ...siteByIdQueryResults.data,
    };
    const formControl = useForm<ChartDataV2QueryVariables["input"]>({
        defaultValues: {
            eventType: "view",
        },
    });
    const eventType = formControl.watch("eventType");
    const dropdownValues = [
        {
            text: "Views",
            value: EventType.View,
            icon: faEye,
        },
        {
            text: "Clicks",
            value: EventType.Click,
            icon: faMousePointer,
        },
        {
            text: "CMS",
            value: EventType.Cms,
            icon: faDatabase,
        },
        {
            text: "Input",
            value: EventType.Keyboard,
            icon: faKeyboard,
        },
        {
            text: "Form",
            value: EventType.Form,
            icon: faListUl,
        },
    ];
    return (
        <MainLayout
            siteId={data.dashboard?.siteId}
            title={`Add new metric to ${data.siteById?.name || "..."}`}
        >
            <Row>
                <Col>
                    <Dropdown className="w-100">
                        <Dropdown.Toggle
                            className="w-100 text-start border-1"
                            variant="light"
                            id="dropdown-basic"
                        >
                            {/* <FontAwesomeIcon
                                className="me-3"
                                icon={
                                    dropdownValues.find(
                                        (d) => d.value === eventType,
                                    )?.icon
                                }
                            /> */}

                            {
                                dropdownValues.find(
                                    (d) => d.value === eventType,
                                )?.text
                            }
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100">
                            {dropdownValues.map((item) => (
                                <Dropdown.Item
                                    key={item.value}
                                    onClick={() =>
                                        formControl.setValue(
                                            "eventType",
                                            item.value,
                                        )
                                    }
                                >
                                    <FontAwesomeIcon
                                        className="me-3"
                                        icon={item.icon}
                                    />
                                    {item.text}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col></Col>
            </Row>
        </MainLayout>
    );
};
