import React from "react";
import { Card } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import styled from "styled-components";

import { CopyToClipboardButton } from "../CopyToClipboardButton";

const SnippetWrapper = styled.div`
    margin-bottom: 15px;
`;

interface SnippetProps {
    code: string;
}

export const CodeCard = styled(Card)`
    border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
    code {
        color: #d3d3d3;
    }
`;

export const Snippet: React.FunctionComponent<SnippetProps> = (props) => {
    return (
        <SnippetWrapper>
            <code className="d-flex">
                <SyntaxHighlighter
                    language="javascript"
                    style={a11yDark}
                    customStyle={{ borderRadius: 0 }}
                    className="d-flex align-items-center mb-0 border-0 mt-0"
                >
                    {props.code}
                </SyntaxHighlighter>
                <CopyToClipboardButton
                    text={props.code}
                    className="d-flex"
                    variant="outline-primary"
                ></CopyToClipboardButton>
            </code>
        </SnippetWrapper>
    );
};
