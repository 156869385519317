import {
    faCalendar,
    faEllipsisV,
    faPlus,
    faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Image, Placeholder, Row } from "react-bootstrap";

import metricLoading from "../../assets/images/visual-data-loading.svg";

const MetricLoadingCard: React.FC = () => (
    <Card
        className="MetricLoadingCard shadow-sm mb-4"
        style={{ border: 0, borderRadius: 10, height: 284 }}
    >
        <Placeholder as={Card.Title} animation="glow">
            <Card.Body>
                <Row>
                    <Col>
                        <Placeholder xs={7} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Placeholder xs={4} />
                    </Col>
                </Row>
                <Image
                    className="img-fluid opacity-25 w-100"
                    style={{ height: 200 }}
                    src={metricLoading}
                />
            </Card.Body>
        </Placeholder>
    </Card>
);

interface MetricsListLoadingProps {}

export const MetricsListLoading: React.FunctionComponent<MetricsListLoadingProps> =
    () => {
        return (
            <>
                <Row className="ActionsBar justify-content-md-center mx-2 mb-4">
                    <Col>
                        <Placeholder.Button
                            aria-hidden="true"
                            variant="outline-secondary"
                            className="bg-white d-flex justify-content-center align-items-center border"
                        >
                            <FontAwesomeIcon
                                icon={faCalendar}
                                className="me-2"
                            />
                            Last 30 days
                        </Placeholder.Button>
                    </Col>
                    <Col md="auto">
                        <Placeholder.Button
                            aria-hidden="true"
                            className="btn fw-bold bg-transparent"
                            variant="border-0 text-primary"
                            type="button"
                            style={{ transform: "rotate(90deg)" }}
                        >
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </Placeholder.Button>
                    </Col>
                    <Col md="auto">
                        <Placeholder.Button
                            xs={4}
                            aria-hidden="true"
                            className="bg-white text-primary w-100 border-0 fw-bold"
                        >
                            <FontAwesomeIcon icon={faShare} className="me-2" />
                            Share
                        </Placeholder.Button>
                    </Col>
                    <Col md="auto">
                        <Placeholder.Button
                            xs={4}
                            aria-hidden="true"
                            className="w-100 fw-bold"
                        >
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            New Metric
                        </Placeholder.Button>
                    </Col>
                </Row>
                <Row className="mx-2">
                    {[0, 1, 2, 3, 4].map((each) => (
                        <Col xs={12} md={6} xl={4} key={each}>
                            <MetricLoadingCard />
                        </Col>
                    ))}
                </Row>
            </>
        );
    };
