import React from "react";
import { Badge } from "react-bootstrap";

import { MetricType } from "../../generated/graphql";

const metricDescriptions: {
    [key in MetricType]: string;
} = {
    [MetricType.Keyboard]: `See what people are searching for (works with Jetboost).`,
    [MetricType.Click]: `Easily track clicks on buttons and links.`,
    [MetricType.View]: `Page views shows how many (total) visits you had to your site.`,
    [MetricType.Visitors]: `Visitors shows how many (total) visitors you had to your site.`,
    [MetricType.Cms]: `Clicks on collection items. Hint: Breakdown by CMS Items to see which are most popular.`,
    [MetricType.Form]: `Shows the number of form views, interactions and submissions.`,
    [MetricType.TopCountries]: "The countries your visitors are from.",
    [MetricType.TopReferers]: "The websites your visitor are coming from.",
    [MetricType.TopPages]: "The most popular pages on your site.",
    [MetricType.CustomField]: "Display the data from an item value.",
};

interface MetricDescriptionProps {
    metricType: MetricType;
}

export const MetricDescription: React.FunctionComponent<MetricDescriptionProps> =
    (props) => {
        if (!props.metricType) {
            return null;
        }
        return (
            <div className="d-flex align-items-baseline mt-3 mb-3">
                <Badge pill bg="secondary" className="me-2">
                    i
                </Badge>{" "}
                <p className="text-muted mb-0">
                    {metricDescriptions[props.metricType]}
                </p>
            </div>
        );
    };
