import {
    faCommentAlt,
    faExternalLinkAlt,
    faFile,
    faMagic,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { routerPath } from "../../AppRouter";
import { config } from "../../config";
import { useAppState } from "../../context";
import {
    Dashboard,
    FeaturesQuery,
    Site,
    UpdateDashboardMutationOptions,
    User,
} from "../../generated";
import { DeleteDashboardModal } from "../Metrics/MetricsList/ActionsBar/DeleteDashboardModal";
import { RenameDashboardModal } from "../Metrics/MetricsList/ActionsBar/RenameDashboardModal";
import { SiteSelector } from "../Navbar/SiteSelector";
import "./Sidebar.scss";
import { SidebarSiteDashboards } from "./SidebarSiteDashboards";
import { SidebarSiteLiveFeed } from "./SidebarSiteLiveFeed";
import { SidebarSiteSettings } from "./SidebarSiteSettings";
import { SidebarSiteVisitors } from "./SidebarSiteVisitors";

export const SidebarListItem = styled.li`
    border-radius: 0.25rem !important;
    &:hover {
        background-color: rgba(
            var(--bs-primary-rgb),
            var(--bs-bg-opacity)
        ) !important;
        --bs-bg-opacity: 25% !important;
    }
    margin-bottom: 10px;
`;

const supportEmailSubject = `I have feedback for ${config.app.name}`.replaceAll(
    /\s/g,
    "%20",
);

interface SidebarProperties {
    user?: Pick<User, "firstname" | "lastname"> | null;
    dashboards: Array<Pick<Dashboard, "id" | "name" | "organisationId">>;
    sites: Array<Pick<Site, "id" | "name" | "organisationId">>;
    siteId?: string | null;
    organisationId?: string | null;
    loading?: boolean;
    features?: FeaturesQuery["features"];
    onLogout: () => void;
    onBillingClick: () => void;
    updateDashboard: (vars: UpdateDashboardMutationOptions) => void;
    handleDeleteDashboard: (dashboardId: string) => void;
}

export const Sidebar: React.FunctionComponent<SidebarProperties> = (props) => {
    const location = useLocation();

    const { showSidebar, setShowSidebar } = useAppState();
    const [toggle, setToggle] = React.useState(true);
    const [currentDashboard, setCurrentDashboard] = useState<string>("");
    const [showUpdate, setShowUpdate] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);

    useEffect(() => {
        setShowSidebar(false);
    }, [location, setShowSidebar]);

    return (
        <div
            className={`Sidebar_Wrapper p-3 bg-dark vh-100 ${
                showSidebar ? "d-block" : "d-md-block"
            }`}
        >
            <ul
                className="nav nav-pills d-flex flex-column h-100 overflow-hidden "
                style={{ paddingTop: 60 }}
            >
                <>
                    <SiteSelector {...props} />
                    <li
                        className={`nav-item w-100 fw-bold text-muted text-uppercase`}
                    >
                        <p className="mb-0 ms-2">Analytics</p>
                    </li>
                    {location.pathname.includes("/sites") ? (
                        <p className="mb-0 mt-2 ms-2 text-white">
                            Choose a site first.
                        </p>
                    ) : null}
                    {props.siteId ? (
                        <>
                            <SidebarSiteDashboards
                                siteId={props.siteId}
                                dashboards={props.dashboards}
                                toggle={toggle}
                                setToggle={setToggle}
                                setShowUpdate={setShowUpdate}
                                setShowDelete={setShowDelete}
                                setCurrentDashboard={setCurrentDashboard}
                            />
                            <SidebarSiteVisitors
                                siteId={props.siteId}
                                organisationId={props.organisationId}
                            />

                            <SidebarSiteSettings
                                siteId={props.siteId}
                                organisationId={props.organisationId}
                            />
                            <SidebarSiteLiveFeed
                                siteId={props.siteId}
                                organisationId={props.organisationId}
                            />
                        </>
                    ) : null}
                    <li
                        className={`mt-auto nav-item py-1 w-100 fw-bold text-muted text-uppercase`}
                    >
                        <p className="mb-0 mt-2 ms-2">Resources</p>
                    </li>
                    <SidebarListItem className={`nav-item py-1 w-100`}>
                        <Link
                            to={routerPath.gdpr}
                            className="nav-link link-light"
                        >
                            <FontAwesomeIcon className="me-3" icon={faFile} />
                            GDPR
                        </Link>
                    </SidebarListItem>
                    <SidebarListItem className={`nav-item py-1 w-100`}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.nocodelytics.com/help-center"
                            className="nav-link link-light"
                        >
                            <FontAwesomeIcon
                                className="me-3"
                                icon={faQuestionCircle}
                            />
                            Help Center
                            <FontAwesomeIcon
                                className="ms-2 text-muted"
                                size="sm"
                                icon={faExternalLinkAlt}
                            />
                        </a>
                    </SidebarListItem>
                    <SidebarListItem className={`nav-item py-1 w-100`}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://nocodelytics.com/roadmap"
                            className="nav-link link-light"
                        >
                            <FontAwesomeIcon className="me-3" icon={faMagic} />
                            Vote for features
                            <FontAwesomeIcon
                                className="ms-2 text-muted"
                                size="sm"
                                icon={faExternalLinkAlt}
                            />
                        </a>
                    </SidebarListItem>
                    <SidebarListItem className={`nav-item py-1 w-100`}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={config.app.supportUrl}
                            className="nav-link link-light"
                        >
                            <FontAwesomeIcon
                                className="me-3"
                                size="sm"
                                icon={faCommentAlt}
                            />
                            Chat with us
                            <FontAwesomeIcon
                                className="ms-2 text-muted"
                                size="sm"
                                icon={faExternalLinkAlt}
                            />
                        </a>
                    </SidebarListItem>
                    <SidebarListItem className={`nav-item py-1 w-100`}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`mailto:${config.email.supportEmail}?subject=${supportEmailSubject}`}
                            className="nav-link link-light"
                        >
                            <FontAwesomeIcon
                                className="me-3"
                                size="sm"
                                icon={faQuestionCircle}
                            />
                            Send feedback
                            <FontAwesomeIcon
                                className="ms-2 text-muted"
                                size="sm"
                                icon={faExternalLinkAlt}
                            />
                        </a>
                    </SidebarListItem>
                </>
            </ul>
            {props.updateDashboard && (
                <RenameDashboardModal
                    showUpdate={showUpdate}
                    dashboardId={currentDashboard}
                    updateDashboard={props.updateDashboard}
                    handleClose={() => setShowUpdate(false)}
                />
            )}

            {props.handleDeleteDashboard && (
                <DeleteDashboardModal
                    dashboardId={currentDashboard}
                    showDelete={showDelete}
                    handleClose={() => setShowDelete(false)}
                    handleDeleteDashboard={() =>
                        props.handleDeleteDashboard(currentDashboard)
                    }
                />
            )}
        </div>
    );
};
