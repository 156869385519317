import React from "react";

import { Snippet } from "./Snippet";

interface CodeSnippetProps {
    siteId: string;
    apiUrl: string;
}

export const CodeSnippet: React.FunctionComponent<CodeSnippetProps> = () => {
    const code = [
        "<!-- Nocodelytics -->",
        `<script async id="nocodelytics-snippet" type="text/javascript" src="https://tracker.nocodelytics.com/api/tracker/assets/nocodelytics.js">`,
        "</script>",
        "<!-- End Nocodelytics -->",
    ].join("");
    return <Snippet code={code} />;
};
