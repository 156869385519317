interface SiteDetailsRouterURLParams {
    organisationId: string;
    siteId: string;
}

export interface RouterURLParams {
    siteRedirect: { siteId: string };
    sitesList: { organisationId: string };
    siteSettings: SiteDetailsRouterURLParams;
    siteVisitors: { siteId: string };
    siteVisitorDetails: {
        visitorId: string;
        siteId: string;
    };
    siteEnable: SiteDetailsRouterURLParams;
    siteDashboardRedirect: SiteDetailsRouterURLParams;
    siteDetails: SiteDetailsRouterURLParams;
    editMetric: SiteDetailsRouterURLParams & {
        metricId: string;
    };
    newMetric: { dashboardId: string };
    embeddedMetrics: { dashboardId: string };
    embeddedMetricsRedirect: { siteId: string };
    authCallback: { provider: string };
    liveFeed: SiteDetailsRouterURLParams;
    dashboardDetails: { dashboardId: string };
    newDashboard: { siteId: string };
}

export const routerPath = {
    auth: "/auth",
    notifications: () => "/notifications",
    sites: "/sites",
    siteRedirect: ({ siteId }: RouterURLParams["siteRedirect"]): string =>
        `/site/${siteId}`,
    sitesList: ({ organisationId }: RouterURLParams["sitesList"]): string =>
        `/organisation/${organisationId}/sites`,
    siteDetails: ({
        organisationId,
        siteId,
    }: RouterURLParams["siteDetails"]): string =>
        `/organisation/${organisationId}/site/${siteId}`,
    siteDashboardRedirect: ({
        organisationId,
        siteId,
    }: RouterURLParams["siteDetails"]): string =>
        `/organisation/${organisationId}/site/${siteId}`,
    siteEnable: ({
        organisationId,
        siteId,
    }: RouterURLParams["siteEnable"]): string =>
        `/organisation/${organisationId}/site/${siteId}/enable`,
    siteSettings: ({
        organisationId,
        siteId,
    }: RouterURLParams["siteSettings"]): string =>
        `/organisation/${organisationId}/site/${siteId}/setting`,
    siteVisitors: ({ siteId }: RouterURLParams["siteVisitors"]): string =>
        `/site/${siteId}/visitors`,
    siteVisitorDetails: ({
        siteId,
        visitorId,
    }: RouterURLParams["siteVisitorDetails"]): string =>
        `/site/${siteId}/visitor/${visitorId}`,
    signUp: "/sign-up",
    signIn: "/sign-in",
    signInCallback: "/sign-in/callback",
    authCallback: ({ provider }: RouterURLParams["authCallback"]): string =>
        `/auth/${provider}/callback`,
    accountSettings: "/settings",
    newMetric: ({ dashboardId }: RouterURLParams["newMetric"]): string =>
        `/dashboard/${dashboardId}/metric/new`,
    newMetricV2: ({ dashboardId }: RouterURLParams["newMetric"]): string =>
        `/dashboard/${dashboardId}/metric/new/beta`,
    editMetric: ({
        organisationId,
        siteId,
        metricId,
    }: RouterURLParams["editMetric"]): string =>
        `/organisation/${organisationId}/site/${siteId}/metric/${metricId}/edit`,
    embeddedSiteDashboardRedirect: ({
        organisationId,
        siteId,
    }: RouterURLParams["siteDetails"]): string =>
        `/organisation/${organisationId}/site/${siteId}/embedded`,
    embeddedMetricsRedirect: ({ siteId }: { siteId: string }): string =>
        `/site/${siteId}/embedded`,
    embeddedMetrics: ({
        dashboardId,
    }: RouterURLParams["embeddedMetrics"]): string =>
        `/dashboard/${dashboardId}/embedded`,
    liveFeed: ({
        organisationId,
        siteId,
    }: RouterURLParams["liveFeed"]): string =>
        `/organisation/${organisationId}/site/${siteId}/live`,
    newDashboard: ({ siteId }: RouterURLParams["newDashboard"]): string =>
        `/site/${siteId}/dashboard/new`,
    dashboardDetails: ({
        dashboardId,
    }: RouterURLParams["dashboardDetails"]): string =>
        `/dashboard/${dashboardId}`,
    webflowExtensionAuthIFrame: () => "/webflow-auth-iframe",
    gdpr: "/gdpr",
};
