import {
    faChevronRight,
    faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useForm } from "react-hook-form";

import { Loading } from "..";
import { config } from "../../config";
import { Maybe } from "../../helpers";

interface SignInFormData {
    email: string;
}

interface SignInFormProperties {
    loading: boolean;
    isMagicLinkSent: boolean;
    isNotFound: Maybe<boolean>;
    onSubmit: (data: SignInFormData) => void;
}

const SignInButton: React.FunctionComponent<SignInFormProperties> = (
    props: SignInFormProperties,
) => {
    const text = props.isMagicLinkSent ? "Sent" : "Sign in";
    return (
        <button
            disabled={props.loading || props.isMagicLinkSent}
            className="btn btn-primary ps-4 pe-4 fw-bolder fs-6 d-flex align-items-center"
            type="submit"
        >
            {props.loading ? (
                <div className="d-flex">
                    Sending link...
                    <Loading color="inherit" />
                </div>
            ) : (
                text
            )}{" "}
            {props.loading ? null : (
                <FontAwesomeIcon className="ms-3 fs-6" icon={faChevronRight} />
            )}
        </button>
    );
};

export const SignInForm: React.FunctionComponent<SignInFormProperties> = (
    props,
) => {
    const { register, handleSubmit } = useForm<SignInFormData>();
    return (
        <div className="SignInForm">
            <main className="form-signin w-100 m-auto card">
                <h1 className="h3 text-center fw-bolder mb-3">
                    Sign in to {config.app.name}
                </h1>
                <form onSubmit={handleSubmit(props.onSubmit)}>
                    <label htmlFor="email">
                        <span className="fs-5 text-muted">Email</span>
                    </label>
                    <div className="row">
                        <div className="col col-12 mb-3">
                            <div>
                                <input
                                    {...register("email", {
                                        required: true,
                                    })}
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    aria-label="email"
                                    placeholder="name@example.com"
                                    disabled={props.isMagicLinkSent}
                                    required
                                />
                                {props.isNotFound ? (
                                    <div className="text-danger mt-1 fw-6">
                                        No user found for this email address
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {props.isMagicLinkSent ? (
                            <div>
                                <div>
                                    Check your email inbox for your login email.
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    <a
                                        className="btn btn-outline-danger w-100"
                                        //  eslint-disable-next-line no-secrets/no-secrets
                                        href={`https://mail.google.com/mail/u/0/#advanced-search/subject=Magic+sign-in+link+for+Nocodelytics`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Open Gmail
                                        <FontAwesomeIcon
                                            icon={faExternalLinkAlt}
                                            className="ms-2"
                                        />
                                    </a>
                                    <a
                                        className="btn btn-outline-primary ms-3 w-100"
                                        //  eslint-disable-next-line no-secrets/no-secrets
                                        href={`https://outlook.live.com`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Open Outlook
                                        <FontAwesomeIcon
                                            icon={faExternalLinkAlt}
                                            className="ms-2"
                                        />
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className="col-auto">
                                <SignInButton {...props} />
                            </div>
                        )}
                    </div>
                </form>
                <div className="mt-5">
                    Don&rsquo;t have an account?{" "}
                    <a
                        className="col fw-bolder text-decoration-none"
                        href={`${config.api.url}/auth/webflow`}
                    >
                        Sign up with Webflow
                    </a>
                </div>
            </main>
        </div>
    );
};
