import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import { routerPath } from "../../AppRouter";
import { SidebarListItem } from "./Sidebar";

interface SidebarSiteSettingsProps {
    siteId?: string | null;
    organisationId?: string | null;
}

export const SidebarSiteSettings: React.FunctionComponent<SidebarSiteSettingsProps> =
    (props) => {
        const location = useLocation();

        const isCurrentPath = location.pathname.includes("/setting");

        return (
            <SidebarListItem
                className={`nav-item py-1 w-100 ${
                    isCurrentPath ? "bg-primary bg-opacity-50 rounded" : ""
                }`}
            >
                <Link
                    to={routerPath.siteSettings({
                        organisationId: props.organisationId || "",
                        siteId: props.siteId || "",
                    })}
                    className="nav-link text-white"
                >
                    <FontAwesomeIcon icon={faCog} />
                    <span className="Sidebar_Link ms-3 fw-bold">
                        Site Settings
                    </span>
                </Link>
            </SidebarListItem>
        );
    };
