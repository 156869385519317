import React from "react";
import { Link } from "react-router-dom";

import { routerPath } from "../../AppRouter";

interface NewDashboardButtonProps {
    siteId?: string | null;
}

export const NewDashboardButton: React.FunctionComponent<NewDashboardButtonProps> =
    (props) => {
        return (
            <Link
                to={routerPath.newDashboard({
                    siteId: props.siteId || "",
                })}
                className="text-decoration-none"
                style={{ paddingBottom: 5 }}
            >
                <div className="btn btn-dark btn-sm p-0">
                    <div style={{ transform: "scale(2)", width: 16 }}>+</div>
                </div>
            </Link>
        );
    };
