import classNames from "classnames";
import { sumBy } from "lodash";
import React from "react";
import { Button, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import { useResizeDetector } from "react-resize-detector";

export interface ListChartProps {
    loading?: boolean;
    values: Array<{ label: string; value: number }>;
}

export const ListChart: React.FunctionComponent<ListChartProps> = (props) => {
    const [showAll, setShowMore] = React.useState(false);
    const { height = 0, ref } = useResizeDetector();
    const total = sumBy(props.values, "value");
    const maxItems = showAll
        ? Number.MAX_SAFE_INTEGER
        : Math.trunc(height / 29);
    const displayedValues = showAll
        ? props.values
        : props.values.slice(0, maxItems - 1);
    return (
        <div ref={ref} className="ListChart h-100">
            <div
                className={classNames([
                    "h-100",
                    {
                        "overflow-hidden": !showAll,
                    },
                ])}
                style={{ overflowY: showAll ? "scroll" : "hidden" }}
            >
                {displayedValues
                    .slice(0, maxItems - 1)
                    .map(({ label, value }) => {
                        const progress = Math.ceil((value / total) * 100);
                        return (
                            <OverlayTrigger
                                key={`${label}-${progress}`}
                                placement="top"
                                overlay={(props) => (
                                    <Tooltip {...props}>{label}</Tooltip>
                                )}
                            >
                                <div className="mb-1">
                                    <div className="fs-6 d-flex">
                                        <div
                                            style={{ fontSize: "smaller" }}
                                            className="text-truncate"
                                        >
                                            {label}
                                        </div>
                                        <div
                                            className="text-end"
                                            style={{ flex: 1 }}
                                        >
                                            <span className="fw-bold">
                                                {value.toLocaleString()}
                                            </span>
                                            <span
                                                className="text-muted ms-2"
                                                style={{ fontSize: "70%" }}
                                            >
                                                ({progress}%)
                                            </span>
                                        </div>
                                    </div>
                                    <ProgressBar
                                        style={{ height: 5 }}
                                        min={0}
                                        max={100}
                                        now={progress}
                                    />
                                </div>
                            </OverlayTrigger>
                        );
                    })}
            </div>
            {!showAll && displayedValues.length !== props.values.length && (
                <Button
                    style={{
                        position: "absolute",
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        bottom: 0,
                        textAlign: "center",
                    }}
                    onClick={() => setShowMore(true)}
                    size="sm"
                    variant="light"
                >
                    Show all
                </Button>
            )}
        </div>
    );
};
