import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
    Card,
    Col,
    Form,
    FormControl,
    FormLabel,
    InputGroup,
    Placeholder,
    Row,
} from "react-bootstrap";

import { MainLayout } from "../../../Layout/MainLayout";
import { Loading } from "../../../components/Loading/Loading";

interface NewMetricLoadingProps {}

export const NewMetricLoading: React.FunctionComponent<NewMetricLoadingProps> =
    () => {
        return (
            <MainLayout
                title={`Add new metric to...`}
                rightElement={
                    <Placeholder.Button
                        className="w-100"
                        variant="outline-primary"
                        aria-hidden="true"
                    >
                        <span>
                            <FontAwesomeIcon
                                className="fs-6 me-2"
                                icon={faSave}
                            />
                            Save to dashboard
                        </span>
                    </Placeholder.Button>
                }
            >
                <Form>
                    <Row>
                        <Col sm={6}>
                            <Row>
                                <Col sm={12}>
                                    <FormLabel className="fw-bold">
                                        Metric name*
                                    </FormLabel>
                                </Col>
                                <Col sm={12}>
                                    <InputGroup>
                                        <FormControl disabled />
                                    </InputGroup>
                                </Col>
                                <Col sm={12}>
                                    <Placeholder
                                        as={Card.Text}
                                        animation="glow"
                                        className="mt-2"
                                    >
                                        <Placeholder xs={7} />{" "}
                                        <Placeholder xs={4} />{" "}
                                    </Placeholder>
                                </Col>
                                <Col sm={12}>
                                    <FormLabel className="mt-4 fw-bold">
                                        What do you want to track?*
                                    </FormLabel>
                                </Col>
                                <Col sm={12}>
                                    <InputGroup>
                                        <FormControl disabled />
                                    </InputGroup>
                                </Col>
                                <Col sm={12}>
                                    <Placeholder
                                        as={Card.Text}
                                        animation="glow"
                                        className="mt-2"
                                    >
                                        <Placeholder xs={7} />{" "}
                                        <Placeholder xs={4} />{" "}
                                        <Placeholder xs={6} />{" "}
                                    </Placeholder>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <div className="border rounded-3 shadow-sm p-3">
                                <h3 className="text-truncate fs-5">
                                    Preview your metric
                                </h3>
                                <div className="text-center d-flex flex-column align-items-center fw-bold opacity-50">
                                    <Loading />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </MainLayout>
        );
    };
