import { faCalendar, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { format, isEqual, subDays, subMonths, subYears } from "date-fns";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { DateRangePicker, FocusedInputShape } from "react-dates";

import { Feature, MetricTimeframe } from "../../../../generated";
import { ActionsBarProps } from "./ActionsBar";

export interface MetricTimeframeButtonProps
    extends Pick<
        ActionsBarProps,
        "hidePaidFeatures" | "triggerUpgradeModal" | "onDateChange"
    > {
    paidFeatures: Feature[];
}

export const getStartAtDate = (id: MetricTimeframe): Date => {
    const now = Date.now();
    switch (id) {
        case MetricTimeframe.Today:
            return new Date(format(now, "yyyy-MM-dd 00:00:00"));
        case MetricTimeframe.Yesterday:
            return subDays(now, 1);
        case MetricTimeframe.Last7days:
            return subDays(now, 7);
        case MetricTimeframe.Last30days:
            return subDays(now, 30);
        case MetricTimeframe.Last3Months:
            return subMonths(now, 3);
        case MetricTimeframe.Last6Months:
            return subMonths(now, 6);
        case MetricTimeframe.LastYear:
            return subYears(now, 1);
        default:
            return subDays(now, 30);
    }
};

const items = [
    {
        timeframe: MetricTimeframe.Today,
        label: "Today",
    },
    {
        timeframe: MetricTimeframe.Yesterday,
        label: "Yesterday",
    },
    {
        timeframe: MetricTimeframe.Last7days,
        label: "Last 7 days",
    },
    {
        timeframe: MetricTimeframe.Last30days,
        label: "Last 30 days",
    },
    {
        timeframe: MetricTimeframe.Last3Months,
        label: "Last 3 months",
    },
    {
        timeframe: MetricTimeframe.Last6Months,
        label: "Last 6 months",
    },
    {
        timeframe: MetricTimeframe.LastYear,
        label: "Last year",
    },
];

export const getLabel = ({
    selectedMetricTimeframe,
    customStartDate,
    customEndDate,
}: {
    selectedMetricTimeframe: MetricTimeframe;
    customStartDate?: Date;
    customEndDate?: Date;
}): string => {
    const dateFormat = "d LLL yyyy";
    if (selectedMetricTimeframe === MetricTimeframe.Today) {
        return "Today";
    }
    if (
        selectedMetricTimeframe === MetricTimeframe.Custom &&
        customStartDate &&
        customEndDate
    ) {
        if (isEqual(customStartDate, customEndDate)) {
            return format(customStartDate, dateFormat);
        }
        return `${format(customStartDate, dateFormat)} - ${format(
            customEndDate,
            dateFormat,
        )}`;
    }
    const start = format(getStartAtDate(selectedMetricTimeframe), dateFormat);
    const today = format(new Date(), dateFormat);
    return `${start} - ${today}`;
};

const paidMetricTimeframes = new Set([MetricTimeframe.Custom]);

export const getDefaultTimeframe = (
    urlSearchParams: URLSearchParams,
): MetricTimeframe | null => {
    const timeframeFromQs = urlSearchParams.get("timeframe");
    if (!timeframeFromQs) {
        return null;
    }
    if (
        !Object.values(MetricTimeframe).includes(
            timeframeFromQs as unknown as MetricTimeframe,
        )
    ) {
        return null;
    }
    return MetricTimeframe[timeframeFromQs as unknown as MetricTimeframe];
};

export const TimeframeButton: React.FunctionComponent<MetricTimeframeButtonProps> =
    (props) => {
        const [selected, setSelected] = useState<MetricTimeframe | null>(
            getDefaultTimeframe(new URLSearchParams(window.location.search)),
        );
        const [customStartDate, setCustomStartDate] = useState<Moment | null>(
            null,
        );
        const [customEndDate, setCustomEndDate] = useState<Moment | null>(null);
        const isSubscriptionValid = props.paidFeatures.includes(
            Feature.HistoricalData,
        );
        const defaultSelected = isSubscriptionValid
            ? MetricTimeframe.Last30days
            : MetricTimeframe.Last7days;
        const selectedMetricTimeframe: MetricTimeframe =
            selected || defaultSelected;

        const [focusedInput, setFocusedInput] =
            useState<FocusedInputShape | null>(null);

        return (
            <>
                <ButtonGroup
                    className="MetricTimeframeButton bg-white h-100 border"
                    role="group"
                    aria-label="Choose the date range"
                >
                    <Dropdown className="btn-group" role="group">
                        <Dropdown.Toggle
                            variant="outline-dark"
                            className="border border-light"
                        >
                            <FontAwesomeIcon
                                icon={faCalendar}
                                className="me-2"
                            />
                            <span className="d-none d-lg-inline d-xl-inline">
                                {getLabel({
                                    selectedMetricTimeframe,
                                    customStartDate: customStartDate?.toDate(),
                                    customEndDate: customEndDate?.toDate(),
                                })}
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="px-1 w-100">
                            {items
                                .filter(
                                    ({ timeframe }) =>
                                        !props.hidePaidFeatures ||
                                        isSubscriptionValid ||
                                        !paidMetricTimeframes.has(timeframe),
                                )
                                .map(({ timeframe, label }) => (
                                    <Dropdown.Item
                                        key={label}
                                        onClick={() => {
                                            if (
                                                !isSubscriptionValid &&
                                                paidMetricTimeframes.has(
                                                    timeframe,
                                                )
                                            ) {
                                                if (props.triggerUpgradeModal) {
                                                    props.triggerUpgradeModal();
                                                }
                                                return;
                                            }
                                            setSelected(timeframe);
                                            props.onDateChange({
                                                startAt:
                                                    getStartAtDate(timeframe),
                                                endAt: new Date(),
                                                timeframe,
                                            });
                                            setCustomStartDate(
                                                moment(
                                                    getStartAtDate(timeframe),
                                                ),
                                            );
                                            setCustomEndDate(moment());
                                        }}
                                        className={`Dropdown_Option text-dark px-2 rounded ${
                                            timeframe ===
                                            selectedMetricTimeframe
                                                ? "bg-primary rounded bg-opacity-25 fw-bold"
                                                : ""
                                        }`}
                                    >
                                        {!isSubscriptionValid &&
                                            paidMetricTimeframes.has(
                                                timeframe,
                                            ) && (
                                                <FontAwesomeIcon
                                                    icon={faLock}
                                                    className="me-1"
                                                />
                                            )}

                                        {label}
                                    </Dropdown.Item>
                                ))}
                            <Dropdown.Item
                                className={classNames([
                                    "Dropdown_Option text-dark px-2",
                                    {
                                        "fw-bold":
                                            selectedMetricTimeframe ===
                                            MetricTimeframe.Custom,
                                    },
                                ])}
                                onClick={() => {
                                    if (
                                        !isSubscriptionValid &&
                                        paidMetricTimeframes.has(
                                            MetricTimeframe.Custom,
                                        )
                                    ) {
                                        if (props.triggerUpgradeModal) {
                                            props.triggerUpgradeModal();
                                        }
                                        return;
                                    }
                                    setFocusedInput("startDate");
                                }}
                            >
                                {!isSubscriptionValid && (
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        className="me-1"
                                    />
                                )}
                                Custom date range
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
                <div style={{ display: "none" }}>
                    <DateRangePicker
                        withPortal
                        hideKeyboardShortcutsPanel
                        isOutsideRange={(date) => {
                            if (date.isAfter(moment().endOf("day"))) {
                                return true;
                            }
                            if (isSubscriptionValid) {
                                return false;
                            }
                            const sevenDaysAgo = subDays(new Date(), 8); // give one more day in case it's close to midnight.
                            return date.isBefore(sevenDaysAgo);
                        }}
                        startDate={customStartDate}
                        startDateId="your_unique_start_date_id"
                        endDate={customEndDate}
                        endDateId="your_unique_end_date_id"
                        onDatesChange={({ startDate, endDate }) => {
                            setSelected(MetricTimeframe.Custom);
                            setCustomStartDate(startDate);
                            setCustomEndDate(endDate);
                            if (startDate && endDate) {
                                props.onDateChange({
                                    startAt: startDate.startOf("day").toDate(),
                                    endAt: endDate.endOf("day").toDate(),
                                    timeframe: MetricTimeframe.Custom,
                                });
                            }
                        }}
                        initialVisibleMonth={() =>
                            moment().subtract(1, "months")
                        }
                        minimumNights={0}
                        onFocusChange={(input) => setFocusedInput(input)}
                        focusedInput={focusedInput}
                    />
                </div>
            </>
        );
    };
