import { formatDistance } from "date-fns";
import React from "react";
import { useHistory, useParams } from "react-router";

import { routerPath, RouterURLParams } from "../../AppRouter";

interface VisitorsRowProps {
    index: number;
    id: string;
    emailAddress: string;
    lastInteraction: string;
}

export const VisitorsRow: React.FunctionComponent<VisitorsRowProps> = (
    props,
) => {
    const history = useHistory();
    const { siteId } = useParams<RouterURLParams["siteVisitors"]>();
    const { id, emailAddress, lastInteraction } = props;

    return (
        <tr
            key={props.id}
            onClick={() =>
                history.push(
                    routerPath.siteVisitorDetails({
                        siteId,
                        visitorId: id,
                    }),
                )
            }
            className="cursor-pointer"
        >
            <td className="py-3">{props.index + 1}</td>
            <td className="py-3 fw-bold" data-testid={`email-address-${id}`}>
                {emailAddress || id}
            </td>
            <td className="py-3">
                {formatDistance(new Date(lastInteraction), new Date())} ago
            </td>
        </tr>
    );
};
