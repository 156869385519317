import emojiFlags from "emoji-flags";
import React, { useState } from "react";
import { Table } from "react-bootstrap";

import { HoverText } from "../../components/Typography";
import { Visitor } from "../../generated";

interface VisitorDetailsCardProperties extends Visitor {
    name?: string;
    isMember: boolean;
}

export const VisitorDetailsCard: React.FunctionComponent<VisitorDetailsCardProperties> =
    (props) => {
        const [sourceFavicon, setSourceFavicon] = useState(
            props.initialReferer && props.initialReferer.includes(`http`)
                ? `${props.initialReferer}/favicon.ico`
                : `http://` + props.initialReferer + `/favicon.ico`,
        );

        const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

        return (
            <div>
                <h4 className="mb-3">Details</h4>
                <Table bordered responsive>
                    <tbody>
                        <tr>
                            <td style={{ width: "15%" }} className="fw-bold">
                                Name
                            </td>
                            <td>{props.name ?? "Anonymous"}</td>
                            <td className="fw-bold" style={{ width: "15%" }}>
                                <span>Email</span>
                            </td>
                            <td>
                                {props.emailAddress ? (
                                    <HoverText text={props.emailAddress}>
                                        <span>
                                            {props.emailAddress || "N/A"}
                                        </span>
                                    </HoverText>
                                ) : (
                                    props.emailAddress || "N/A"
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="fw-bold">Signed Up</span>
                            </td>
                            <td>{props.isMember ? "Yes" : "No"}</td>
                            <td>
                                <span className="fw-bold">Last seen</span>
                            </td>
                            <td>
                                {new Date(
                                    props.lastInteraction,
                                ).toLocaleString()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="fw-bold">Location</span>
                            </td>
                            <td>
                                {props.country
                                    ? emojiFlags.countryCode(props.country)
                                          .emoji
                                    : null}{" "}
                                {props.city ? `${props.city}` : null}
                                {props.city && props.country ? ", " : null}
                                {props.country
                                    ? regionNames.of(props.country)
                                    : null}{" "}
                            </td>
                            <td>
                                <span className="fw-bold">Source</span>
                            </td>
                            <td className="d-flex align-items-center">
                                {props.initialReferer ? (
                                    <img
                                        height="22"
                                        width="22"
                                        src={
                                            props.initialReferer.includes(
                                                "webflow.io",
                                            )
                                                ? `https://webflow.com/favicon.ico`
                                                : sourceFavicon
                                        }
                                        alt={
                                            props.initialReferer ||
                                            "Initial referrer favicon"
                                        }
                                        onError={() =>
                                            setSourceFavicon(
                                                `https://webflow.com/favicon.ico`,
                                            )
                                        }
                                        className="me-2"
                                    />
                                ) : null}
                                {props.initialReferer
                                    ? // eslint-disable-next-line unicorn/no-nested-ternary
                                      props.initialReferer.includes("http")
                                        ? props.initialReferer.replace(
                                              "https://",
                                              "",
                                          )
                                        : props.initialReferer
                                    : "Direct"}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    };
