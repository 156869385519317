import { ApolloProvider } from "@apollo/client";
import "bootstrap";
import React from "react";

import { AppRouter } from "../AppRouter";
import { apolloClient } from "../apolloClient";

const App: React.FunctionComponent = () => {
    return (
        <ApolloProvider client={apolloClient}>
            <AppRouter />
        </ApolloProvider>
    );
};

export { App };
