import React from "react";
import { FormControlProps } from "react-bootstrap";

import { InputWithLabel } from "../../InputWithLabel";

interface MetricNameInputProps {
    formControl: FormControlProps;
}

export const MetricNameInput: React.FunctionComponent<MetricNameInputProps> = (
    props,
) => {
    return (
        <InputWithLabel
            label="Metric name"
            formControl={{
                placeholder: "e.g. Top Freelancers",
                id: "metric-name",
                ...props.formControl,
            }}
        />
    );
};
