import React, { useState } from "react";
import {
    Accordion,
    Breadcrumb,
    Button,
    Col,
    ListGroup,
    Modal,
    Row,
} from "react-bootstrap";
import { Redirect, useHistory, useParams } from "react-router";

import { RouterURLParams, routerPath } from "../../AppRouter";
import { MainLayout } from "../../Layout";
import { CodeSnippet, ErrorText, Loading } from "../../components";
import { Divider, StyledH4 } from "../../components/Typography";
import { config } from "../../config";
import {
    SitesListContainerDocument,
    useCmsCollectionsItemsQuery,
    useCmsCollectionsQuery,
    useDashboardBySiteIdQuery,
    useDeleteSiteMutation,
    useSiteByIdQuery,
    useSiteDomainsQuery,
    useSyncSiteWithWebflowMutation,
    useUpdateSiteByIdMutation,
} from "../../generated/graphql";
import { CsvExport } from "./CsvExport";

const SiteDomains: React.FunctionComponent<{
    siteId: string;
}> = (props) => {
    const siteResults = useSiteByIdQuery({ variables: { id: props.siteId } });
    const siteDomainsResults = useSiteDomainsQuery({
        variables: { siteId: props.siteId },
    });
    const loading = siteResults.loading || siteDomainsResults.loading;

    const data = { ...siteDomainsResults.data, ...siteResults.data };
    const error = { ...siteDomainsResults.error, ...siteResults.error };
    if (loading) {
        return <Loading />;
    }
    if (error) {
        <ErrorText from="Site settings domains" error={error} />;
    }
    return (
        <div>
            <h4>Domains</h4>
            <ul className="list-group list-group-flush">
                {[
                    ...(data.siteDomains?.domains || []),
                    `${data.siteById?.shortName}.webflow.io`,
                ].map((domain) => (
                    <li key={btoa(domain)} className="list-group-item ps-0">
                        <a
                            href={`https://${domain}`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {domain}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const SiteCollectionItems: React.FC<{ collectionId: string }> = ({
    collectionId,
}) => {
    const cmsCollectionsItemsResults = useCmsCollectionsItemsQuery({
        variables: { collectionId },
    });

    return (
        <div>
            {cmsCollectionsItemsResults.loading ? <Loading /> : null}
            <ListGroup>
                {cmsCollectionsItemsResults.data?.cmsCollectionItems.map(
                    (item) => (
                        <ListGroup.Item key={item.id}>
                            {item.name} ({item.id})
                        </ListGroup.Item>
                    ),
                )}
            </ListGroup>
        </div>
    );
};

const SiteCollections: React.FunctionComponent<{
    siteId: string;
}> = (props) => {
    const cmsCollectionsResults = useCmsCollectionsQuery({
        variables: { siteId: props.siteId },
    });
    const [syncSiteWithWebflow, syncSiteWithWebflowResults] =
        useSyncSiteWithWebflowMutation({
            variables: {
                siteId: props.siteId,
            },
        });
    return (
        <div>
            <div style={{ display: "flex" }}>
                <h4 style={{ flex: 1 }}>CMS Collections</h4>
                <Button
                    className="mb-2"
                    variant="outline-primary"
                    disabled={
                        syncSiteWithWebflowResults.loading ||
                        syncSiteWithWebflowResults.called
                    }
                    onClick={() => syncSiteWithWebflow()}
                >
                    {syncSiteWithWebflowResults.loading && "Loading..."}
                    {!syncSiteWithWebflowResults.called
                        ? "Sync now"
                        : "Wait a few minute and refresh the page"}
                </Button>
            </div>
            <div>{syncSiteWithWebflowResults.error?.message}</div>
            <div>
                {cmsCollectionsResults.data?.cmsCollections.length === 0
                    ? "No collections found. Try to sync CMS data again."
                    : null}
            </div>
            {cmsCollectionsResults.loading ? <Loading /> : null}
            <Accordion>
                {cmsCollectionsResults.data?.cmsCollections.map(
                    (collection) => (
                        <Accordion.Item
                            key={collection.id}
                            eventKey={collection.id}
                        >
                            <Accordion.Header>
                                {collection.name} ({collection.id})
                            </Accordion.Header>
                            <Accordion.Body>
                                <SiteCollectionItems
                                    collectionId={collection.id}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    ),
                )}
            </Accordion>
        </div>
    );
};

const DeleteSiteModal: React.FunctionComponent<{
    shortName: string;
    showModal: boolean;
    onClose: () => void;
    onDeleteSite: () => void;
}> = (props) => {
    const [siteInput, setSiteInput] = useState<string>("");
    return (
        <Modal centered show={props.showModal} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Site</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Deleting your site will delete all of your metrics in
                    Nocodelytics. It does not delete the site in Webflow.
                </p>
                <p>
                    If you want to add the site to Nocodelytics in future, you
                    will need to re-connect to Webflow and follow the setup
                    process again.
                </p>
                <p className="fw-bold text-center">
                    Type <span className="text-danger">{props.shortName}</span>{" "}
                    below to confirm and delete.
                </p>
                <input
                    id="name"
                    type="text"
                    placeholder={props.shortName}
                    aria-label="name"
                    value={siteInput ? siteInput : ""}
                    onChange={(e) => setSiteInput(e.target.value)}
                    className="w-100 p-2"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button
                    variant="danger"
                    onClick={props.onDeleteSite}
                    disabled={siteInput !== props.shortName}
                >
                    Delete Forever
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export const SiteSettingsPage: React.FunctionComponent = () => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const { siteId, organisationId } =
        useParams<RouterURLParams["siteSettings"]>();
    const siteByIdResults = useSiteByIdQuery({ variables: { id: siteId } });
    const dashboardBySiteId = useDashboardBySiteIdQuery({
        variables: { siteId },
    });

    const [, updateSiteByIdResults] = useUpdateSiteByIdMutation();
    const [deleteSite] = useDeleteSiteMutation({
        variables: { siteId: siteId || "" },
        refetchQueries: [SitesListContainerDocument],
    });

    const handleDeleteSite = () => {
        deleteSite();
        window.location.replace(routerPath.sitesList({ organisationId }));
    };

    const data = {
        ...siteByIdResults.data,
        ...updateSiteByIdResults.data,
        ...dashboardBySiteId.data,
    };

    const error =
        siteByIdResults.error ||
        updateSiteByIdResults.error ||
        dashboardBySiteId.error;
    const loading = siteByIdResults.loading || dashboardBySiteId.loading;

    if (loading) {
        return <Loading makeItFun />;
    }
    if (error || !data.siteById) {
        return (
            <ErrorText
                from="SiteSettingsPage"
                error={error || "Site or organisation data not available"}
            />
        );
    }

    if (data?.siteById?.isEnabled === false) {
        return (
            <Redirect
                to={routerPath.siteEnable({
                    organisationId: data.siteById.organisationId,
                    siteId,
                })}
            />
        );
    }

    const settingsUrl = `https://webflow.com/dashboard/sites/${data?.siteById?.shortName}/code`;

    return (
        <MainLayout
            siteId={siteId}
            loading={loading}
            showUpgradeModal={showUpgradeModal}
            triggerUpgradeModal={() => setShowUpgradeModal(true)}
            onUpgradeModalClose={() => setShowUpgradeModal(false)}
        >
            <Row>
                <Col>
                    <Breadcrumb className="Breadcrumb">
                        <Breadcrumb.Item
                            onClick={() =>
                                history.push(
                                    routerPath.siteRedirect({
                                        siteId,
                                    }),
                                )
                            }
                            className="fw-bold"
                        >
                            {data.siteById.name}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Site Settings</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h3>Site Settings</h3>
                </Col>
            </Row>
            <Divider />
            <StyledH4>Tracking Script</StyledH4>
            {data?.siteById?.isEnabled ? (
                <p className="text-muted mb-4">
                    Here&apos;s the tiny bit of code that does all the tracking
                    magic. Go to{" "}
                    <a href={settingsUrl} target="_blank" rel="noreferrer">
                        Webflow Project Settings
                    </a>
                    .
                </p>
            ) : (
                <p className="text-muted mb-4">
                    Uh oh. Your site is not being tracked. Please add the below
                    script to your site to finish setup.
                </p>
            )}
            <CodeSnippet apiUrl={config.api.url} siteId={siteId} />
            <Divider className="mt-4" />
            <div>
                <SiteDomains siteId={siteId} />
                <Divider className="mt-4" />
                <CsvExport siteId={siteId} />
                <Divider className="mt-4" />
                <SiteCollections siteId={siteId} />
            </div>

            {data.siteById.shortName ? (
                <div>
                    <div>
                        <Divider />
                        <h4 className="text-danger">Danger Zone</h4>
                    </div>
                    <div className="mt-3">
                        <p>
                            Wait! Make sure you know what you&apos;re doing
                            before you proceed.
                        </p>
                        <Button
                            variant="outline-danger"
                            onClick={() => setShowModal(true)}
                        >
                            Delete Site
                        </Button>
                    </div>
                    <DeleteSiteModal
                        shortName={data.siteById.shortName}
                        showModal={showModal}
                        onClose={() => setShowModal(false)}
                        onDeleteSite={handleDeleteSite}
                    />
                </div>
            ) : null}
        </MainLayout>
    );
};
