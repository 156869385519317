import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import { routerPath } from "../../AppRouter";
import { SidebarListItem } from "./Sidebar";

interface SidebarSiteVisitorsProps {
    siteId?: string | null;
    organisationId?: string | null;
}

export const SidebarSiteVisitors: React.FunctionComponent<SidebarSiteVisitorsProps> =
    (props) => {
        const location = useLocation();
        const isCurrentPath = location.pathname.includes("/visitors");

        return (
            <SidebarListItem
                className={`nav-item py-1 w-100 ${
                    isCurrentPath ? "bg-primary bg-opacity-50 rounded" : ""
                }`}
            >
                <Link
                    to={routerPath.siteVisitors({
                        siteId: props.siteId || "",
                    })}
                    className="Sidebar_Link nav-link text-white"
                >
                    <FontAwesomeIcon icon={faUserCircle} />
                    <span className="Sidebar_Link ms-3 fw-bold">Visitors</span>
                </Link>
            </SidebarListItem>
        );
    };
