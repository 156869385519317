import React from "react";
import { Redirect, useParams } from "react-router";

import { routerPath, RouterURLParams } from "../../AppRouter";
import { ErrorText, Loading } from "../../components";
import { useDashboardBySiteIdQuery } from "../../generated/graphql";

export const DashboardRedirectPage: React.FunctionComponent = () => {
    const { siteId } = useParams<RouterURLParams["siteDashboardRedirect"]>();
    const dashboardBySiteIdQuery = useDashboardBySiteIdQuery({
        variables: { siteId },
    });
    if (dashboardBySiteIdQuery.loading) {
        return <Loading makeItFun />;
    }
    if (dashboardBySiteIdQuery.error) {
        <ErrorText
            from="DashboardRedirectPage"
            error={dashboardBySiteIdQuery.error}
        />;
    }
    const dashboards = dashboardBySiteIdQuery.data?.dashboardBySiteId || [];
    if (
        dashboardBySiteIdQuery.called &&
        !dashboardBySiteIdQuery.loading &&
        dashboards.length === 0
    ) {
        return <Redirect to={routerPath.newDashboard({ siteId })} />;
    }

    return (
        <Redirect
            to={routerPath.dashboardDetails({
                dashboardId: dashboards[0].id,
            })}
        />
    );
};
