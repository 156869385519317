import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { RouterURLParams, routerPath } from "../../../AppRouter";
import { MainLayout } from "../../../Layout";
import { addItemToGridLayout } from "../../../Layout/GridLayout/addItemToGridLayout";
import { ErrorText } from "../../../components";
import { MetricFormData } from "../../../components/MetricEditor";
import { MetricFormContainer } from "../../../containers";
import {
    MetricChartBreakdownBy,
    MetricFieldsFragmentDoc,
    MetricType,
    useCreateMetricMutation,
    useDashboardByIdQuery,
    useSiteByIdLazyQuery,
    useUpdateDashboardMutation,
} from "../../../generated/graphql";
import { NewMetricLoading } from "./NewMetricLoading";

interface NewMetricPageProps {}

export interface NewMetricFormData {
    name: string;
    metricType: MetricType;
    cssSelector?: string;
    path: string | null;
    webflowCollectionId?: string;
}

export const NewMetricPage: React.FunctionComponent<NewMetricPageProps> =
    () => {
        const [showUpgradeModal, setShowUpgradeModal] = useState(false);
        const formControl = useForm<MetricFormData>({
            defaultValues: {
                metricType: MetricType.View,
                breakdownBy: MetricChartBreakdownBy.None,
                uniqueVisitor: false,
            },
        });
        const { dashboardId } = useParams<RouterURLParams["newMetric"]>();
        const [siteByIdQuery, siteByIdQueryResults] = useSiteByIdLazyQuery();
        const dashboardByIdQueryResults = useDashboardByIdQuery({
            variables: { dashboardId },
            onCompleted: (data) => {
                siteByIdQuery({ variables: { id: data.dashboard.siteId } });
            },
        });
        const [updateDashboard, updateDashboardResults] =
            useUpdateDashboardMutation();

        const loading =
            siteByIdQueryResults.loading || dashboardByIdQueryResults.loading;
        const error =
            siteByIdQueryResults.error || dashboardByIdQueryResults.error;
        const data = {
            ...dashboardByIdQueryResults.data,
            ...siteByIdQueryResults.data,
        };

        const { siteId, organisationId } = data.dashboard || {
            siteId: "",
            organisationId: "",
        };

        const [createMetricMutation, createMetricMutationResults] =
            useCreateMetricMutation({
                update: (cache, { data }) => {
                    return cache.modify({
                        fields: {
                            metricsBySiteId(existingMetrics = []) {
                                const newMetricRef = cache.writeFragment({
                                    data: data?.createMetric,
                                    fragment: MetricFieldsFragmentDoc,
                                });
                                return [...existingMetrics, newMetricRef];
                            },
                        },
                    });
                },
                onCompleted: async ({ createMetric }) => {
                    const layout = data.dashboard?.gridLayout?.map(
                        ({ id, h, w, x, y }) => ({ id, h, w, x, y }),
                    );
                    await updateDashboard({
                        variables: {
                            input: {
                                id: dashboardId,
                                gridLayout: addItemToGridLayout({
                                    layout,
                                    metricId: createMetric.id,
                                }),
                            },
                        },
                    });
                    window.location.replace(
                        routerPath.dashboardDetails({ dashboardId }),
                    );
                },
            });

        if (loading || !siteByIdQueryResults.called) {
            return <NewMetricLoading />;
        }
        if (error) {
            {
                return <ErrorText error={error} from="NewMetricPage" />;
            }
        }
        if (!data.siteById) {
            return <ErrorText error="Site not found" from="NewMetricPage" />;
        }
        const handleSubmit = formControl.handleSubmit(
            (metric) => {
                createMetricMutation({
                    variables: {
                        metric: {
                            ...metric,
                            dashboardId,
                        },
                    },
                });
            },
            (errors) => {
                for (const error in errors) {
                    window.alert(`Error: Invalid ${error}`);
                }
            },
        );

        return (
            <MainLayout
                siteId={siteId}
                title={`Add new metric to ${data.siteById.name}`}
                showUpgradeModal={showUpgradeModal}
                triggerUpgradeModal={() => setShowUpgradeModal(true)}
                onUpgradeModalClose={() => setShowUpgradeModal(false)}
                rightElement={
                    <>
                        <Link
                            to={routerPath.dashboardDetails({
                                dashboardId,
                            })}
                        >
                            <Button
                                variant="light"
                                disabled={
                                    createMetricMutationResults.loading ||
                                    updateDashboardResults.loading
                                }
                                className="me-2"
                            >
                                Cancel
                            </Button>
                        </Link>

                        <Button
                            variant="primary"
                            disabled={
                                createMetricMutationResults.loading ||
                                updateDashboardResults.loading
                            }
                            onClick={handleSubmit}
                            className="fw-bold"
                        >
                            <span>
                                <FontAwesomeIcon
                                    className="fs-6 me-2"
                                    icon={faSave}
                                />
                                Save to dashboard
                            </span>
                        </Button>
                    </>
                }
            >
                <MetricFormContainer
                    onSubmit={handleSubmit}
                    siteId={siteId}
                    organisationId={organisationId}
                    formControl={formControl}
                    triggerUpgradeModal={() => setShowUpgradeModal(true)}
                />
                {createMetricMutationResults.error && (
                    <div className="text-danger fw-bold">
                        {createMetricMutationResults.error.message}
                    </div>
                )}
            </MainLayout>
        );
    };
