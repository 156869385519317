import React from "react";
import {
    FormLabel,
    FormSelect,
    FormSelectProps,
    InputGroup,
} from "react-bootstrap";

import { MetricType } from "../../../generated";

interface MetricTypeInputProps {
    formSelectProps: FormSelectProps;
}

export const metricTypeLabel: { [key: string]: string } = {
    [MetricType.Click]: "Button or Link Clicks",
    [MetricType.Cms]: "Collection",
    [MetricType.CustomField]: "Custom Field",
    [MetricType.Keyboard]: "Searches",
    [MetricType.View]: "Page Views",
    [MetricType.Visitors]: "Visitors",
    [MetricType.Form]: "Form",
};

export const MetricTypeInput: React.FunctionComponent<MetricTypeInputProps> = (
    props,
) => {
    return (
        <>
            <FormLabel htmlFor="metric-name" className="fw-bold mt-3">
                What do you want to track?
            </FormLabel>
            <InputGroup>
                <FormSelect id="event-type" {...props.formSelectProps}>
                    {Object.entries(metricTypeLabel).map(([value, label]) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))}
                </FormSelect>
            </InputGroup>
        </>
    );
};
