import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

import { Loading } from "../../components";
import { config } from "../../config";
import { useShortLiveTokenQuery } from "../../generated";

interface CsvExportProps {
    siteId: string;
}

export const CsvExport: React.FC<CsvExportProps> = (props) => {
    const shortLiveTokenQueryResults = useShortLiveTokenQuery({
        fetchPolicy: "network-only",
        pollInterval: 9000,
    });
    if (shortLiveTokenQueryResults.error) {
        return (
            <p>
                Error loading short live token:{" "}
                {shortLiveTokenQueryResults.error.message}
            </p>
        );
    }
    if (shortLiveTokenQueryResults.loading) {
        <Loading />;
    }
    return (
        <div>
            <h4>Data Export</h4>
            <p>
                Export your data in CSV format. Please note that this process
                might take a few minutes.
            </p>
            <Button
                href={`${config.api.url}/export/csv/${props.siteId}?token=${shortLiveTokenQueryResults.data?.shortLiveToken}`}
                target="_blank"
                variant="outline-primary"
            >
                {" "}
                <FontAwesomeIcon className="me-2" icon={faDownload} />
                Download
            </Button>
        </div>
    );
};
