import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { throttle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { routerPath } from "../../AppRouter";
import { useAppState } from "../../context";
import { FeaturesQuery, Site, User } from "../../generated";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

export interface SiteSelectorProperties {
    user?: Pick<User, "firstname" | "lastname"> | null;
    siteId?: string | null;
    organisationId?: string | null;
    loading?: boolean;
    features?: FeaturesQuery["features"];
    onLogout: () => void;
    onBillingClick: () => void;
    sites: Array<Pick<Site, "id" | "name" | "organisationId">>;
}

export const SiteSelector: React.FunctionComponent<SiteSelectorProperties> = (
    props,
) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const { register, watch } = useForm<{ searchSiteNameValue: string }>();
    const searchSiteNameValue = watch("searchSiteNameValue");
    const { setShowConfetti } = useAppState();
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    useOnClickOutside(ref, () => setShowDropdown(false));

    const searchInput = (
        <InputGroup className="Input_Group">
            <InputGroup.Text className="bg-primary text-white">
                <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
                {...register("searchSiteNameValue")}
                onChange={throttle((event) => {
                    register("searchSiteNameValue").onChange(event);
                }, 500)}
                type="text"
                placeholder="Search sites"
                className="bg-light text-dark border-0 rounded"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                autoComplete="off"
            />
        </InputGroup>
    );

    useEffect(() => {
        if (searchSiteNameValue === "tada") {
            setShowConfetti(true);
        } else {
            setShowConfetti(false);
        }
    }, [searchSiteNameValue, setShowConfetti]);

    const currentSite = props.sites.find((site) => site.id === props.siteId);

    return (
        <Dropdown
            show={showDropdown}
            onToggle={() => setShowDropdown(true)}
            className="dropdown w-100 mb-3"
            ref={ref}
        >
            {showDropdown ? (
                searchInput
            ) : (
                <Dropdown.Toggle
                    className="btn d-flex align-items-center justify-content-between bg-transparent dropdown-toggle border-secondary w-100"
                    type="button"
                    id="sitesDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <span className="Site_Link me-3 text-truncate d-lg-inline d-xl-inline text-white fw-bold">
                        {currentSite?.name || "My sites"}
                    </span>
                </Dropdown.Toggle>
            )}

            <Dropdown.Menu
                className="dropdown-menu mt-1 w-100 px-1"
                aria-labelledby="sitesDropdown"
                style={{ position: "absolute" }}
            >
                {props.sites
                    .filter((site) =>
                        searchSiteNameValue !== undefined &&
                        searchSiteNameValue.length > 0
                            ? site.name
                                  .toLowerCase()
                                  .includes(searchSiteNameValue.toLowerCase())
                            : site.name,
                    )
                    .map((site) => (
                        <div key={site.id} className="p-0">
                            <Dropdown.Item
                                className={`Site_Link dropdown-item text-truncate px-2 rounded ${
                                    currentSite?.name === site.name
                                        ? "bg-primary rounded bg-opacity-25 fw-bold"
                                        : ""
                                }`}
                                href={routerPath.siteDetails({
                                    organisationId: site.organisationId,
                                    siteId: site.id,
                                })}
                            >
                                {site.name}
                            </Dropdown.Item>
                        </div>
                    ))}
                {props.sites?.filter((site) => site.id !== props.siteId)
                    .length ? (
                    <li>
                        <hr className="dropdown-divider text-light" />
                    </li>
                ) : null}
                <div className="p-0">
                    <Link
                        className="Site_Link dropdown-item px-2"
                        to={routerPath.sites}
                        // href={`${config.api.url}/auth/webflow`}
                    >
                        View all sites
                        {/* <FontAwesomeIcon
                            icon={faExternalLinkAlt}
                            className="me-2"
                        />
                        Manage sites in Webflow */}
                    </Link>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};
