import { useApolloClient } from "@apollo/client";
import { first } from "lodash";
import React from "react";
import { useHistory } from "react-router";

import { Navbar } from "../../components";
import { ErrorText } from "../../components/ErrorText";
import { localStorageKeys } from "../../config";
import {
    Feature,
    useCurrentUserQuery,
    useFeaturesQuery,
    useOrganisationQuery,
    useSitesQuery,
    useStripeCustomerPortalLinkLazyQuery,
} from "../../generated";

export interface NavbarContainerProperties {
    siteId?: string | null;
    triggerUpgradeModal?: () => void;
}

export const NavbarContainer: React.FunctionComponent<NavbarContainerProperties> =
    (props) => {
        const apolloClient = useApolloClient();
        const history = useHistory();
        const currentUserQueryResults = useCurrentUserQuery();
        const organisationId =
            localStorage.getItem(localStorageKeys.selectedOrganisationId) ||
            first(currentUserQueryResults.data?.me?.organisations)?.id ||
            "";

        const organisationQueryResults = useOrganisationQuery({
            variables: {
                organisationId,
            },
            skip: !organisationId || !props.siteId,
        });

        const sitesQueryResults = useSitesQuery({
            variables: {
                first: 5,
                organisationId,
            },
            skip: !organisationId,
        });

        const featuresQueryResults = useFeaturesQuery();

        const [getStripeCustomerPortalLink] =
            useStripeCustomerPortalLinkLazyQuery({
                fetchPolicy: "no-cache",
                onError(error) {
                    alert(error.message);
                },
                onCompleted(data) {
                    // eslint-disable-next-line security/detect-non-literal-fs-filename
                    window.open(data.stripeCustomerPortalLink, "_blank");
                },
            });

        const error =
            sitesQueryResults.error ||
            currentUserQueryResults.error ||
            featuresQueryResults.error ||
            organisationQueryResults.error;
        const loading =
            sitesQueryResults.loading ||
            currentUserQueryResults.loading ||
            featuresQueryResults.loading ||
            organisationQueryResults.loading;

        const data = {
            ...sitesQueryResults.data,
            ...currentUserQueryResults.data,
            ...featuresQueryResults.data,
            ...organisationQueryResults.data,
        };

        if (error) {
            return <ErrorText from="NavbarContainer" error={error} />;
        }

        const onLogout = async () => {
            await apolloClient.clearStore();
            localStorage.clear();
            history.push("/");
        };

        const isSubscriptionValid = data.organisation?.features.includes(
            Feature.OneThousandVisitors,
        );

        return (
            <Navbar
                user={data?.me?.user}
                features={data?.features}
                organisationId={organisationId}
                loading={loading}
                onLogout={onLogout}
                onBillingClick={() => {
                    getStripeCustomerPortalLink();
                }}
                triggerUpgradeModal={props.triggerUpgradeModal}
                isSubscriptionValid={isSubscriptionValid}
            />
        );
    };
