import React from "react";
import {
    Badge,
    FormLabel,
    FormSelect,
    FormSelectProps,
    InputGroup,
} from "react-bootstrap";

import { MetricType, useCmsCollectionsQuery } from "../../../generated/graphql";
import { Snippet } from "../../CodeSnippet";
import { InputWithLabel, InputWithLabelProps } from "../../InputWithLabel";

export interface MetricCollectionInputProps {
    metricType: MetricType;
    formControl: {
        webflowCollectionId: FormSelectProps;
        cssSelector: InputWithLabelProps["formControl"];
    };
    siteId: string;
    error?: string;
}

export const MetricCollectionInput: React.FunctionComponent<MetricCollectionInputProps> =
    (props) => {
        const isCmsMetricType = [
            MetricType.Cms,
            MetricType.CustomField,
        ].includes(props.metricType);
        const cmsCollectionsResults = useCmsCollectionsQuery({
            variables: { siteId: props.siteId },
            skip: !isCmsMetricType || !props.siteId,
        });
        if (!isCmsMetricType) {
            return null;
        }
        return (
            <>
                <div className="col-md mt-2">
                    <FormLabel htmlFor="collection-name" className="fw-bold">
                        Which collection is it?
                    </FormLabel>
                    <InputGroup className="input-group">
                        <FormSelect
                            id="collection-name"
                            placeholder="Choose a collection"
                            {...props.formControl.webflowCollectionId}
                        >
                            <option value="">Choose a collection</option>
                            {cmsCollectionsResults.data?.cmsCollections.map(
                                ({
                                    id,
                                    name,
                                }: {
                                    id: string;
                                    name: string;
                                }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ),
                            )}
                        </FormSelect>
                    </InputGroup>
                </div>

                {props.metricType === MetricType.Cms && (
                    <>
                        <p className="mt-3">
                            <span className="fw-bold ">
                                Tell us which collection list it is
                            </span>
                            <span className="text-muted ">(optional)</span>
                        </p>
                        <div className="d-flex align-items-baseline">
                            <Badge pill bg="secondary" className="me-2">
                                i
                            </Badge>{" "}
                            <div>
                                <p className="text-muted mb-0">
                                    To tell Nocodelytics exactly which
                                    collection you want to track, add the below
                                    class to the &quot;Collection List
                                    Wrapper&quot;. Then add the snippet to a
                                    HTML Embed inside a Collection Item.
                                </p>
                                <a
                                    href="/assets/cms-collection-tutorial.mp4"
                                    target="_blank"
                                >
                                    Show me
                                </a>
                            </div>
                        </div>
                        <div className="col-md mt-3 mb-2">
                            <InputWithLabel
                                formControl={props.formControl.cssSelector}
                                label="CSS Class"
                            />
                        </div>
                        <Snippet
                            code={
                                '<input type="hidden" class="nocodelytics-list-item" value="{{wf {&quot;path&quot;:&quot;slug&quot;,&quot;type&quot;:&quot;PlainText&quot;} }}" />'
                            }
                        />
                    </>
                )}
                {props.error && (
                    <div className="fw-bold text-danger mt-2">
                        {props.error}
                    </div>
                )}
            </>
        );
    };
