import {
    faCopy,
    faEdit,
    faEllipsisV,
    faTrash,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { routerPath, RouterURLParams } from "../../AppRouter";
import { Scalars } from "../../generated";
import { useDuplicateDashboardMutation } from "../../generated/graphql";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

const DropdownToggle = styled(Dropdown.Toggle)`
    &.dropdown-toggle::after {
        content: none !important;
    }
`;

export const DashboardListItem = styled.div`
    border-radius: 0.25rem !important;
    &:hover {
        background-color: rgba(
            var(--bs-primary-rgb),
            var(--bs-bg-opacity)
        ) !important;
        --bs-bg-opacity: 25% !important;
    }
    margin-bottom: 6px;
`;

interface DropdownOption {
    text: string;
    icon: IconDefinition;
    onClick: () => void;
    extraClass: string | null;
}

interface SidebarSiteDashboardsLinkProps {
    currentDashboard: {
        id: Scalars["String"];
        name: Scalars["String"];
    };
    setCurrentDashboard: (dashboardId: string) => void;
    setShowUpdate: (value: boolean) => void;
    setShowDelete: (value: boolean) => void;
}

export const SidebarSiteDashboardsLink: React.FunctionComponent<SidebarSiteDashboardsLinkProps> =
    (props) => {
        const { dashboardId } =
            useParams<RouterURLParams["dashboardDetails"]>();
        const [showMore, setShowMore] = useState<boolean>(false);
        const [showDropdown, setShowDropdown] = useState<boolean>(false);
        const history = useHistory();
        const [duplicateDashboard] = useDuplicateDashboardMutation({
            variables: { id: dashboardId },
            onCompleted: ({ duplicateDashboard: { id } }) => {
                history.push(routerPath.dashboardDetails({ dashboardId: id }));
            },
        });

        const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
        useOnClickOutside(ref, () => setShowDropdown(false));

        const DropdownOptions: DropdownOption[] = [
            {
                text: "Rename Dashboard",
                icon: faEdit,
                extraClass: null,
                onClick: () => props.setShowUpdate(true),
            },
            {
                text: "Duplicate Dashboard",
                icon: faCopy,
                extraClass: null,
                onClick: () => duplicateDashboard(),
            },
            {
                text: "Delete Dashboard",
                icon: faTrash,
                extraClass: "text-danger",
                onClick: () => props.setShowDelete(true),
            },
        ];

        return (
            <DashboardListItem
                className={`nav-item w-100 d-flex justify-content-between ${
                    props.currentDashboard.id === dashboardId
                        ? "bg-primary bg-opacity-50 rounded"
                        : ""
                }`}
                onMouseEnter={() => {
                    props.setCurrentDashboard(props.currentDashboard.id);
                    setShowMore(true);
                }}
                onMouseLeave={() => setShowMore(false)}
                onBlur={() => {
                    setShowDropdown(false);
                }}
                ref={ref}
            >
                <Link
                    to={routerPath.dashboardDetails({
                        dashboardId: props.currentDashboard.id,
                    })}
                    className={classNames([
                        "nav-link link-light text-truncate d-block w-100 pe-4",
                        {
                            "fw-bolder":
                                props.currentDashboard.id === dashboardId,
                        },
                    ])}
                    style={{
                        fontSize: 14,
                    }}
                >
                    {props.currentDashboard.name}
                </Link>
                <Dropdown
                    className="position-absolute"
                    style={{
                        right: 0,
                    }}
                    show={showDropdown}
                    onToggle={() => setShowDropdown(!showDropdown)}
                >
                    <DropdownToggle
                        variant="border-0 text-light"
                        style={{ width: 40, transform: "rotate(90deg)" }}
                        className={`Dashboards_Dropdown_Toggle btn fw-bold me-3 ${
                            showMore ? "d-block" : "d-none"
                        }`}
                        aria-label="dashboards-menu"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onBlur={() => setShowMore(false)}
                        id={`dropdown-${props.currentDashboard.id}`}
                    >
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </DropdownToggle>
                    <Dropdown.Menu
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dashboards-menu"
                        onBlur={() => setShowMore(false)}
                        show={showDropdown}
                    >
                        {DropdownOptions.map(
                            (option: DropdownOption, i: number) => (
                                <div key={i}>
                                    <Dropdown.Item
                                        onClick={option.onClick}
                                        className={`dropdown-item ${
                                            option.extraClass
                                                ? `${option.extraClass}`
                                                : ""
                                        }`}
                                    >
                                        <FontAwesomeIcon
                                            className={`me-3`}
                                            icon={option.icon}
                                        />
                                        <span
                                            className={`More_Dropdown_Item ${
                                                option.icon === faEdit ? -2 : ""
                                            }`}
                                            style={{
                                                marginLeft:
                                                    option.icon === faEdit
                                                        ? -2
                                                        : undefined,
                                            }}
                                        >
                                            {option.text}
                                        </span>
                                    </Dropdown.Item>
                                </div>
                            ),
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </DashboardListItem>
        );
    };
