import classNames from "classnames";
import { random } from "lodash";
import React, { useEffect, useState } from "react";
import { useInterval, useTimeout } from "react-use";

const funLoadingText = [
    "Optimising for IE6...",
    "Re-starting Raspberry Pi...",
    "Downloading more RAM...",
    "Generating anti-matter...",
    "Swapping time and space...",
    "Playing elevator music...",
    "We challenge you to hold your breath!",
    "Testing your patience...",
    "I swear it’s almost done...",
    "Baking chocolate chip cookies...",
    "Finding bug spray...",
    "Renaming Div 243...",
    "Unlinking Component...",
    "Generating silly loading text...",
    "So...do you come here often?",
    "Trying to learn SEO for the 34th time...",
    "Cleaning up unused images...",
    "Messing around with interactions...",
];

interface LoadingProperties
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    color?: "primary" | "light" | "inherit";
    timeout?: number;
    makeItFun?: boolean;
}

export const Loading: React.FunctionComponent<LoadingProperties> = (props) => {
    const [randomIndex, setRandomIndex] = useState<number>(0);

    useEffect(() => {
        setRandomIndex(random(0, funLoadingText.length));
    }, []);
    useInterval(() => {
        if (props.makeItFun) {
            setRandomIndex(random(0, funLoadingText.length));
        }
    }, 2500);

    const [show] = useTimeout(props.timeout || !props.makeItFun ? 0 : 1000);
    if (!show()) return null;

    return (
        <div className="d-flex justify-content-center align-items-center flex-column h-100">
            <div
                style={{ width: 20, height: 20 }}
                className={classNames([
                    "spinner-border",
                    { [`text-${props.color}`]: props.color !== "inherit" },
                    props.className ? props.className : "",
                ])}
                role="status"
            >
                <span className="visually-hidden">Loading...</span>
            </div>
            {props.makeItFun ? (
                <p id="loadingText" className="mt-3">
                    {funLoadingText[randomIndex]}
                </p>
            ) : null}
        </div>
    );
};
