import {
    faCaretSquareDown,
    faCaretSquareUp,
    faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import * as dateFns from "date-fns";
import { sumBy } from "lodash";
import * as queryString from "query-string";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useResizeDetector } from "react-resize-detector";
import { useLocation, useParams } from "react-router";

import { routerPath, RouterURLParams } from "../../AppRouter";
import {
    ListChart,
    Loading,
    TimeserieChart,
    TopCountriesChart,
} from "../../components";
import { MetricChartEmptyState } from "../../components/Metrics/MetricChart/MetricChartEmptyState/MetricChartEmptyState";
import {
    ChartDataInput,
    ChartDataQuery,
    ChartDataV2Query,
    MetricChartBreakdownBy,
    MetricChartInput,
    MetricsBySiteIdDocument,
    MetricsBySiteIdQuery,
    MetricTimeframe,
    MetricType,
    useChartDataV2Query,
    useDeleteMetricMutation,
} from "../../generated";
import { timeframeToStartAt } from "../../helpers/timeframeToStartAt";
import { ActionsButton } from "./ActionsButton";

export interface ChartContainerProps extends MetricChartInput {
    organisationId: string;
    metric?: { id: string; name: string; metricType: MetricType };
    isEditable?: boolean;
    siteId: string;
    containerProps?: React.HTMLProps<HTMLDivElement>;
}

const ChartLabel: React.FunctionComponent<{
    metric: {
        metricType: MetricType;
        breakdownBy?: MetricChartBreakdownBy | null;
    };
    data: ChartDataV2Query["chartDataV2"];
    delta: number | null;
    deltaDateRange: ChartDataInput["dateRange"] | null;
    previousPeriodTotal: number;
}> = (props) => {
    return (
        <div className="d-flex">
            <p className="text-truncate">
                <span className="fw-bold h4">
                    {sumBy(props.data, "value").toLocaleString()}
                </span>
                <span className="small text-muted me-1">
                    {props.metric.metricType === MetricType.Form && name}
                </span>
            </p>

            {props.delta ? (
                <OverlayTrigger
                    placement="top"
                    overlay={(overlayProps) => (
                        <Tooltip {...overlayProps}>
                            {props.deltaDateRange?.from.toLocaleDateString()} -{" "}
                            {props.deltaDateRange?.to.toLocaleDateString()} (
                            {props.previousPeriodTotal})
                        </Tooltip>
                    )}
                >
                    <span
                        className={classNames([
                            "rounded text-nowrap small mt-2 ms-2",
                            {
                                "text-success": props.delta >= 0,
                                "text-danger": props.delta < 0,
                            },
                        ])}
                    >
                        <FontAwesomeIcon
                            className="me-1"
                            icon={
                                props.delta >= 0
                                    ? faCaretSquareUp
                                    : faCaretSquareDown
                            }
                        />
                        {Math.ceil(Math.abs(props.delta)).toLocaleString()}%
                    </span>
                </OverlayTrigger>
            ) : null}
        </div>
    );
};

const BreakdownByChart: React.FunctionComponent<{
    breakdownBy: MetricsBySiteIdQuery["metricsBySiteId"][0]["breakdownBy"];
    metricType: MetricsBySiteIdQuery["metricsBySiteId"][0]["metricType"];
    data: ChartDataQuery["chartData"]["data"];
}> = ({ breakdownBy, data, metricType }) => {
    if (!data || data?.length === 0) {
        return null;
    }
    const { values } = data[0];
    if (
        (breakdownBy && breakdownBy !== MetricChartBreakdownBy.None) ||
        [
            MetricType.TopCountries,
            MetricType.TopPages,
            MetricType.TopReferers,
            MetricType.CustomField,
        ].includes(metricType)
    ) {
        if (
            breakdownBy === MetricChartBreakdownBy.Country ||
            metricType === MetricType.TopCountries
        ) {
            return <TopCountriesChart values={values} />;
        }
        return <ListChart values={values || []} />;
    }
    return null;
};

export const ChartContainer: React.FunctionComponent<ChartContainerProps> = (
    props,
) => {
    const [dateRange, setDateRange] = useState<{ from: Date; to: Date } | null>(
        null,
    );
    const { dashboardId } = useParams<RouterURLParams["dashboardDetails"]>();
    const { height: chartContainerHeight, ref: chartContainerRef } =
        useResizeDetector();

    const { height: headerHeight, ref: headerRef } = useResizeDetector();
    const location = useLocation();
    const extendedProps = {
        ...props.metric,
        ...props,
        ...queryString.parse(location.search),
    };
    const {
        metric,
        siteId,
        startAt: _startAt,
        endAt: _endAt,
        timeframe,
        webflowCollectionId,
        cssSelector,
        emailAddress,
        breakdownBy,
        memberId,
        uniqueVisitor,
    } = extendedProps;

    const isMember =
        (typeof extendedProps.isMember === "boolean" &&
            extendedProps.isMember) ||
        (typeof extendedProps.isMember === "string" &&
            (extendedProps.isMember === "true" ||
                extendedProps.isMember === "1"));
    const metricType =
        props.metric?.metricType || props.metricType! || MetricType.View;
    if (!metricType) {
        throw new Error("metricType is required");
    }

    useEffect(() => {
        setDateRange({
            from: dateFns.startOfDay(
                _startAt
                    ? new Date(_startAt)
                    : (timeframeToStartAt(
                          timeframe || MetricTimeframe.Last30days,
                      ) as any),
            ),
            to: dateFns.endOfDay(_endAt ? new Date(_endAt) : new Date()),
        });
    }, [_startAt, _endAt, timeframe]);
    // const chartDataQueryResults = useChartDataQuery({
    //     variables: {
    //         input: {
    //             timeframe,
    //             metricId: metric?.id,
    //             siteId,
    //             metricType,
    //             webflowCollectionId,
    //             cssSelector,
    //             country,
    //             domain,
    //             emailAddress,
    //             ipAddress,
    //             isMember,
    //             path,
    //             referer,
    //             value,
    //             breakdownBy,
    //             startAt,
    //             endAt,
    //             memberId,
    //             uniqueVisitor,
    //         },
    //     },
    //     skip: !onScreen,
    // });

    const filters = [];
    if (webflowCollectionId) {
        filters.push({
            column: "cms_webflow_collection_id",
            value: webflowCollectionId,
        });
    }
    if (cssSelector) {
        if (cssSelector.startsWith("#")) {
            filters.push({
                column: "css_id",
                value: `*${cssSelector.slice(1)}*`,
            });
        } else if (cssSelector.startsWith(".")) {
            filters.push({
                column: "css_class",
                value: `*${cssSelector.slice(1)}*`,
            });
        } else {
            filters.push({
                column: "css_class",
                value: cssSelector,
            });
        }
    }
    for (const key of ["country", "domain", "path", "referer", "value"]) {
        if ((extendedProps as any)[key]) {
            filters.push({
                column: key,
                value: (extendedProps as any)[key],
            });
        }
    }
    const chartDataV2Results = useChartDataV2Query({
        variables: {
            input: {
                eventType:
                    metricType === MetricType.Visitors
                        ? MetricType.View
                        : metricType,
                dateRange: dateRange as any,
                siteId,
                filters,
                uniqueVisitor:
                    metricType === MetricType.Visitors ? true : uniqueVisitor,
                groupBy: breakdownBy,
            },
        },
        skip: !dateRange,
    });

    const isTimeseries =
        ![
            MetricType.TopCountries,
            MetricType.TopPages,
            MetricType.TopReferers,
            MetricType.CustomField,
        ].includes(metricType) &&
        (!breakdownBy || breakdownBy === MetricChartBreakdownBy.None);

    const periodDistance = dateRange
        ? dateFns.eachDayOfInterval({
              start: dateRange.from,
              end: dateRange.to,
          }).length
        : 0;
    const deltaDateRange = dateRange
        ? {
              from: dateFns.subDays(
                  dateFns.startOfDay(dateRange.from),
                  periodDistance - 1,
              ),
              to: dateFns.endOfDay(dateFns.subDays(dateRange.from, 1)),
          }
        : null;
    const chartDataV2PreviousTimeframeResults = useChartDataV2Query({
        variables: {
            input: {
                eventType:
                    metricType === MetricType.Visitors
                        ? MetricType.View
                        : metricType,
                dateRange: deltaDateRange || {
                    from: new Date(),
                    to: new Date(),
                },
                siteId,
                filters,
                uniqueVisitor:
                    metricType === MetricType.Visitors ? true : uniqueVisitor,
                groupBy: breakdownBy,
            },
        },
        skip: !deltaDateRange,
    });

    const data = {
        ...chartDataV2Results.data,
    };
    const loading = chartDataV2Results.loading;

    const [deleteMetric] = useDeleteMetricMutation({
        variables: { metricId: metric?.id || "" },
        refetchQueries: [MetricsBySiteIdDocument],
        onCompleted: () => {
            window.location.replace(
                routerPath.dashboardDetails({ dashboardId }),
            );
        },
    });

    const handleDeleteMetric = () => {
        if (window.confirm(`Delete the metric "${metric?.name}"?`)) {
            deleteMetric();
        }
    };

    if (chartDataV2Results.error) {
        return <div>{chartDataV2Results.error}</div>;
    }
    if (!dateRange) {
        return <div>Error: Timeframe or start and end date required</div>;
    }

    const hasData = sumBy(data.chartDataV2, "value");
    const metricName = props.metric?.name || "Preview";
    const previousPeriodTotal = sumBy(
        chartDataV2PreviousTimeframeResults.data?.chartDataV2,
        "value",
    );
    const currentPeriodTotal = sumBy(data.chartDataV2, "value");
    return (
        <div
            {...props.containerProps}
            ref={chartContainerRef}
            className={classNames([
                `h-100 w-100`,
                "ChartContainer",
                props.containerProps?.className,
            ])}
        >
            {props.isEditable && (
                <div className="position-absolute align-middle w-100 text-center top-50">
                    <FontAwesomeIcon
                        className="me-2"
                        icon={faExpandArrowsAlt}
                    />
                    Drag to reorder or resize
                </div>
            )}
            <div ref={headerRef} className="d-flex justify-content-between ">
                <div className="overflow-hidden">
                    <div className="d-flex">
                        <p className="text-truncate fs-6 text-muted fw-bold mb-0">
                            <OverlayTrigger
                                placement="top"
                                overlay={(props) => (
                                    <Tooltip {...props}>{metricName}</Tooltip>
                                )}
                            >
                                <span>{metricName}</span>
                            </OverlayTrigger>
                        </p>
                    </div>
                    {isTimeseries ? (
                        <ChartLabel
                            metric={{ metricType, breakdownBy }}
                            data={data.chartDataV2 || []}
                            delta={
                                data.chartDataV2 &&
                                chartDataV2PreviousTimeframeResults.data
                                    ?.chartDataV2
                                    ? ((currentPeriodTotal -
                                          previousPeriodTotal) /
                                          previousPeriodTotal) *
                                      100
                                    : null
                            }
                            deltaDateRange={deltaDateRange}
                            previousPeriodTotal={previousPeriodTotal}
                        />
                    ) : null}
                </div>
                <ActionsButton
                    {...extendedProps}
                    handleDeleteMetric={handleDeleteMetric}
                />
            </div>
            {loading ? <Loading /> : null}

            <div
                className={classNames({ "opacity-25": props.isEditable })}
                style={{
                    height: (chartContainerHeight || 0) - (headerHeight || 0),
                }}
            >
                {!hasData && !loading ? <MetricChartEmptyState /> : null}
                {hasData ? (
                    <BreakdownByChart
                        breakdownBy={breakdownBy}
                        metricType={metricType}
                        data={[
                            {
                                name: metricName,
                                values: (data.chartDataV2 || []).map(
                                    ({ label, value }) => ({
                                        label,
                                        value,
                                    }),
                                ),
                            },
                        ]}
                    />
                ) : null}
                {hasData && isTimeseries ? (
                    <TimeserieChart
                        data={[
                            {
                                name: metricName,
                                values:
                                    data.chartDataV2?.map(
                                        ({ label, value }) => {
                                            try {
                                                return {
                                                    label: new Date(
                                                        label,
                                                    ).toISOString(),
                                                    value,
                                                };
                                            } catch {
                                                return {
                                                    label: "Error",
                                                    value: 0,
                                                };
                                            }
                                        },
                                    ) || [],
                            },
                        ]}
                    />
                ) : null}
            </div>
        </div>
    );
};
