import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import {
    Dashboard,
    UpdateDashboardMutationOptions,
} from "../../../../generated";

interface RenameDashboardProps {
    showUpdate: boolean;
    handleClose: () => void;
    dashboardId: Dashboard["id"];
    updateDashboard: (vars: UpdateDashboardMutationOptions) => void;
}

export const RenameDashboardModal: React.FunctionComponent<RenameDashboardProps> =
    (props) => {
        const [name, setName] = React.useState<string>("");

        const handleUpdate = () => {
            props.updateDashboard({
                variables: { input: { id: props.dashboardId, name } },
            });
            props.handleClose();
        };

        return (
            <Modal show={props.showUpdate} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Rename Dashboard</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control
                            type="text"
                            value={name}
                            placeholder="New Dashboard Name"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUpdate}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
