import classNames from "classnames";
import React from "react";
import { Badge } from "react-bootstrap";

import { config } from "../../../config";
import { MetricType, Site } from "../../../generated/graphql";
import { InputWithLabel, InputWithLabelProps } from "../../InputWithLabel";

export interface MetricCssSelectorInputProps
    extends Omit<InputWithLabelProps, "label"> {
    metricType: MetricType;
    site: Pick<Site, "name">;
    loading?: boolean;
    siteHtmlElements: Array<{ id: string; text: string }>;
    setValue: (value: string) => void;
    value: string;
}

const labels: { [key: string]: string } = {
    [MetricType.Keyboard]: `What's the ID or class of the search box?`,
    [MetricType.Click]: `What's the ID or class of the button/link?`,
    [MetricType.Form]: `What's the ID of the form (class not supported)?`,
};

export const MetricCssSelectorInput: React.FunctionComponent<MetricCssSelectorInputProps> =
    (props) => {
        const { value, setValue } = props;
        const [showList, setShowList] = React.useState(false);
        if (
            ![MetricType.Click, MetricType.Keyboard, MetricType.Form].includes(
                props.metricType,
            )
        ) {
            return null;
        }

        const idsFound = props.siteHtmlElements.filter((htmlElement) =>
            value
                ? [htmlElement.id, htmlElement.text]
                      .join("")
                      .toLowerCase()
                      .includes(value.toLowerCase().replace("#", ""))
                : true,
        );

        const isValidCssSelector = !(
            value.length > 0 && !["#", "."].includes(value[0])
        );

        return (
            <>
                <InputWithLabel
                    label={
                        labels[props.metricType] || `What's the ID or class?`
                    }
                    formControl={{
                        ...props.formControl,
                        // @ts-ignore
                        autoComplete: "off",
                        value: value || props.formControl.value,
                        onClick: () => setShowList(true),
                        onChange: (event) => {
                            setValue(event.currentTarget.value);
                            setShowList(event.currentTarget.value.length > 0);
                            if (props.formControl.onChange) {
                                return props.formControl.onChange(event);
                            }
                        },
                        placeholder: "#my-id",
                    }}
                />
                <div className="d-flex align-items-baseline mt-3">
                    <Badge
                        pill
                        bg={isValidCssSelector ? "secondary" : "danger"}
                        className="me-2"
                    >
                        i
                    </Badge>{" "}
                    <p
                        className={classNames([
                            "mb-0",
                            {
                                "text-muted": isValidCssSelector,
                                "text-danger": !isValidCssSelector,
                                "fw-bolder": !isValidCssSelector,
                            },
                        ])}
                    >
                        Make sure to include the # (for an id) or . for a class.
                    </p>
                </div>
                {showList &&
                value.length > 0 &&
                !props.loading &&
                value[0] !== "." ? (
                    <div className="mt-3">
                        {idsFound?.length !== 0 ? (
                            <p className="fw-bold">
                                IDs found on {props.site.name}
                            </p>
                        ) : (
                            <>
                                <p>
                                    No IDs found based on your search (as we
                                    only include the home page). Don&apos;t
                                    worry though, you can still add your ID and
                                    save your metric.
                                </p>
                                <a
                                    href={`mailto:${config.email.supportEmail}?subject=I have feedback`}
                                >
                                    Send feedback
                                </a>
                            </>
                        )}

                        <ul
                            className="list-group mt-3 shadow-sm overflow-scroll"
                            style={{ maxHeight: 200 }}
                        >
                            {idsFound?.map((htmlElment) => (
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setValue(`#${htmlElment.id}`);
                                        setShowList(false);
                                    }}
                                    className="list-group-item border-bottom-0 list-group-item-action"
                                    key={htmlElment.id}
                                >
                                    <div className="row">
                                        <div className="me-auto col-md-auto">
                                            #{htmlElment.id}
                                        </div>
                                        <div className="text-muted col text-truncate">
                                            {htmlElment.text}
                                        </div>
                                    </div>
                                </button>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </>
        );
    };
