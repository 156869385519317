import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import { routerPath, RouterURLParams } from "../../AppRouter";
import { MainLayout } from "../../Layout";
import {
    DashboardFieldsFragmentDoc,
    useCreateDashboardMutation,
} from "../../generated/graphql";

interface DashboardFormData {
    name: string;
}

export const NewDashboardPage: React.FunctionComponent = () => {
    const formControl = useForm<DashboardFormData>();
    const history = useHistory();
    const { siteId } = useParams<RouterURLParams["newDashboard"]>();
    const [createDashboardMutation] = useCreateDashboardMutation({
        update: (cache, { data }) => {
            return cache.modify({
                fields: {
                    dashboardBySiteId(existingDashboards = []) {
                        const newDashboardRef = cache.writeFragment({
                            data: data?.createDashboard,
                            fragment: DashboardFieldsFragmentDoc,
                        });
                        return [...existingDashboards, newDashboardRef];
                    },
                },
            });
        },
        onCompleted: (data) => {
            history.push(
                routerPath.dashboardDetails({
                    dashboardId: data.createDashboard.id,
                }),
            );
        },
    });
    const handleSubmit = formControl.handleSubmit(({ name }) => {
        createDashboardMutation({ variables: { input: { name, siteId } } });
    });

    return (
        <MainLayout siteId={siteId}>
            <Modal show centered onHide={() => history.goBack()}>
                <Modal.Header closeButton>
                    <Modal.Title>New dashboard</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Dashboard Name"
                                {...formControl.register("name")}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => history.goBack()} variant="danger">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="primary">
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </MainLayout>
    );
};
