import classnames from "classnames";
import React from "react";

import { ChartContainer, ChartContainerProps } from "../../../containers/Chart";
import { MetricTimeframe, MetricType } from "../../../generated/graphql";
import { Maybe } from "../../../helpers";

interface PreviewChartProps {
    metricName: string;
    metricType: MetricType;
    organisationId: string;
    siteId: string;
    cssSelector?: Maybe<string>;
    path?: Maybe<string>;
    webflowCollectionId?: Maybe<string>;
    timeframe: MetricTimeframe;
    startAt: string;
    endAt: string;
    containerProps?: ChartContainerProps["containerProps"];
}

export const PreviewChart: React.FunctionComponent<PreviewChartProps> = (
    props,
) => {
    return (
        <div
            className="h-100 mt-3 border rounded-3 shadow-sm p-3"
            style={{ maxHeight: 300, position: "relative" }}
        >
            <ChartContainer
                {...props}
                containerProps={{
                    ...props.containerProps,
                    className: classnames([
                        "PreviewChart",
                        props.containerProps?.className,
                    ]),
                }}
                isEditable={false}
            />
        </div>
    );
};
