import React from "react";
import { Redirect, useParams } from "react-router-dom";

import { routerPath, RouterURLParams } from "../../AppRouter";
import { ErrorText, Loading } from "../../components";
import { useDashboardBySiteIdQuery } from "../../generated";

export const EmbeddedMetricsPageRedirect: React.FunctionComponent = () => {
    const { siteId } = useParams<RouterURLParams["siteRedirect"]>();
    const dashboardBySiteId = useDashboardBySiteIdQuery({
        variables: { siteId },
    });

    const loading = dashboardBySiteId.loading;
    const error = dashboardBySiteId.error;
    const data = { ...dashboardBySiteId.data };

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <ErrorText from="EmbeddedMetricsPageRedirect" error={error} />;
    }
    if (!data.dashboardBySiteId?.length) {
        return (
            <div>
                Oh oh... This site does not have any dashboards. Create one
                first.
            </div>
        );
    }
    return (
        <Redirect
            to={routerPath.embeddedMetrics({
                dashboardId: data.dashboardBySiteId[0].id,
            })}
        />
    );
};
