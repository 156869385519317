import { first } from "lodash";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { Loading } from "../components";
import { ErrorText } from "../components/ErrorText";
import { localStorageKeys } from "../config";
import { useCurrentUserQuery, useRenewTokenMutation } from "../generated";
import { routerPath } from "./routerPath";

const RedirectToSignIn: React.FunctionComponent<{ from: string }> = ({
    from,
}) => (
    <Redirect
        to={{
            pathname: routerPath.signIn,
            state: { from },
        }}
    />
);

export const PrivateRoute: React.FunctionComponent<RouteProps> = ({
    children,
    ...rest
}) => {
    const [renewTokenMutation, renewTokenMutationResults] =
        useRenewTokenMutation({
            onCompleted: async (data) => {
                localStorage.setItem(localStorageKeys.token, data.renewToken);
            },
        });
    const meQueryResults = useCurrentUserQuery({
        onCompleted: (data) => {
            if (!data.me?.user.id) {
                return;
            }
            if (!renewTokenMutationResults.called) {
                renewTokenMutation();
            }
            const selectedOrganisationId = first(data.me.organisations)?.id;
            if (selectedOrganisationId) {
                localStorage.setItem(
                    localStorageKeys.selectedOrganisationId,
                    selectedOrganisationId,
                );
            }
        },
    });

    const me = meQueryResults.data?.me?.user;

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (meQueryResults.error) {
                    if (
                        meQueryResults.error?.graphQLErrors.some(
                            ({ extensions }) =>
                                extensions?.code === "NOT_FOUND",
                        )
                    ) {
                        return <RedirectToSignIn from={location.pathname} />;
                    }
                    return (
                        <ErrorText
                            error={meQueryResults.error}
                            from="PrivateRoute"
                        />
                    );
                }
                if (meQueryResults.loading) {
                    return (
                        <div className="Loading_Wrapper">
                            <Loading makeItFun />
                        </div>
                    );
                }
                if (me) {
                    return children;
                }
                if (meQueryResults.called && !me) {
                    return <RedirectToSignIn from={location.pathname} />;
                }
            }}
        />
    );
};
