import React from "react";
import { Col, Row } from "react-bootstrap";

import { Loading } from "../../Loading";
import { SiteCard, SiteCardProps } from "../SiteCard";

export interface SiteListProps {
    sites: Array<SiteCardProps["site"]>;
    hasNextPage?: boolean;
    loading?: boolean;
    onEnableClick: (params: { siteId: string }) => void;
}

export const SitesList: React.FunctionComponent<SiteListProps> = (props) => {
    const { sites, loading } = props;

    if (loading) {
        return <Loading makeItFun />;
    }

    return (
        <>
            <Row xs={1} md={2} lg={3} className="g-4">
                {sites.map((site) => (
                    <Col key={site.id}>
                        <SiteCard
                            site={site}
                            onEnableClick={() =>
                                props.onEnableClick({ siteId: site.id })
                            }
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};
