import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
    DashboardDetailsPage,
    DashboardRedirectPage,
    EditMetricPage,
    EmbeddedMetricsPage,
    EmbeddedMetricsPageRedirect,
    GdprPage,
    LiveFeedPage,
    NewDashboardPage,
    NewMetricPage,
    NewMetricV2Page,
    NotificationsPage,
    SignInCallbackPage,
    SignInPage,
    SignUpCallbackPage,
    SignUpPage,
    SiteEnablePage,
    SiteListRedirectPage,
    SiteRedirect,
    SiteSettingsPage,
    SitesPage,
    VisitorDetailsPage,
    VisitorsPage,
    WebflowIFrameAuth,
} from "../pages";
import { PrivateRoute } from "./PrivateRoute";
import { routerPath } from "./routerPath";

const organisationId = ":organisationId";
const siteId = ":siteId";
const dashboardId = ":dashboardId";
const metricId = ":metricId";
const visitorId = ":visitorId";

export const Routes: React.FunctionComponent = () => {
    return (
        <Switch>
            {/* Sign up with Webflow */}
            <Route path={routerPath.signUp} exact>
                <SignUpPage />
            </Route>
            <Route
                path={routerPath.authCallback({ provider: "webflow" })}
                component={SignUpCallbackPage}
            />
            {/* Sign in */}
            <Route path={routerPath.signIn} exact>
                <SignInPage />
            </Route>
            <Route path={routerPath.signInCallback} exact>
                <SignInCallbackPage />
            </Route>
            <Route path={routerPath.notifications()}>
                <NotificationsPage />
            </Route>
            <Route
                path={[
                    routerPath.embeddedMetricsRedirect({
                        siteId,
                    }),
                    routerPath.embeddedSiteDashboardRedirect({
                        organisationId,
                        siteId,
                    }),
                ]}
            >
                <EmbeddedMetricsPageRedirect />
            </Route>
            <Route
                path={routerPath.embeddedMetrics({
                    dashboardId,
                })}
            >
                <EmbeddedMetricsPage />
            </Route>
            {/* Sites */}
            <PrivateRoute
                path={`${routerPath.siteRedirect({
                    siteId,
                })}`}
                exact
            >
                <SiteRedirect />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.sitesList({
                    organisationId,
                })}
                exact
            >
                <SitesPage />
            </PrivateRoute>
            <PrivateRoute path={routerPath.sites} exact>
                <SiteListRedirectPage />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.siteEnable({
                    organisationId,
                    siteId,
                })}
                exact
            >
                <SiteEnablePage />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.siteDetails({
                    organisationId,
                    siteId,
                })}
                exact
            >
                <DashboardRedirectPage />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.dashboardDetails({
                    dashboardId,
                })}
                exact
            >
                <DashboardDetailsPage />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.newDashboard({
                    siteId,
                })}
                exact
            >
                <NewDashboardPage />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.newMetric({
                    dashboardId,
                })}
                exact
            >
                <NewMetricPage />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.newMetricV2({
                    dashboardId,
                })}
                exact
            >
                <NewMetricV2Page />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.editMetric({
                    organisationId,
                    siteId,
                    metricId,
                })}
                exact
            >
                <EditMetricPage />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.siteVisitors({
                    siteId,
                })}
                exact
            >
                <VisitorsPage />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.siteVisitorDetails({
                    siteId,
                    visitorId,
                })}
                exact
            >
                <VisitorDetailsPage />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.siteSettings({
                    organisationId,
                    siteId,
                })}
                exact
            >
                <SiteSettingsPage />
            </PrivateRoute>
            <PrivateRoute
                path={routerPath.liveFeed({
                    organisationId,
                    siteId,
                })}
                exact
            >
                <LiveFeedPage />
            </PrivateRoute>
            <PrivateRoute path={routerPath.gdpr} exact>
                <GdprPage />
            </PrivateRoute>
            <Route path={routerPath.webflowExtensionAuthIFrame()}>
                <WebflowIFrameAuth />
            </Route>
            <Route path="*">
                <Redirect to="/sites" />
            </Route>
        </Switch>
    );
};
