import React, { useEffect, useMemo } from "react";
import { Redirect } from "react-router-dom";

import { routerPath } from "../../AppRouter";
import { ErrorText, Loading } from "../../components";
import { useCurrentUserQuery } from "../../generated";
import { useLogout } from "../../helpers";

export const SiteListRedirectPage: React.FunctionComponent = () => {
    const currentUserQuery = useCurrentUserQuery();
    const { logout } = useLogout();

    const organisations = useMemo(
        () => currentUserQuery.data?.me?.organisations || [],
        [currentUserQuery.data?.me?.organisations],
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            if (organisations.length === 0) {
                logout();
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [organisations, logout]);

    if (currentUserQuery.loading) {
        return <Loading makeItFun />;
    }

    if (organisations.length === 0) {
        return (
            <ErrorText
                from="SiteListRedirectPage"
                error="Could not load current user organisations"
            />
        );
    }

    return (
        <Redirect
            to={routerPath.sitesList({ organisationId: organisations[0].id })}
        />
    );
};
