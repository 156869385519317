import { InMemoryCache, InMemoryCacheConfig } from "@apollo/client";

import { mergeResults } from "./mergeResults";

export const createCache = (config?: InMemoryCacheConfig): InMemoryCache =>
    new InMemoryCache({
        ...config,
        typePolicies: {
            Query: {
                fields: {
                    sites: {
                        keyArgs: false,
                        merge: mergeResults,
                    },
                    users: {
                        keyArgs: false,
                        merge: mergeResults,
                    },
                    visitors: {
                        keyArgs: ["siteId", "startAt"],
                        merge: mergeResults,
                    },
                    metrics: {
                        keyArgs: false,
                        merge: mergeResults,
                    },
                    me: {
                        keyArgs: false,
                        merge: (existing, incoming) => ({
                            ...existing,
                            ...incoming,
                        }),
                    },
                },
            },
        },
    });
