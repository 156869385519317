import styled from "styled-components";

export const StyledH4 = styled.h4``;

export const StyledH5 = styled.h5``;

export const StyledP = styled.p``;

export const Divider = styled.hr`
    border-top: 3px solid #bbb;
`;
