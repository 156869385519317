import React, { useState } from "react";
import { Redirect, useHistory, useParams } from "react-router";

import { routerPath, RouterURLParams } from "../../AppRouter";
import { MainLayout } from "../../Layout";
import { ErrorText } from "../../components";
import { MetricListContainer } from "../../containers";
import {
    DashboardBySiteIdDocument,
    useDashboardByIdQuery,
    useDeleteDashboardMutation,
    useSiteByIdLazyQuery,
} from "../../generated";

export const DashboardDetailsPage: React.FunctionComponent = () => {
    const history = useHistory();

    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const { dashboardId } = useParams<RouterURLParams["dashboardDetails"]>();

    const [getSiteByIdQuery, siteByIdQueryResults] = useSiteByIdLazyQuery();
    const dashboardByIdQueryResults = useDashboardByIdQuery({
        variables: { dashboardId },
        onCompleted: ({ dashboard: { siteId } }) => {
            getSiteByIdQuery({ variables: { id: siteId } });
        },
    });

    const { siteId, organisationId } = dashboardByIdQueryResults.data
        ?.dashboard || { siteId: "", organisationId: "" };

    const error = siteByIdQueryResults.error || dashboardByIdQueryResults.error;

    const loading =
        siteByIdQueryResults.loading || dashboardByIdQueryResults.loading;

    const data = {
        ...siteByIdQueryResults.data,
        ...dashboardByIdQueryResults.data,
    };

    const [deleteDashboard] = useDeleteDashboardMutation({
        variables: { id: dashboardId },
        refetchQueries: [DashboardBySiteIdDocument],
        onCompleted() {
            history.push(routerPath.siteDetails({ organisationId, siteId }));
        },
    });

    const handleDeleteDashboard = (dashboardId: string) => {
        deleteDashboard({
            variables: {
                id: dashboardId,
            },
        });
    };

    if (error) {
        return <ErrorText error={error} from="SiteDetailsPage" />;
    }

    if (
        !siteByIdQueryResults.loading &&
        !data?.siteById &&
        siteByIdQueryResults.called
    ) {
        return !loading ? (
            <ErrorText
                error={`Site ${siteId} not found`}
                from="SiteDetailsPage"
            />
        ) : (
            <div />
        );
    }

    if (data.siteById?.isEnabled === false) {
        return (
            <Redirect
                to={routerPath.siteEnable({
                    organisationId,
                    siteId,
                })}
            />
        );
    }

    return (
        <MainLayout
            siteId={siteId}
            noBackground
            loading={loading}
            showUpgradeModal={showUpgradeModal}
            triggerUpgradeModal={() => setShowUpgradeModal(true)}
            onUpgradeModalClose={() => setShowUpgradeModal(false)}
        >
            <MetricListContainer
                handleDeleteDashboard={handleDeleteDashboard}
                siteId={siteId}
                isPublic={data.dashboard?.isPublic}
                dashboardId={dashboardId}
                organisationId={organisationId}
                triggerUpgradeModal={() => setShowUpgradeModal(true)}
                showShare={showShare}
                setShowShare={setShowShare}
            />
        </MainLayout>
    );
};
