import React from "react";
import { Badge, Button, Card, Placeholder } from "react-bootstrap";
import { Img } from "react-image";
import { Link } from "react-router-dom";

import { routerPath } from "../../AppRouter";
import underConstruction from "../../assets/images/under-construction.svg";
import { Site } from "../../generated";
import { Maybe } from "../../helpers";
import { Loading } from "../Loading";

export interface SiteCardProps {
    site: Pick<Site, "id" | "name" | "isEnabled" | "organisationId"> & {
        previewUrl?: Maybe<string>;
    };
    onEnableClick: () => void;
}

export const SiteLoadingCard: React.FC = () => (
    <Card className="SiteLoadingCard">
        <div className="Site_Loading_Wrapper">
            <Loading />
        </div>
        <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                <Placeholder as={Badge} />
            </Placeholder>
            <Placeholder.Button variant="primary" xs={12} />
        </Card.Body>
    </Card>
);

export const SiteCard: React.FC<SiteCardProps> = ({ site, onEnableClick }) => {
    const { id, name, isEnabled, previewUrl } = site;

    return (
        <Card className="Site_Card" border={isEnabled ? "primary" : "warning"}>
            <Img
                className="Site_Image"
                src={previewUrl ? previewUrl : underConstruction}
            />
            <Card.Body>
                <Card.Title className="Site_Card_Title">
                    {name}
                    <Badge
                        bg={isEnabled ? "success" : "warning"}
                        style={{
                            color: isEnabled ? `white` : `black`,
                            position: "absolute",
                            top: 15,
                            right: 15,
                        }}
                    >
                        {isEnabled ? `Tracking` : `Incomplete`}
                    </Badge>
                </Card.Title>
                {isEnabled ? (
                    <Link
                        to={`${routerPath.siteDetails({
                            organisationId: site.organisationId,
                            siteId: id,
                        })}`}
                    >
                        <Button variant="primary" className="w-100 fw-bold">
                            View Metrics
                        </Button>
                    </Link>
                ) : (
                    <Button
                        variant="secondary"
                        className="w-100 fw-bold"
                        onClick={onEnableClick}
                    >
                        Finish Setup
                    </Button>
                )}
            </Card.Body>
        </Card>
    );
};
