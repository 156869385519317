import { useApolloClient } from "@apollo/client";
import * as queryString from "query-string";
import React, { useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { routerPath } from "../../AppRouter";
import { Loading } from "../../components";
import { ErrorText } from "../../components/ErrorText";
import { localStorageKeys } from "../../config";
import { useSignUpWithWebflowMutation } from "../../generated";

interface SignUpCallbackPageProperties {}

export const SignUpCallbackPage: React.FunctionComponent<SignUpCallbackPageProperties> =
    () => {
        const location = useLocation();
        const history = useHistory();
        const apolloClient = useApolloClient();
        const { code } = queryString.parse(location.search);
        const [signUpWithWebflowMutation, { loading, error }] =
            useSignUpWithWebflowMutation({
                onCompleted: async (data) => {
                    localStorage.setItem(
                        localStorageKeys.token,
                        data.signUpWithWebflow,
                    );
                    await apolloClient.resetStore();
                    history.push(routerPath.sites);
                },
            });
        useEffect(() => {
            if (typeof code == "string") {
                signUpWithWebflowMutation({
                    variables: {
                        code,
                    },
                });
            }
        }, [code, signUpWithWebflowMutation]);

        if (typeof code !== "string") {
            return <Redirect to={routerPath.auth} />;
        }

        if (loading) {
            return <Loading makeItFun />;
        }

        if (error) {
            return (
                <ErrorText error={error} from="SignUpCallbackPage webflow" />
            );
        }
        return (
            <div>
                <h1>Almost there... We are creating your account</h1>
            </div>
        );
    };
