import { startOfDay, subDays, subMonths } from "date-fns";

import { MetricTimeframe } from "../generated/graphql";

export const timeframeToStartAt = (
    timeframe: MetricTimeframe,
): Date | undefined => {
    const now = Date.now();
    const timeframeStartDates: { [key in MetricTimeframe]: Date } = {
        [MetricTimeframe.Today]: new Date(now),
        [MetricTimeframe.Yesterday]: subDays(now, 1),
        [MetricTimeframe.Last7days]: subDays(now, 7),
        [MetricTimeframe.Last30days]: subDays(now, 30),
        [MetricTimeframe.Last3Months]: subMonths(now, 3),
        [MetricTimeframe.Last6Months]: subMonths(now, 6),
        [MetricTimeframe.LastYear]: subMonths(now, 12),
        [MetricTimeframe.Custom]: new Date(),
    };
    return startOfDay(timeframeStartDates[timeframe]);
};
