import React from "react";
import { AxisOptions, Chart, UserSerie } from "react-charts";

export interface MetricChartProps {
    data?: Array<{
        name: string;
        values: Array<{ label: string; value: number }>;
    }>;
    detailsChart?: boolean;
}

interface Datum {
    date: Date;
    value: number;
}

export const TimeserieChart: React.FunctionComponent<MetricChartProps> = (
    props,
) => {
    const data: UserSerie<Datum>[] =
        props.data?.map(({ name, values }) => ({
            label: name,
            data:
                values?.map(({ label, value }) => ({
                    date: new Date(label),
                    value,
                })) || [],
        })) || [];

    const primaryAxis = React.useMemo(
        (): AxisOptions<Datum> => ({
            scaleType: "localTime",
            showGrid: false,
            formatters: {
                tooltip: (date): string =>
                    (date || new Date()).toLocaleDateString(),
            },
            getValue: (datum) => datum.date,
        }),
        [],
    );

    const secondaryAxes = React.useMemo(
        (): AxisOptions<Datum>[] => [
            {
                showGrid: false,
                formatters: {
                    scale: (value: number) => value.toFixed(0),
                    tooltip: (value: number) => Math.ceil(value),
                },
                getValue: (datum) => datum.value,
            },
        ],

        [],
    );

    return (
        <Chart
            className={`w-100 TimeserieChart ${
                props.detailsChart ? "position-relative" : "position-absolute"
            }`}
            options={{
                data,
                primaryAxis,
                secondaryAxes,
                interactionMode: "closest",
                tooltip: true,
                useIntersectionObserver: false,
                defaultColors: [
                    "#0d6efd",
                    "#9b59b6",
                    "#28A96C",
                    "#d44401",
                    "#1abc9c",
                    "#f39c12",
                    "#3498db",
                    "#27ae60",
                    "#16a085",
                ],
            }}
        />
    );
};
