import {
    IconDefinition,
    faCheck,
    faCog,
    faCopy,
    faEdit,
    faEllipsisV,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";

import { routerPath } from "../../../../AppRouter/routerPath";
import {
    Dashboard,
    DashboardBySiteIdDocument,
    UpdateDashboardMutationOptions,
    useDuplicateDashboardMutation,
} from "../../../../generated/graphql";
import { DeleteDashboardModal } from "./DeleteDashboardModal";
import { RenameDashboardModal } from "./RenameDashboardModal";

export interface MoreActionsProps {
    reorderingMetrics: boolean;
    onReorderingMetricsClick: () => void;
    updateDashboard?: (options: UpdateDashboardMutationOptions) => void;
    dashboardId: Dashboard["id"];
    handleDeleteDashboard?: (dashboardId: string) => void;
}

interface DropdownOption {
    text: string;
    icon: IconDefinition;
    onClick: () => void;
    extraClass: string | null;
}

export const MoreActionsDropdown: React.FunctionComponent<MoreActionsProps> = (
    props,
) => {
    const [showUpdate, setShowUpdate] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);

    const [duplicateDashboard] = useDuplicateDashboardMutation({
        variables: { id: props.dashboardId },
        onCompleted: ({ duplicateDashboard: { id } }) => {
            window.location.href = routerPath.dashboardDetails({
                dashboardId: id,
            });
        },
        refetchQueries: [DashboardBySiteIdDocument],
    });
    const reorderingMetrics: boolean = props.reorderingMetrics;
    const ReorderLayoutButton = (
        <Button
            variant="success"
            onClick={() => props.onReorderingMetricsClick()}
        >
            <FontAwesomeIcon className="me-2" icon={faCheck} />
            Done
        </Button>
    );

    const DropdownOptions: DropdownOption[] = [
        {
            text: "Edit Layout",
            icon: faCog,
            extraClass: null,
            onClick: () => props.onReorderingMetricsClick(),
        },
        {
            text: "Rename Dashboard",
            icon: faEdit,
            extraClass: null,
            onClick: () => setShowUpdate(true),
        },
        {
            text: "Duplicate Dashboard",
            icon: faCopy,
            extraClass: null,
            onClick: () => duplicateDashboard(),
        },
        {
            text: "Delete Dashboard",
            icon: faTrash,
            extraClass: "text-danger",
            onClick: () => setShowDelete(true),
        },
    ];

    return (
        <Col xs="auto" md="auto">
            {reorderingMetrics ? (
                ReorderLayoutButton
            ) : (
                <div className="dropdown">
                    <Button
                        variant="border-0 text-primary"
                        style={{ width: 40 }}
                        className="More_Button btn fw-bold"
                        type="button"
                        aria-label="dropdown-account-menu"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </Button>
                    <ul
                        className="dropdown-menu dropdown-menu-end px-1"
                        aria-labelledby="dropdown-account-menu"
                    >
                        {DropdownOptions.map(
                            (option: DropdownOption, i: number) => (
                                <li key={i}>
                                    <button
                                        onClick={option.onClick}
                                        className={`More_Dropdown_Item dropdown-item rounded ${
                                            option.extraClass
                                                ? `${option.extraClass}`
                                                : ""
                                        }`}
                                    >
                                        <FontAwesomeIcon
                                            className={`me-3`}
                                            icon={option.icon}
                                        />
                                        <span
                                            style={{
                                                marginLeft:
                                                    option.icon === faEdit
                                                        ? -2
                                                        : undefined,
                                            }}
                                        >
                                            {option.text}
                                        </span>
                                    </button>
                                </li>
                            ),
                        )}
                    </ul>
                </div>
            )}
            {props.updateDashboard && (
                <RenameDashboardModal
                    showUpdate={showUpdate}
                    dashboardId={props.dashboardId}
                    updateDashboard={props.updateDashboard}
                    handleClose={() => setShowUpdate(false)}
                />
            )}

            {props.handleDeleteDashboard && (
                <DeleteDashboardModal
                    showDelete={showDelete}
                    handleClose={() => setShowDelete(false)}
                    handleDeleteDashboard={props.handleDeleteDashboard}
                    dashboardId={props.dashboardId}
                />
            )}
        </Col>
    );
};
