import React from "react";
import { Badge, FormControlProps } from "react-bootstrap";

import { InputWithLabel } from "../..";
import { MetricType } from "../../../generated";

interface MetricPathInputProps {
    metricType: MetricType;
    formControl: FormControlProps;
}

export const MetricPathInput: React.FunctionComponent<MetricPathInputProps> = (
    props,
) => {
    const [value, setValue] = React.useState("");
    if (![MetricType.View].includes(props.metricType)) {
        return null;
    }
    return (
        <>
            <InputWithLabel
                label="Path"
                formControl={{
                    placeholder: "/sign-up",
                    id: "metric-path",
                    ...props.formControl,
                    onChange: (event) => {
                        setValue(event?.target.value);
                        props.formControl.onChange &&
                            props.formControl.onChange(event);
                    },
                }}
            />
            {value && !value.startsWith("/") && (
                <div className="d-flex align-items-baseline mt-3">
                    <Badge pill bg={"danger"} className="me-2">
                        i
                    </Badge>{" "}
                    <p className="text-danger fw-bold">
                        Path should start with &quot;/&quot;.
                    </p>
                </div>
            )}
        </>
    );
};
