import React from "react";

import { UpgradeModal, UpgradeModalProps } from "../components";
import { useStripeCustomerPortalLinkLazyQuery } from "../generated";

interface UpgradeModalContainerProps
    extends Omit<UpgradeModalProps, "onUpgradeClick"> {
    siteId: string;
}

export const UpgradeModalContainer: React.FunctionComponent<UpgradeModalContainerProps> =
    (props) => {
        const [getStripeCustomerPortalLink] =
            useStripeCustomerPortalLinkLazyQuery({
                fetchPolicy: "no-cache",
                onError(error) {
                    alert(error.message);
                },
                onCompleted(data) {
                    // eslint-disable-next-line security/detect-non-literal-fs-filename
                    window.open(data.stripeCustomerPortalLink, "_blank");
                },
            });
        return (
            <UpgradeModal
                {...props}
                onUpgradeClick={() => {
                    getStripeCustomerPortalLink();
                }}
            />
        );
    };
