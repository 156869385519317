import {
    faDatabase,
    faFile,
    faKeyboard,
    faMouse,
    faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import { EventType } from "../generated";

export const getEventIcon = (eventType: EventType) => {
    switch (eventType) {
        case "click":
            return faMouse;
        case "cms":
            return faDatabase;
        case "form":
            return faKeyboard;
        case "keyboard":
            return faKeyboard;
        case "view":
            return faFile;
        default:
            return faQuestion;
    }
};
