import React from "react";
import { Card } from "react-bootstrap";

export const MaintenanceMessage: React.FunctionComponent = () => (
    <Card
        className="position-absolute top-50 start-50 translate-middle"
        bg="light"
    >
        <Card.Header>
            Scheduled maintenance{" "}
            <span role="img" aria-label="tool">
                🛠
            </span>
        </Card.Header>
        <Card.Body>
            <Card.Text>
                <p>
                    Our hamsters that power our servers need new hamster wheels.
                    We&apos;re in the process of upgrading this vital
                    infrastructure so check back soon. <br />
                </p>
                <p style={{ fontSize: "smaller" }}>
                    <i>
                        No hamsters are ever harmed in providing you with
                        amazing Webflow analytics.
                        <span role="img" aria-label="hamster">
                            🐹
                        </span>{" "}
                        <span role="img" aria-label="wheel">
                            🎡
                        </span>
                        <br />
                        This page will reload automatically.
                    </i>
                </p>
            </Card.Text>
        </Card.Body>
    </Card>
);
