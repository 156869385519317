import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { ErrorText } from "../../components";
import { SignInForm } from "../../components/SignInForm";
import { config } from "../../config";
import { useCurrentUserQuery, useSendMagicLinkMutation } from "../../generated";

export const SignInPage: React.FunctionComponent = () => {
    const history = useHistory();
    const [sendMagicLinkMutation, { loading, error, data }] =
        useSendMagicLinkMutation();
    useCurrentUserQuery({
        onCompleted: ({ me }) => me && history.push("/"),
    });

    useEffect(() => {
        document.title = `${config.app.name} – Sign In`;
    }, []);
    const isNotFound = error?.graphQLErrors.some(
        ({ extensions }) => extensions?.code === "NOT_FOUND",
    );
    if (error && !isNotFound) {
        return <ErrorText error={error} from="SignIn" />;
    }
    return (
        <SignInForm
            isMagicLinkSent={Boolean(data?.sendMagicLink)}
            loading={loading}
            onSubmit={(variables) => sendMagicLinkMutation({ variables })}
            isNotFound={isNotFound}
        />
    );
};

// eslint-disable-next-line import/no-default-export
export default SignInPage;
