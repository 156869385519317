import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import { routerPath } from "../../AppRouter/routerPath";
import { config, localStorageKeys } from "../../config";

export const WebflowIFrameAuth: React.FC = () => {
    const [message, setMessage] = useState<string>();
    const [noTokenFound, setNoTokenFound] = useState<boolean>();
    const [tokenSent, setTokenSent] = useState<boolean>();
    const token = localStorage.getItem(localStorageKeys.token);
    const postToken = (): boolean => {
        if (tokenSent) {
            return true;
        }
        if (!token) {
            setNoTokenFound(true);
            return false;
        }
        try {
            window.parent.postMessage(
                token,
                config.webflow.designerExtensionUrl,
            );
            setTokenSent(true);
        } catch (error) {
            setMessage(`${error || "Oops, something went wrong"}`);
            return false;
        }
        return true;
    };
    useEffect(() => {
        if (!tokenSent) {
            postToken();
        }
        const interval = setInterval(() => {
            if (postToken()) {
                window.clearInterval(interval);
            }
        }, 2000);
        return () => {
            window.clearInterval(interval);
        };
    }, []);
    if (noTokenFound) {
        return (
            <div>
                <p>
                    We get it, there is so much to explore! But first, you need
                    to sign in.
                </p>
                <Button
                    href={[config.app.baseUrl, routerPath.signIn].join("")}
                    target="_blank"
                >
                    Sign in
                </Button>
            </div>
        );
    }
    if (tokenSent || message) {
        return (
            <div>
                <div>
                    Something went wrong. <a href={config.app.supportUrl}>Chat with us</a> or send an email to{" "}
                    {config.email.supportEmail}.
                </div>
                <div>Debug info:</div>
                <code>
                    {JSON.stringify(
                        { message, token: token || "no token" },
                        null,
                        2,
                    )}
                </code>
            </div>
        );
    }
    return <div>Fetching token... Please wait</div>;
};
