import React from "react";
import {
    FormLabel,
    InputGroup,
    FormControl,
    FormControlProps,
} from "react-bootstrap";

import { Loading } from "../Loading";

export interface InputWithLabelProps {
    label: String;
    formControl: FormControlProps;
    optional?: boolean;
    loading?: boolean;
}

export const InputWithLabel: React.FunctionComponent<InputWithLabelProps> = (
    props,
) => {
    return (
        <>
            <FormLabel htmlFor="metric-name">
                <span className="fw-bold">{props.label}</span>
                {props.optional ? (
                    <span className="text-muted ms-1">(optional)</span>
                ) : null}
            </FormLabel>
            <InputGroup>
                <FormControl
                    type="text"
                    id="metric-name"
                    {...props.formControl}
                />
                {props.loading && (
                    <InputGroup.Text>
                        <Loading timeout={1} />
                    </InputGroup.Text>
                )}
            </InputGroup>
        </>
    );
};
