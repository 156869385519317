import React from "react";

interface MetricChartEmptyStateProps {}

export const MetricChartEmptyState: React.FunctionComponent<MetricChartEmptyStateProps> =
    () => {
        return (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="text-center d-flex flex-column align-items-center fw-bold ">
                    <p className="text-muted">Not enough data</p>
                </div>
            </div>
        );
    };
