import { faLock, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col } from "react-bootstrap";
import { useHistory } from "react-router";

import { routerPath } from "../../../../AppRouter";
import { Dashboard, Feature } from "../../../../generated/graphql";

export interface NewMetricActionProps {
    dashboardId: Dashboard["id"];
    metricCount: number;
    triggerUpgradeModal?: () => void;
    paidFeatures: Feature[];
    canAddNewMetric: boolean;
}

export const NewMetricAction: React.FunctionComponent<NewMetricActionProps> = (
    props,
) => {
    const history = useHistory();
    const { canAddNewMetric } = props;
    return (
        <Col xs="auto" md="auto">
            <Button
                variant="primary"
                onClick={() => {
                    if (!canAddNewMetric && props.triggerUpgradeModal) {
                        props.triggerUpgradeModal();
                        return;
                    }
                    history.push(
                        routerPath.newMetric({
                            dashboardId: props.dashboardId,
                        }),
                    );
                }}
                className="fw-bold"
            >
                <FontAwesomeIcon
                    icon={canAddNewMetric ? faPlus : faLock}
                    className="me-2"
                />
                New Metric
            </Button>
        </Col>
    );
};
