import classNames from "classnames";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";

import { routerPath, RouterURLParams } from "../AppRouter";
import { Confetti, ErrorText, Loading } from "../components";
import { NavbarContainer, UpgradeModalContainer } from "../containers";
import { SidebarContainer } from "../containers/Sidebar/SidebarContainer";
import { useAppState } from "../context/AppStateProvider";
import {
    DashboardBySiteIdDocument,
    useDashboardByIdQuery,
    useDeleteDashboardMutation,
    useUpdateDashboardMutation,
} from "../generated";

interface MainLayoutProperties {
    siteId?: string | null;
    navbar?: boolean;
    loading?: boolean;
    error?: { text: string; from: string };
    title?: string;
    description?: string;
    rightElement?: React.ReactElement;
    noBackground?: boolean;
    showUpgradeModal?: boolean;
    triggerUpgradeModal?: () => void;
    onUpgradeModalClose?: () => void;
}

const Confettied = () => {
    const { showConfetti } = useAppState();
    return showConfetti ? <Confetti /> : null;
};

const MemoizedConfettied = React.memo(Confettied);

export const MainLayout: React.FunctionComponent<MainLayoutProperties> = (
    props,
) => {
    const history = useHistory();
    const params = useParams<
        Partial<RouterURLParams["siteDetails"]> &
            Partial<RouterURLParams["dashboardDetails"]>
    >();

    const { dashboardId } = useParams<RouterURLParams["dashboardDetails"]>();
    const { showSidebar } = useAppState();

    const dashboardByIdQueryResults = useDashboardByIdQuery({
        variables: { dashboardId },
        skip: typeof params.dashboardId === "undefined",
    });

    const { organisationId } = dashboardByIdQueryResults.data?.dashboard || {
        organisationId: "",
    };

    const [updateDashboard] = useUpdateDashboardMutation();

    const [deleteDashboard] = useDeleteDashboardMutation({
        variables: { id: dashboardId },
        refetchQueries: [DashboardBySiteIdDocument],
        onCompleted() {
            if (props.siteId) {
                history.push(
                    routerPath.siteDetails({
                        organisationId,
                        siteId: props.siteId,
                    }),
                );
            }
        },
    });

    const handleDeleteDashboard = (dashboardId: string) => {
        deleteDashboard({
            variables: {
                id: dashboardId,
            },
        });
    };

    return (
        <div className="MainLayout_Wrapper h-100">
            <MemoizedConfettied />

            {props.navbar !== false ? (
                <NavbarContainer
                    siteId={props.siteId}
                    triggerUpgradeModal={props.triggerUpgradeModal}
                />
            ) : null}
            <div>
                <div className="d-md-flex align-items-start justify-content-center">
                    <SidebarContainer
                        handleDeleteDashboard={handleDeleteDashboard}
                        updateDashboard={updateDashboard}
                    />
                    <div
                        className={`MainLayout_Content ${
                            showSidebar ? "blur" : ""
                        }`}
                    >
                        {props.error ? (
                            <ErrorText
                                error={props.error.text}
                                from={props.error.from}
                            />
                        ) : null}
                        <Container
                            className={classNames([
                                "h-100 mt-4 rounded",
                                { "bg-white p-4": !props.noBackground },
                            ])}
                            style={{ minHeight: 400 }}
                        >
                            {props.title ? <Header {...props} /> : null}
                            <div className="MainLayout_Container">
                                {props.onUpgradeModalClose && props.siteId && (
                                    <UpgradeModalContainer
                                        show={props.showUpgradeModal || false}
                                        onClose={props.onUpgradeModalClose}
                                        siteId={props.siteId}
                                    />
                                )}
                                {props.loading ? (
                                    <Loading makeItFun />
                                ) : (
                                    props.children
                                )}
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Header: React.FunctionComponent<MainLayoutProperties> = (props) => (
    <div className="MainLayout__Header border-bottom mb-4 pb-3">
        <Row>
            <Col>
                <h1 className="text-truncate h3">
                    {props.title} {props.loading ? <Loading /> : null}
                </h1>
            </Col>
            {props.rightElement ? (
                <Col xs="auto">{props.rightElement}</Col>
            ) : null}
        </Row>
        <Description {...props} />
    </div>
);

const Description: React.FunctionComponent<MainLayoutProperties> = (props) =>
    props.description ? (
        <p className="fw-light text-muted">{props.description}</p>
    ) : null;
