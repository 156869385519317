import React from "react";
import {
    FormLabel,
    FormSelect,
    FormSelectProps,
    InputGroup,
} from "react-bootstrap";

import { MetricType, useCmsCollectionsQuery } from "../../../generated";

interface CustomFieldSelectorInputProps {
    metricType: MetricType;
    siteId: string;
    formSelectProps: FormSelectProps;
    collectionId: string;
}

export const CustomFieldSelectorInput: React.FunctionComponent<CustomFieldSelectorInputProps> =
    (props) => {
        const cmsCollectionsResults = useCmsCollectionsQuery({
            variables: { siteId: props.siteId },
            skip: props.metricType !== MetricType.CustomField || !props.siteId,
        });
        if (props.metricType !== MetricType.CustomField) {
            return null;
        }
        const fields = cmsCollectionsResults.data?.cmsCollections
            .find(({ id }) => id === props.collectionId)!
            .fields.filter(({ type }) => type == "Number");
        if (!fields) {
            return <div>Collection {props.collectionId} not found</div>;
        }
        return (
            <div className="col-md mt-2">
                <FormLabel htmlFor="collection-fields" className="fw-bold">
                    Select a field from the collection
                </FormLabel>
                <InputGroup className="input-group">
                    <FormSelect
                        id="custom-field"
                        placeholder="Choose a field"
                        {...props.formSelectProps}
                    >
                        <option value="">
                            {fields.length === 0
                                ? `No fields available`
                                : `Choose a field`}
                        </option>
                        {fields.map(({ id, name, slug }) => (
                            <option key={id} value={`$..['${slug}']`}>
                                {name}
                            </option>
                        ))}
                    </FormSelect>
                </InputGroup>
            </div>
        );
    };
