import { useApolloClient } from "@apollo/client";
import React from "react";
import { useHistory, useParams } from "react-router";

import { RouterURLParams } from "../../AppRouter";
import { ErrorText } from "../../components/ErrorText";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { localStorageKeys } from "../../config";
import {
    useCurrentUserQuery,
    useDashboardByIdQuery,
    useDashboardBySiteIdQuery,
    useFeaturesQuery,
    useSitesQuery,
    useStripeCustomerPortalLinkLazyQuery,
} from "../../generated";

interface SidebarContainerProperties {
    updateDashboard?: any;
    handleDeleteDashboard: (dashboardId: string) => void;
}

export const SidebarContainer: React.FunctionComponent<SidebarContainerProperties> =
    (props) => {
        const apolloClient = useApolloClient();
        const history = useHistory();
        const currentUserQueryResults = useCurrentUserQuery();
        const organisationId =
            localStorage.getItem(localStorageKeys.selectedOrganisationId) ||
            currentUserQueryResults.data?.me?.organisations[0].id ||
            "";

        const params = useParams<
            Partial<RouterURLParams["siteDetails"]> &
                Partial<RouterURLParams["dashboardDetails"]>
        >();

        const dashboardByIdQuery = useDashboardByIdQuery({
            variables: { dashboardId: params.dashboardId || "" },
            skip: typeof params.dashboardId === "undefined",
        });

        const siteId =
            dashboardByIdQuery.data?.dashboard.siteId || params.siteId;

        const dashboardsQueryResults = useDashboardBySiteIdQuery({
            variables: {
                siteId: siteId || "",
            },
            skip: !siteId,
        });

        const sitesQueryResults = useSitesQuery({
            variables: {
                first: 5,
                organisationId,
            },
            skip: !organisationId,
        });
        const featuresQueryResults = useFeaturesQuery();

        const [getStripeCustomerPortalLink] =
            useStripeCustomerPortalLinkLazyQuery({
                fetchPolicy: "no-cache",
                onError(error) {
                    alert(error.message);
                },
                onCompleted(data) {
                    // eslint-disable-next-line security/detect-non-literal-fs-filename
                    window.open(data.stripeCustomerPortalLink, "_blank");
                },
            });

        const error =
            sitesQueryResults.error ||
            currentUserQueryResults.error ||
            dashboardsQueryResults.error;
        const loading: boolean =
            sitesQueryResults.loading ||
            currentUserQueryResults.loading ||
            dashboardsQueryResults.loading ||
            featuresQueryResults.loading;
        const data = {
            ...sitesQueryResults.data,
            ...currentUserQueryResults.data,
            ...featuresQueryResults.data,
            ...dashboardsQueryResults.data,
        };

        if (error) return <ErrorText from="NavbarContainer" error={error} />;

        const onLogout = async () => {
            await apolloClient.clearStore();
            localStorage.clear();
            history.push("/");
        };

        const sites = data?.sites?.edges.map(({ node }) => node) || [];

        const onBillingClick = (): void => getStripeCustomerPortalLink();

        return (
            <Sidebar
                dashboards={data.dashboardBySiteId || []}
                user={data?.me?.user}
                features={data?.features}
                sites={sites}
                siteId={siteId}
                organisationId={organisationId}
                loading={loading}
                onLogout={onLogout}
                onBillingClick={onBillingClick}
                handleDeleteDashboard={props.handleDeleteDashboard}
                updateDashboard={props.updateDashboard}
            />
        );
    };
