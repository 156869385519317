import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router";

export const useLogout = () => {
    const apolloClient = useApolloClient();
    const history = useHistory();
    const logout = async () => {
        await apolloClient.clearStore();
        localStorage.clear();
        history.push("/");
    };
    return { logout };
};
