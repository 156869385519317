import React from "react";
import { useParams } from "react-router";

import { RouterURLParams } from "../../AppRouter";
import { MainLayout } from "../../Layout";

export const LiveFeedPage: React.FunctionComponent = () => {
    const { siteId } = useParams<RouterURLParams["newDashboard"]>();

    return (
        <MainLayout siteId={siteId} title="Live Feed">
            <div>
                Work in progress. Email us if you would like to join our closed
                beta.
            </div>
        </MainLayout>
    );
};
