import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col } from "react-bootstrap";
import styled from "styled-components";

import {
    Dashboard,
    Feature,
    Organisation,
    Site,
    UpdateDashboardMutationOptions,
} from "../../../../generated/graphql";
import { ShareDashboardModal } from "./ShareDashboardModal";

interface ShareActionProps {
    siteId: Site["id"];
    dashboardId: Dashboard["id"];
    isPublic: boolean | undefined;
    organisationId: Organisation["id"];
    updateDashboard: (options: UpdateDashboardMutationOptions) => Promise<void>;
    paidFeatures: Feature[];
    showShare: boolean;
    setShowShare: (value: boolean) => void;
}

const ShareButton = styled(Button)``;

export const ShareAction: React.FunctionComponent<ShareActionProps> = (
    props,
) => {
    return (
        <>
            <Col xs="auto" md="auto">
                <ShareButton
                    className="fw-bold bg-white h-10 text-primary"
                    variant="white"
                    onClick={() => props.setShowShare(true)}
                >
                    <>
                        <FontAwesomeIcon icon={faShare} className="me-2" />
                        Share
                    </>
                </ShareButton>
            </Col>

            <ShareDashboardModal
                paidFeatures={props.paidFeatures}
                siteId={props.siteId}
                showShare={props.showShare}
                handleClose={() => props.setShowShare(false)}
                dashboardId={props.dashboardId}
                updateDashboard={props.updateDashboard}
                isPublic={props.isPublic}
                organisationId={props.organisationId}
            />
        </>
    );
};
