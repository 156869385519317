import React from "react";
import ReactDOM from "react-dom";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { App } from "./App";
import { AppStateProvider } from "./context/AppStateProvider";
import "./index.css";

ReactDOM.render(
    <React.StrictMode>
        <AppStateProvider>
            <App />
        </AppStateProvider>
    </React.StrictMode>,

    document.querySelector("#root"),
);
