import React from "react";
import { Button, Modal } from "react-bootstrap";

import { Dashboard } from "../../../../generated";

interface DeleteDashboardProps {
    showDelete: boolean;
    handleClose: () => void;
    handleDeleteDashboard: (dashboardId: string) => void;
    dashboardId: Dashboard["id"];
}

export const DeleteDashboardModal: React.FunctionComponent<DeleteDashboardProps> =
    (props) => {
        const onDeleteDashboard = (): void => {
            props.handleDeleteDashboard(props.dashboardId);
            props.handleClose();
        };

        return (
            <Modal centered show={props.showDelete} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Dashboard</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this dashboard?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={onDeleteDashboard}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
