import { faLock, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { throttle } from "lodash";
import React, { useState } from "react";
import {
    Breadcrumb,
    Button,
    Col,
    Form,
    InputGroup,
    Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router";

import { RouterURLParams, routerPath } from "../../AppRouter";
import { MainLayout } from "../../Layout";
import { ErrorText, Loading } from "../../components";
import { Filters } from "../../components/Filters/Filters";
import { getStartAtDate } from "../../components/Metrics/MetricsList/ActionsBar/TimeframeButton";
import {
    Feature,
    MetricTimeframe,
    VisitorsQueryVariables,
    useOrganisationQuery,
    useSiteByIdQuery,
    useVisitorsQuery,
} from "../../generated/graphql";
import { VisitorsRow } from "./VisitorsRow";

interface VisitorsPageProps {
    startAt?: Date;
    endAt?: Date;
}

export const VisitorsPage: React.FunctionComponent<VisitorsPageProps> = (
    props,
) => {
    const history = useHistory();
    const { siteId } = useParams<RouterURLParams["siteVisitors"]>();
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [isMember, setIsMember] = useState<boolean | undefined>();
    const [startAt] = useState<Date>(
        props.startAt || new Date(getStartAtDate(MetricTimeframe.Today)),
    );
    const [endAt] = useState<Date>(props.endAt || new Date());
    const { register, watch } = useForm<{ searchMemberValue: string }>();
    const searchMemberValue = watch("searchMemberValue");

    const filters: VisitorsQueryVariables["filters"] = {
        siteId,
        startAt,
        endAt,
        isMember,
        emailAddress:
            searchMemberValue?.length > 0 ? searchMemberValue : undefined,
    };

    const siteByIdResults = useSiteByIdQuery({ variables: { id: siteId } });
    const visitorsQueryResults = useVisitorsQuery({
        variables: { filters },
        // notifyOnNetworkStatusChange: true,
    });
    const organisationResults = useOrganisationQuery({ variables: { siteId } });

    const data = {
        ...siteByIdResults.data,
        ...visitorsQueryResults.data,
        ...organisationResults.data,
    };
    const error =
        siteByIdResults.error ||
        visitorsQueryResults.error ||
        organisationResults.error;
    const loading =
        siteByIdResults.loading ||
        visitorsQueryResults.loading ||
        organisationResults.loading;

    const isSubscriptionValid = data.organisation?.features?.includes(
        Feature.Membership,
    );

    // const dateDropdown = (
    //     <TimeframeButton
    //         paidFeatures={data.organisation?.features || []}
    //         onDateChange={(dates) => {
    //             setStartAt(dates.startAt);
    //             setEndAt(dates.endAt);
    //             visitorsQueryResults.refetch({
    //                 filters: {
    //                     ...filters,
    //                     startAt: dates.startAt,
    //                     endAt: dates.endAt,
    //                 },
    //             });
    //         }}
    //         triggerUpgradeModal={() => setShowUpgradeModal(true)}
    //     />
    // );

    const handleSearch = throttle((event) => {
        register("searchMemberValue").onChange(event);

        visitorsQueryResults.refetch({
            filters: {
                ...filters,
                emailAddress:
                    event.target.value.length > 0 ? event.target.value : null,
            },
        });
    }, 500);

    const handleFilter = (selectedFilters: {
        isMember?: boolean | undefined;
    }) => {
        setIsMember(selectedFilters.isMember);
        visitorsQueryResults.refetch({
            filters: {
                ...filters,
                isMember: selectedFilters.isMember,
            },
        });
    };

    if (loading) {
        return <Loading makeItFun />;
    }

    if (error || !data.visitors || !data.organisation || !data.siteById) {
        return (
            <MainLayout
                title="Error getting visitors"
                loading={loading}
                showUpgradeModal={showUpgradeModal}
                triggerUpgradeModal={() => setShowUpgradeModal(true)}
                onUpgradeModalClose={() => setShowUpgradeModal(false)}
                siteId={siteId}
            >
                <Row className="mb-3">
                    <Col sm={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control
                                id="filter-members-input"
                                {...register("searchMemberValue")}
                                onChange={handleSearch}
                                type="text"
                                placeholder="Search by email"
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <Filters
                            filters={{ isMember }}
                            onChange={handleFilter}
                        />
                    </Col>
                    {/* <Col>{dateDropdown}</Col> */}
                </Row>
                <ErrorText
                    error={error || "Visitors not available"}
                    from="VisitorsPage"
                    inline
                />
            </MainLayout>
        );
    }

    if (data?.siteById?.isEnabled === false) {
        return (
            <Redirect
                to={routerPath.siteEnable({
                    organisationId: data.siteById.organisationId,
                    siteId,
                })}
            />
        );
    }

    const visitors = data.visitors?.edges.map(({ node }) => node) || [];

    return (
        <MainLayout
            siteId={siteId}
            showUpgradeModal={showUpgradeModal}
            triggerUpgradeModal={() => setShowUpgradeModal(true)}
            onUpgradeModalClose={() => setShowUpgradeModal(false)}
        >
            <Row>
                <Col>
                    {data.organisation ? (
                        <Breadcrumb className="Breadcrumb">
                            <Breadcrumb.Item
                                onClick={() =>
                                    history.push(
                                        routerPath.siteRedirect({
                                            siteId,
                                        }),
                                    )
                                }
                                className="fw-bold"
                            >
                                {data.siteById.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Visitors</Breadcrumb.Item>
                        </Breadcrumb>
                    ) : null}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h3>
                        Visitors{" "}
                        {data.visitors?.totalCount
                            ? `(${data.visitors?.totalCount})`
                            : ""}
                    </h3>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={4}>
                    <InputGroup>
                        <InputGroup.Text className="bg-primary border border-dark text-white">
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control
                            id="filter-members-input"
                            {...register("searchMemberValue")}
                            onChange={handleSearch}
                            type="text"
                            placeholder="Search by email"
                            className="border border-dark"
                        />
                    </InputGroup>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Filters filters={{ isMember }} onChange={handleFilter} />
                </Col>
                {/* <Col className="d-flex justify-content-end">{dateDropdown}</Col> */}
            </Row>
            <Row>
                <Col>
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th>User</th>
                                <th>Last seen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && visitors.length === 0 && (
                                <tr>
                                    <td>
                                        This site does not have any tracked
                                        visitors yet.
                                    </td>
                                </tr>
                            )}
                            {visitors.map((visitor, i) => {
                                return (
                                    <VisitorsRow
                                        index={i}
                                        key={visitor.id}
                                        id={visitor.id}
                                        emailAddress={
                                            visitor.emailAddress || ""
                                        }
                                        lastInteraction={
                                            visitor.lastInteraction
                                        }
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </Col>
            </Row>

            {!isSubscriptionValid || data.visitors?.pageInfo.hasNextPage ? (
                <div className="mt-3 text-center">
                    <Button
                        id="load-more-btn"
                        disabled={loading}
                        onClick={() => {
                            visitorsQueryResults.fetchMore({
                                variables: {
                                    filters,
                                    offset: data.visitors?.edges.length,
                                },
                            });
                        }}
                    >
                        {loading ? (
                            <FontAwesomeIcon className="me-2" icon={faLock} />
                        ) : null}
                        {!isSubscriptionValid ? (
                            <FontAwesomeIcon className="me-2" icon={faLock} />
                        ) : null}
                        {loading ? "Loading..." : "Load more"}
                    </Button>
                </div>
            ) : null}
        </MainLayout>
    );
};
