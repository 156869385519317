import React, { ChangeEventHandler } from "react";

import { Maybe } from "../../helpers";
import { Loading } from "../Loading";

export interface CheckboxProps {
    id: string;
    loading?: Maybe<boolean>;
    value: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = (props) => {
    if (props.loading) {
        return <Loading />;
    }
    return (
        <div className="form-check form-switch">
            <input
                checked={props.value}
                onChange={props.onChange}
                className="form-check-input"
                type="checkbox"
                id={props.id}
            />
            <label className="form-check-label" htmlFor={props.id}>
                {props.children}
            </label>
        </div>
    );
};
