import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { omit } from "lodash";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import { routerPath, RouterURLParams } from "../../../AppRouter";
import { MainLayout } from "../../../Layout";
import { ErrorText, Loading } from "../../../components";
import { MetricFormData } from "../../../components/MetricEditor";
import { MetricFormContainer } from "../../../containers";
import {
    MetricChartBreakdownBy,
    useMetricByIdQuery,
    useSiteByIdQuery,
    useUpdateMetricMutation,
} from "../../../generated/graphql";

interface EditMetricPageProps {}

export const EditMetricPage: React.FunctionComponent<EditMetricPageProps> =
    () => {
        const [showUpgradeModal, setShowUpgradeModal] = useState(false);
        const { organisationId, siteId, metricId } =
            useParams<RouterURLParams["editMetric"]>();
        const formControl = useForm<MetricFormData>();
        const siteByIdQueryResults = useSiteByIdQuery({
            variables: { id: siteId },
        });
        const metricByIdQueryResults = useMetricByIdQuery({
            variables: { id: metricId },
            onCompleted: (data) => {
                const breakdownBy =
                    data.metric.breakdownBy || MetricChartBreakdownBy.None;
                formControl.reset(
                    omit({ ...data.metric, breakdownBy }, [
                        "__typename",
                        "siteId",
                    ]),
                );
            },
        });

        const [updateMetricMutation, updateMetricMutationResults] =
            useUpdateMetricMutation({
                onCompleted() {
                    window.location.replace(
                        routerPath.dashboardDetails({
                            dashboardId:
                                metricByIdQueryResults.data!.metric.dashboardId,
                        }),
                    );
                },
            });

        const loading =
            siteByIdQueryResults.loading || metricByIdQueryResults.loading;
        const error =
            siteByIdQueryResults.error || metricByIdQueryResults.error;
        const data = {
            ...siteByIdQueryResults.data,
            ...metricByIdQueryResults.data,
        };

        if (loading) {
            return <Loading makeItFun />;
        }
        if (error) {
            {
                return <ErrorText error={error} from="EditMetricPage" />;
            }
        }
        if (!data.siteById) {
            return <ErrorText error="Site not found" from="EditMetricPage" />;
        }

        const handleSubmit = formControl.handleSubmit(
            (metric) => {
                updateMetricMutation({
                    variables: {
                        metric: {
                            ...omit(metric, "dashboardId"),
                            id: metricId,
                        },
                    },
                });
            },
            (errors) => window.alert(errors),
        );

        return (
            <MainLayout
                siteId={siteId}
                title={`Edit metric from ${data.siteById.name}`}
                showUpgradeModal={showUpgradeModal}
                triggerUpgradeModal={() => setShowUpgradeModal(true)}
                onUpgradeModalClose={() => setShowUpgradeModal(false)}
                rightElement={
                    <Button
                        variant="outline-primary"
                        disabled={updateMetricMutationResults.loading}
                        onClick={handleSubmit}
                    >
                        <span>
                            <FontAwesomeIcon
                                className="fs-6 me-2"
                                icon={faSave}
                            />
                            Update metric
                        </span>
                    </Button>
                }
            >
                <MetricFormContainer
                    onSubmit={handleSubmit}
                    siteId={siteId}
                    organisationId={organisationId}
                    formControl={formControl}
                    triggerUpgradeModal={() => setShowUpgradeModal(true)}
                />
            </MainLayout>
        );
    };
