import { ApolloClient, ServerParseError } from "@apollo/client";
// import { BatchHttpLink as HttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";

import { config, localStorageKeys } from "../config";
import { createCache } from "./cache";

const errorLink = onError(({ networkError }) => {
    if (networkError) {
        const { statusCode } = networkError as ServerParseError;
        if (statusCode === 401) {
            localStorage.removeItem(localStorageKeys.token);
        }
    }
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(localStorageKeys.token);
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const httpLink = new HttpLink({
    uri: `${config.api.url}/graphql`,
});

// const wsLink = new WebSocketLink({
//     uri: `${config.api.url}/graphql`.replace("http", "ws"),
//     options: {
//         connectionParams: {
//             authorization: `Bearer ${localStorage.getItem(
//                 localStorageKeys.token,
//             )}`,
//         },
//     },
// });
const cache = createCache();

export const apolloClient = new ApolloClient({
    uri: `${config.api.url}/graphql`,
    // eslint-disable-next-line unicorn/prefer-spread
    link: authLink.concat(errorLink).concat(httpLink),
    // connectToDevTools: true,
    cache,
});
