import React from "react";
import { Col, Row } from "react-bootstrap";

import { MetricEvent } from "../../generated";
import { VisitorEventRow } from "./VisitorEventRow";

interface VisitorEventsProperties {
    events: MetricEvent[];
}

export const VisitorEventsTable: React.FunctionComponent<VisitorEventsProperties> =
    ({ events }) => {
        return (
            <Row>
                <Col>
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Time</th>
                                <th scope="col">Details</th>
                                <th scope="col">URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {events.map((event) => (
                                <VisitorEventRow key={event.id} event={event} />
                            ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
        );
    };
