import React from "react";

import { IFrameSnippet, IFrameSnippetProps } from "../../components";

interface EmbedDashboardDescriptionProps extends IFrameSnippetProps {}

export const EmbedDashboardDescription: React.FunctionComponent<EmbedDashboardDescriptionProps> =
    (props) => {
        return (
            <>
                <IFrameSnippet {...props} />
                <p>
                    Want to create a member dashboard instead? Follow this{" "}
                    <a
                        rel="noreferrer"
                        href="https://www.nocodelytics.com/help-center/member-dashboard"
                        target="_blank"
                    >
                        guide
                    </a>
                    .
                </p>
            </>
        );
    };
