import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDistance } from "date-fns";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { HoverText } from "../../components/Typography";
import { MetricEvent } from "../../generated";
import { getEventIcon } from "../../helpers/getEventIcon";

interface VisitorEventRowProperties {
    event: MetricEvent;
}

export const VisitorEventRow: React.FunctionComponent<VisitorEventRowProperties> =
    (props) => {
        const { createdAt, path: _path, eventType } = props.event;
        const path = _path ? _path.split("?")[0] : null;
        return (
            <>
                <tr>
                    <td className="py-3 text-muted">
                        <HoverText
                            text={new Date(createdAt).toLocaleString()}
                            hoverOnly
                        >
                            {createdAt
                                ? formatDistance(
                                      new Date(createdAt!),
                                      new Date(),
                                  ).toLocaleString()
                                : null}{" "}
                            ago
                        </HoverText>
                    </td>
                    <td className="py-3">
                        <div className="badge bg-dark">
                            <FontAwesomeIcon
                                icon={getEventIcon(eventType)}
                                className="me-1 opacity-75"
                            />
                            {eventType === "keyboard" ? "input" : eventType}
                        </div>
                    </td>
                    <OverlayTrigger
                        placement="top"
                        overlay={(props) => (
                            <Tooltip {...props}>{path}</Tooltip>
                        )}
                    >
                        <td
                            className="py-3 text-truncate"
                            style={{ maxWidth: 200 }}
                        >
                            {path}
                        </td>
                    </OverlayTrigger>
                </tr>
            </>
        );
    };
