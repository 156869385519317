import { FieldMergeFunction } from "@apollo/client";

import { SitePaginated } from "../generated/graphql";

export interface Paginatedresults extends Omit<SitePaginated, "edges"> {
    edges: Array<{ node: { __ref: string } }>;
}

export const mergeResults: FieldMergeFunction<Paginatedresults> = (
    existing,
    incoming,
) => {
    if (!existing) {
        return (
            incoming || {
                edges: [],
                pageInfo: { hasNextPage: false },
                totalCount: 0,
            }
        );
    }
    const existingRefs = new Set(existing.edges.map(({ node }) => node.__ref));
    return {
        __typename: incoming?.__typename || existing.__typename,
        pageInfo: incoming?.pageInfo || existing.pageInfo,
        totalCount: incoming?.totalCount || existing.totalCount,
        edges: [
            ...existing.edges,
            ...(incoming?.edges || []).filter(({ node }) => {
                return !existingRefs.has(node.__ref);
            }),
        ],
    };
};
